import { useState, useEffect } from 'react'

export const useCloverSDK = (url, name, api_key) => {

    const [lib, setLib] = useState({})

    useEffect(() => {
        const script = document.createElement('script')
        script.src = url
        script.async = true
        script.onload = () => {

            const clover = new window[name](api_key);
            setLib({[name]: clover })
            // setLib({ [name]: window[name]})
        }

        document.head.appendChild(script)

        return () => {
            document.head.removeChild(script)
            document.getElementsByClassName('clover-footer')[0].remove();
        }
    }, [name])

    return lib

}