import Button from "react-bootstrap/Button";
import React, {Fragment, useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import AxiosInstance from "../../services/AxiosInstance";
import {Badge, FormGroup, Tab} from "react-bootstrap";
import {Link} from "react-router-dom";

const GroupsViewTab = (props) => {


    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState({});
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        AxiosInstance.get(`/api/v1/organization/${props.organizationNumber}/groups`).then((resGroups => {
            setGroups(resGroups.data);
        }));
    }, [props.organizationNumber])

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let errors = {...formErrors};
        delete errors[name];
        setFormErrors(errors);

        setGroup({
            ...group,
            [name]: value
        });

    }

    const handleAdd = () => {

        let errors = {...formErrors};

        !/[\w\d]{3,}\s*?\d*?$/i.test(group.groupName) ? errors.groupName = "Group Name is Required" :
            delete errors.groupName;

        let newGroup = {
            organizationNumber: props.organizationNumber,
            ...group
        }

        if (Object.keys(errors).length == 0) {

            AxiosInstance.post(`/api/v1/organization/${props.organizationNumber}/groups`, newGroup).then(res => {

                AxiosInstance.get(`/api/v1/organization/${props.organizationNumber}/groups`).then((resGroups => {
                    setGroups(resGroups.data);
                }));

                props.onGroupAdded(newGroup);

                setGroup({
                    organizationNumber: props.organizationNumber,
                    groupName: ""
                })

            }).catch((err, res1) => {
                    setFormErrors({...formErrors, groupName: err.response.data});
            }
            );

        }

        setFormErrors(errors);



    }

    const handleDelete = (groupId) => {

        AxiosInstance.delete(`/api/v1/organization/${props.organizationNumber}/groups/${groupId}`).then((res => {

            AxiosInstance.get(`/api/v1/organization/${props.organizationNumber}/groups`).then((resGroups => {
                setGroups(resGroups.data);
            }));

            props.onGroupAdded({}); // TODO: replace with onDelete

        }));

    }


    return (
            <Fragment   >
                <Row className="mb-3">
                    <Col md={4}>
                        <FormGroup>
                            <InputGroup>

                                <Form.Control name="groupName" size="sm" type="text"
                                              maxLength={100}
                                              value={group.groupName}
                                              isInvalid={!!formErrors.groupName}
                                              onChange={handleInputChange}
                                              placeholder="Group Name"
                                />
                                <Button variant="secondary"
                                        size="sm"
                                        className="d-inline-block"
                                        onClick={handleAdd}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-plus-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>{` `}Add
                                </Button>
                            </InputGroup>
                        </FormGroup>
                        <div className="text-danger">{formErrors.groupName}</div>
                    </Col>
                </Row>

                {
                    groups.map((gr, idx) => (
                        <Row key={idx}>
                            <Col>
                                <Link><i className="bi bi-x text-danger" onClick={()=>handleDelete(gr._id)} /></Link>
                                {gr.groupName}
                            </Col>
                        </Row>
                    ))
                }

            </Fragment>
            );

}

export default GroupsViewTab;