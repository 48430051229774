import API_V2 from "./API_V2";

class MemberService {

    static getMemberById(member_id) {
        return API_V2.get(`/v2/members/${member_id}`)
            .then(res => {
                // set some defaults for easier handling later
                if (res.data.phones === undefined) {
                    res.data.phones = [];
                }
                return res.data
            }
            ).catch(err => {
                if (err.response) {
                    throw {status: err.response.status, message: err.response.data.detail};
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }


    static getLinkedMembers(member_id) {
        return API_V2.get(`/v2/members/${member_id}/linked-members`)
            .then(res => {

                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static getCampaigns(member_id) {
        return API_V2.get(`/v2/members/${member_id}/campaigns`)
            .then(res => {

                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }


    static getDonations(member_id, pageSize  =10, pageNumber  = 1) {
        return API_V2.get(`/v2/members/${member_id}/donations?page_size=${pageSize}&page_number=${pageNumber}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    throw {status: err.response.status, message: err.response.data.detail};
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    // Add more static methods here
}

export default MemberService;