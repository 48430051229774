const ENVIRONMENT = 'prod'

const global = {
    version: "1.1.13",
    environment: ENVIRONMENT
}

const devConfig = {
    axios: {
        baseURL: "http://localhost:3001"
    },
    api_v2: {
        baseURL: "http://localhost:8000"
    },
    clover: {
        employee: "ZZRWMZ80A6HPC",
        sdk_uri: "https://checkout.sandbox.dev.clover.com/sdk.js",
        public_api_key: "4a6503ce180ebec2d15be904a0d0a173"
    },
    global: {
        ...global,
        version: global.version + " (dev)"
    }
}

const prodConfig = {
    axios: {
        baseURL: 'https://api.myfundcenter.com'
        // baseURL: "http://localhost:3001"
    },
    api_v2: {
        baseURL: 'https://api.myfundcenter.com'
    },
    clover: {
        employee: "TQEG2VXFSD2CJ",
        sdk_uri: "https://checkout.clover.com/sdk.js",
        public_api_key: "99bc702002044265863695a6730f4098"
    },
    global
}

export const AppConfig = () => {

    switch (ENVIRONMENT) {
        case 'dev':
            return devConfig;
        case 'prod':
            return prodConfig;
        default:
            return devConfig;
    }

}

export default  AppConfig;




