import React, {useState, useEffect, useReducer} from 'react';
import combineReducers from 'react-combine-reducers';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import AxiosInstance from '../../services/AxiosInstance.js'
import {Collapse} from "react-bootstrap";
import AccountSummaryGrid from "../../components/AccountSummaryGrid";
import {useNavigate} from "react-router";
import CreditCardView from "../../components/CreditCardView";
import PhoneView from "../../components/PhoneView";
import ThankYouForPayment from "../Payment/ThankYouForPayment";
import CreditCardInfo from "../Payment/CreditCardInfo";


const AccountSummary = (props) => {


    AxiosInstance.defaults.headers['Authorization-Token'] = localStorage.getItem('userToken');

    const [isLoaded] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [payee, setPayee] = useState("");
    const navigate = useNavigate();


    const initialTotals = {
        nextPaymentDueAmt: 0,
        lastPaymentAmt: 0,
        lateChargesAmt: 0,
        totalPaymentDue: 0
    };

    const initialMembershipDonations = [];

    const membershipDonationsReducer = (state, action) => {
        switch (action.type) {
            case 'ADD_MEMBERSHIP_DONATIONS':
                return [...state], [...state, ...action.payload];
            case 'ADD_MEMBERSHIP_DONATION':
                return [...state, action.payload];

            default:
                return state;
        }
    };

    const totalsReducer = (state, action) => {

        switch (action.type) {
            case 'ADD_TOTAL':
                return {...state, totalPaymentDue: state.totalPaymentDue + action.payload};
            case 'RESET_TOTAL':
                return {...state, totalPaymentDue: 0};
            default:
                return state;
        }
    };

    const lastPaymentReducer = (state, action) => {

        switch (action.type) {
            case 'ADD_LAST_PAYMENT':
                return {...state, lastPaymentAmt: state.lastPaymentAmt + action.payload};
            case 'RESET_LAST_PAYMENT':
                return {...state, lastPaymentAmt: 0};
            default:
                return state;
        }
    };

    const [combinedReducer, initialState] = combineReducers({
        totals: [totalsReducer, initialTotals],
        payments: [lastPaymentReducer, initialTotals],
        membershipDonations: [membershipDonationsReducer, initialMembershipDonations]

    });


    const [state, dispatch] = useReducer(combinedReducer, initialState);


    // this is a placeholder props for new Account Summary Grid
    // TODO: populate dynamically
    const accountSummaries = [
        {
            accountName: "Funeral Fund Coverage",
            previousPayment: state.payments.lastPaymentAmt,
            balance: state.totals.totalPaymentDue
        }
    ]


    const getMember = async (id) => {
        return await AxiosInstance.get(`/api/v1/member/${id}`).then(res => res.data);
    };

    const getMemberByMemberId = async (memberId) => {
        return await AxiosInstance.get(`/api/v1/member/memberId/${memberId}`).then(res => res.data);
    };

    const getMembershipDetails = async (membershipId) => {
        return await AxiosInstance.get(`/api/v1/membership/${membershipId}`).then(res => res.data);
    };

    const getMembershipDues = async (membershipId) => {
        return await AxiosInstance.get(`/api/v1/donation/${membershipId}/dues`).then(res => res.data);
    };


    const getDetails = () => {

        const memberId = localStorage.getItem("memberId");
        dispatch({type: "RESET_TOTAL"});

        getMember(memberId).then(member => {

            setPayee(member.firstNm + ', ' + member.lastNm)

            member["campaignMembership"].forEach(cm => {

                getMembershipDues(cm["campaignMembershipId"]).then(dues => {

                    dues.forEach(donation => {
                        console.log("donation: " + JSON.stringify(donation));
                        let dueAmount = donation.minAmountDue - donation.paymentsTotal;
                        // let dueAmount = donation.minAmountDue;
                        dispatch({type: "ADD_TOTAL", payload: dueAmount});
                        dispatch({type: "ADD_LAST_PAYMENT", payload: donation.paymentsTotal});

                        console.log("dueAmount: " + dueAmount)

                        if (donation.paymentsTotal < donation.minAmountDue) {
                            donation.members = [];
                            getMembershipDetails(cm["campaignMembershipId"]).then(membershipDetail => {

                                membershipDetail.members.forEach(membershipMember => {

                                    if (donation.createdDt >= membershipMember.startDt &&
                                        donation.createdDt <= membershipMember.endDt) {

                                        getMemberByMemberId(membershipMember.memberId).then((r) => {

                                            let temp_member = {
                                                id: membershipMember._id,
                                                name: r.firstNm + ', ' + r.lastNm,
                                                birthDt: r.birthDt,
                                                price: membershipMember.price
                                            };

                                            donation.members.push(temp_member);

                                            donation.members.sort((a, b) => {
                                                if (a.birthDt > b.birthDt) {
                                                    return 1
                                                } else {
                                                    return -1
                                                }
                                            });

                                        });

                                    }

                                });

                                dispatch({type: "ADD_MEMBERSHIP_DONATION", payload: donation});

                            });
                        }


                    });


                });

            });

        });

    };

    useEffect(getDetails, [isLoaded]);

    const processPayment = () => {

        // setPaymentContext("Hello you!!!");

        navigate('/account-process-payment',
                {
                    totals: state.totals,
                    summary: state.membershipDonations,
                    payee: payee
                }
            );


        // props.history.push({
        //     pathname: '/account-process-payment',
        //     state: {
        //         totals: state.totals,
        //         summary: state.membershipDonations,
        //         payee: payee
        //     }
        //
        // });

    };

    let membersList = (membershipMembers) => {
        return membershipMembers.map(m =>
            <div key={m.id} className="row" style={{lineHeight: '100%'}}>
                <div className="col-9 col-md-11 ">{m.name}</div>
                <div className="text-right col-3 col-md-1">$ {m.price.toFixed(2)}</div>
            </div>
        );
    };


    let donationDetailsList = state.membershipDonations.map(m =>
        <div key={m.externalRef}>
            <div className="row bg-light-grey" style={{lineHeight: '150%'}}>
                <div className="col-4 col-md-10">{new Date(m.createdDt).toLocaleDateString()}</div>
                <div className="col-4 col-md-1">{m.externalRef}</div>
                <div className="text-right col-4 col-md-1">$ {m.minAmountDue.toFixed(2)}</div>
            </div>
            <div>
                {membersList(m["members"])}
            </div>
        </div>
    );

    let donationDetailsTable =
        <Collapse in={showDetails}>
            <div className="text-right w-100 float-right small">
                {donationDetailsList}
            </div>
        </Collapse>

    return (
        <div className="container">

            <div className="page-header-text mt-2">Welcome to mFC, { JSON.parse(localStorage.getItem("memberData")).firstNm}</div>
            <div className="text-muted mb-4">Here is your account summary and balance.</div>

            <div className="mb-5">
                <AccountSummaryGrid accountSummaries={accountSummaries} />
                {/*<CreditCardInfo />*/}
            </div>





            <Table responsive size="sm" variant="dark">
                <tbody>
                <tr>
                    <td>
                        <div className="row align-items-center h-100">

                            <div className="col-7">
                                <p className="h5 align-middle text-uppercase">Funeral Fund Coverage</p>
                            </div>
                            <div className="col-5">
                                <div className="float-right text-right">
                                    <h3 className="m-0">${state.totals.totalPaymentDue.toFixed(2)}</h3>
                                    <small className="align-top overflow-hidden">Next Payment Due</small>
                                </div>
                            </div>

                        </div>

                    </td>
                </tr>
                </tbody>
            </Table>

            <Table responsive size="sm">
                <thead>
                <tr>
                    <th>Description</th>
                    <th className="text-right">Amount</th>
                </tr>
                </thead>
                <tbody>

                <tr>
                    <td>Last Payment Received</td>
                    <td className="text-right">$ {state.payments.lastPaymentAmt.toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Previous Late Charges</td>
                    <td className="text-right">$ {state.totals.lateChargesAmt.toFixed(2)}</td>
                </tr>
                <tr className="justify-content-center">
                    <td>Total Payment Due
                        <Button variant="link" size="sm"
                                className="text-decoration-none"
                                onClick={() => setShowDetails(!showDetails)}>
                            {showDetails ? "Hide" : "Details"}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path
                                    d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                            </svg>
                        </Button>
                    </td>
                    <td className="text-right align-middle font-weight-bold">
                        <div>
                            $ {state.totals.totalPaymentDue.toFixed(2)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" className="border-0">
                        {donationDetailsTable}
                    </td>
                </tr>


                <tr>
                    <td colSpan="3">
                        <div className="row">
                            <div className="col"/>
                            <div className="col-lg-3 col-xs-2">
                                <Button variant={state.totals.totalPaymentDue > 0? "default" : "secondary" }
                                        // disabled={state.totals.totalPaymentDue > 0? false : true}
                                        className="mt-3 mb-5"
                                        onClick={processPayment}>
                                        Make a Payment
                                </Button>
                            </div>
                        </div>

                    </td>
                </tr>

                </tbody>
            </Table>


        </div>
    )

};


export default AccountSummary;