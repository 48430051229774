import React from "react";

interface IProps {
    code: string,
    message: string,
}

const CustomerErrorComponent = ({ code, message }: IProps) => {

    return (
        <>
            <div className="pt-5 text-center align-content-center">
                <div className="align-bottom d-flex justify-content-center align-middle lh-1">
                    <span><i className="bi bi-info-circle-fill text-danger fs-4 me-2"></i></span>
                    <span className="h5">Error {code}</span>
                </div>
                <div className="lh-1">{message}</div>
            </div>
        </>
    )
};

export default CustomerErrorComponent;