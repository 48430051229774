import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AxiosInstance from "../../services/AxiosInstance";
import {useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {Badge, Dropdown, OverlayTrigger, Popover} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {AgeCalculator, CustomToggle} from "../../utils/Utils";
import NavigateBackMenu from "../../components/NavigateBackMenu";
import member from "./Member";
import Alert from "react-bootstrap/Alert";
import API_V2 from "../../services/API_V2";
import CampaignMembershipService from "../../services/CampaignMembershipService";
import {useAuth} from "../../hooks/useAuth";
import MembershipService from "../../services/CampaignMembershipService";


const MemberCampaignMembership = (props) => {

    const auth = useAuth()
    const [campaignMembership, setCampaignMembership] = useState({});
    const [household, setHousehold] = useState({});
    const {member_id, membership_id} = useParams();
    const [pageErrors, setPageErrors] = useState({});
    const navigate = useNavigate();


    const formatDate = (inputDate) => {
        let d = new Date(inputDate);
        let res = d.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric"
        });
        return res.replaceAll('/', '.');
    }


    const setCampaignMembershipSortedMembers = async () => {

        // let membership  = await AxiosInstance.get(`/api/v1/membership/${membership_id}`);

        let membership = await API_V2.get(`/v2/membership/${membership_id}`);

        // if (membership.data.members !== undefined && membership.members !== null) {  // original
        if (membership.data.members !== undefined && membership.data.members !== null) {
            membership.data.members.sort((a, b) => {

                if (a.memberId == membership.data.primaryMemberId) {
                    return -1;
                } else if (a.isActive && !b.isActive) {
                    return -1;
                } else {
                    return 0;
                }

            });

            setCampaignMembership(membership.data);

        }


        return membership.data;

    }


    useEffect(() => {

        setCampaignMembershipSortedMembers().then(res => {
            setQualifiedHouseholdMembers(res.members);
        });

    }, [membership_id, member_id])


    const setQualifiedHouseholdMembers = (cmembers) => {

        AxiosInstance.get(`/api/v1/member/${member_id}/household`).then((res => {

            let householdRes = {...res.data};
            let qualifiedMembers = [];

            if (cmembers != null && householdRes.members != null) {
                for (let i = 0; i < householdRes.members.length; i++) {
                    let exists = false;
                    cmembers.map(cm => {
                        if (cm.member_id == householdRes.members[i].member_id) {
                            exists = true;
                        }
                    })
                    if (!exists) {
                        qualifiedMembers.push(householdRes.members[i]);
                    }
                }
            }

            householdRes.members = qualifiedMembers;

            setHousehold(householdRes);
        }));
    }


    const handleMemberAdd = (add_member_id) => {
        AxiosInstance.get(`/api/v1/member/${add_member_id}/campaign-membership/${membership_id}/add`).then((res => {
            setCampaignMembershipSortedMembers().then(res => {
                setQualifiedHouseholdMembers(res.members);
            });
        }));
    }

    const handleMemberRemove = (remove_member_id) => {

        AxiosInstance.get(`/api/v1/member/${remove_member_id}/campaign-membership/${membership_id}/remove`).then((res => {
            setCampaignMembershipSortedMembers().then(res => {
                setQualifiedHouseholdMembers(res.members);
            });
        }));
    }

    const handleDeleteCampaignMembership = () => {

        AxiosInstance.delete(`/api/v1/membership/${membership_id}`).then((res) => {
            navigate(`/member/${member_id}`);
        }).catch((err) => {

            setPageErrors({...pageErrors, removeMembership: err.response.data})

            setTimeout(() => {
                delete pageErrors.removeMembership;
                setPageErrors({...pageErrors});
            }, 5000)

        });
    }


    const handleDeactivateMember = (deactivate_memberId) => {

        let membershipData = {...campaignMembership};

        membershipData.members.map((cm) => {

            if (cm.memberId == deactivate_memberId) {
                cm.isActive = !cm.isActive;
                cm.endDt = cm.isActive ? new Date("9999-12-31").getTime() : Date.now();
            }
        });


        AxiosInstance.post(`/api/v1/member/${member_id}/campaign-membership/${membership_id}/`, membershipData).then((res => {
            setCampaignMembershipSortedMembers().then(res => {

            });
        }));

    }

    const handleRefreshPrices = (membershipId) => {

        CampaignMembershipService.refreshPrices(membershipId).then((res) => {
            console.log("prices refreshed successfully");

        }).catch((err) => {
            auth.setError("could NOT refresh prices")
        })
    }

    const popover = (name) => (
        <Popover id="popover-basic">
            <Popover.Body>
                {name} is not in primary's household
            </Popover.Body>
        </Popover>
    );


    const CampaignMemberMenu = ({member, membership,onChange}) => {

        const [isMembershipActive, setMembershipActive] = useState(membership.isActive);

        const handleMakeMemberPrimary = (primaryMemberId) => {
            const membershipData = {
                _id: membership_id,
                primaryMemberId: primaryMemberId,
            }

            AxiosInstance.post(`/api/v1/member/${member_id}/campaign-membership/${membership_id}/`, membershipData).then((res => {
                setCampaignMembershipSortedMembers().then(res => {

                });
            }));

        }

        const handleMembershipStatusChange= () => {
            const new_membership = {
                "_id": membership._id,
                "isActive": !isMembershipActive,
                "modifiedBy": auth.accessTokenData.email,
                "modifiedDt": new Date()
            }

            MembershipService.upsertMembership(new_membership).then((res) => {
                setMembershipActive(membership.isActive);

                onChange && onChange()
                console.log(res);
            })



        }

        return (
            <Dropdown className="d-inline" style={{fontStyle: "normal"}}>
                <Dropdown.Toggle variant="link" id="dropdown-basic" as={CustomToggle}>
                    <i className="bi bi-three-dots-vertical"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    {!member.isPrimary && member.isActive &&
                        <Dropdown.Item key={1}
                                       onClick={() => handleMakeMemberPrimary(member.memberId)}>
                            Make Primary
                        </Dropdown.Item>
                    }

                    <Dropdown.Item key={2} disabled={member.isPrimary} onClick={() => handleDeactivateMember(member.memberId)}>
                        {member.isActive ? "Deactivate Member" : "Activate Member"}
                    </Dropdown.Item>

                    <Dropdown.Divider></Dropdown.Divider>
                    {member.isPrimary &&
                        <Dropdown.Item key={1}
                                       className="text-danger"
                                       onClick={handleMembershipStatusChange}>

                            {isMembershipActive ? "Deactivate Membership" : "Activate Membership"}
                        </Dropdown.Item>
                    }

                    {!member.isPrimary &&
                        <Dropdown.Item key={3}
                                       className="text-danger"
                                       onClick={() => handleMemberRemove(member.member_id)}>
                            Remove Member
                        </Dropdown.Item>
                    }

                </Dropdown.Menu>
            </Dropdown>
        )

    }

    const statusIcon = (v) => {

        switch (v) {
            case true:
                return <i className="bi bi-circle-fill small text-success"></i>;
            case false:
                return <i className="bi bi-circle-fill small text-danger"></i>;
            default:
                return <i className="bi bi-circle-fill small text-warning"></i>
        }
    }


    return (
        <Container>
            <NavigateBackMenu/>

            {/* todo: fix/verify why name is not populating */}
            <div className="h5 mb-5">{campaignMembership.campaignName}</div>

            <div>
                <div className="d-flex justify-content-end">
                    <span className="me-2">Membership Status:</span>
                    <span className="me-1">{statusIcon(campaignMembership.isActive)}</span>

                    {
                        (() => {
                            switch (campaignMembership.isActive) {
                                case true:
                                    return "active"
                                case false:
                                    return "inactive"
                                default:
                                    return "unknown"
                            }
                        })()
                    }
                    <Button className="btn-sm btn-link fw-bold ms-auto"
                            onClick={() => handleRefreshPrices(membership_id)}>
                        <i className="bi bi-arrow-clockwise fs-3 fw-bold"></i>
                    </Button>
                </div>

            </div>


            {
                campaignMembership.externalRef &&
                <div className="small text-secondary">
                    <span className="me-1">External Ref:</span>
                    <a target="_blank"
                       href={`https://funeral.myfundcenter.com/Policy/View.aspx?policyNumber=${campaignMembership.externalRef}`}>
                        {campaignMembership.externalRef}
                        <i className="bi bi-box-arrow-up-right ms-1"></i>
                    </a>
                </div>
            }

            <hr/>

            <div className="d-none d-md-block">
                <Row className="fw-bold">
                    <Col md={6}>Name</Col>
                    <Col md={1}>Age</Col>
                    <Col md={1}>Price</Col>
                    <Col md={2}>Start Dt</Col>
                    <Col md={2}>End Dt</Col>
                </Row>
            </div>
            {
                campaignMembership.members ?
                    campaignMembership.members.map((member, idx) => (
                        <Row key={idx}
                             style={{
                                 fontStyle: !member.isActive ? "italic" : null,
                                 textDecoration: !member.isActive ? "line-through" : "none",
                                 backgroundColor: idx % 2 == 0 ? "#e5e5e5" : null,
                                 color: !member.isActive ? "#6c6c6c" : null,
                             }}

                        >
                            <Col md={6}>

                                <CampaignMemberMenu member={member}
                                                    membership={campaignMembership}
                                                    onChange={setCampaignMembershipSortedMembers}/>

                                <div className="d-inline">
                                    {member.lastNm + ` ` + member.firstNm}
                                    <span className="text-secondary">
                                        {member.memberId == campaignMembership.primaryMemberId ? " (primary)" : null}
                                    </span>
                                    <span className="text-danger" style={{
                                        display: member.inPrimaryHousehold ? "none" : "inline",
                                        marginLeft: 10
                                    }}>
                                        <OverlayTrigger trigger="click"
                                                        placement="bottom"
                                                        overlay={popover(member.firstNm)}>
                                            <i className="bi bi-info-circle-fill"></i>
                                        </OverlayTrigger>

                                    </span>
                                </div>
                            </Col>
                            <Col md={1}>
                                {member.age}
                            </Col>
                            <Col md={1}>
                                {`$ ` + member.price.toFixed(2)}
                            </Col>

                            <Col md={2}>
                                {formatDate(member.startDt)}
                            </Col>
                            <Col md={2}>
                                {formatDate(member.endDt)}
                            </Col>
                        </Row>
                    )) : null
            }
            <div className="d-none d-md-block">
                <Row className="fw-bold">
                    <Col md={6}></Col>
                    <Col md={1}></Col>
                    <Col md={1}>{campaignMembership.totalPrice &&  `$${campaignMembership.totalPrice.toFixed(2)}`}</Col>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                </Row>
            </div>

            <div className="mt-3">
                <Dropdown className={household.members != null && household.members.length > 0 ? "d-inline" : "d-none"}>
                    <Dropdown.Toggle variant=""
                                     id="dropdown-basic"
                                     as={CustomToggle}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        {` `}Add Household Member
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            household.members ?
                                household.members.map((member, idx) => (
                                    <Dropdown.Item key={idx}
                                                   onClick={() => handleMemberAdd(member.member_id)}>
                                        {member.firstNm + ` ` + member.lastNm}
                                    </Dropdown.Item>
                                )) : null
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className="text-center mt-5">
                <Button variant="danger" onClick={handleDeleteCampaignMembership}>
                    Remove Membership
                </Button>
                <Alert variant="danger"
                       className="mt-3"
                       show={pageErrors.removeMembership ? true : false}>
                    {pageErrors.removeMembership ? pageErrors.removeMembership : null}
                </Alert>
            </div>

        </Container>
    );
}

export default MemberCampaignMembership;