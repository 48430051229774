import Form from "react-bootstrap/Form";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useCloverSDK} from "../hooks/useCloverSDK";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";



const WIP = () => {


    return (
      <div>test WIP</div>
    );

}


export default WIP;