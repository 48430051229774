import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import {FloatingLabel, FormControl, FormLabel, Spinner} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import {useCloverSDK} from "../../hooks/useCloverSDK";


const CreditCardView = (props) => {

    const [card, setCard] = useState(props.card);
    const [formErrors, setFormErrors] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);


    useEffect(() => {


        }, [])




    //document.addEventListener(e, this.handleActivity);


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trim();
        const name = target.name;

        let newCard = {
            ...card,
            [name]: value
        }

        delete formErrors[name];
        setFormErrors({...formErrors});

        setCard(newCard);
        props.onChange(newCard);

    }

    const normalizePhoneInput1 = (e) => {

        const target = e.target;
        const value = target.value.replace(/[^\d]/g, '');
        const name = target.name;
        const cvLength = value.length;

        let temp_val = value;

        if (cvLength > 0 && cvLength < 4) {
            temp_val = `${temp_val.slice(0, 4)}`
        } else if (cvLength > 4 && cvLength <= 8) {
            temp_val = `${temp_val.slice(0, 4)} ${temp_val.slice(4)}`
        } else if (cvLength > 8 && cvLength <= 12) {
            temp_val = `${temp_val.slice(0, 4)} ${temp_val.slice(4,8)} ${temp_val.slice(8)}`
        } else if (cvLength > 12) {
            temp_val = `${temp_val.slice(0, 4)} ${temp_val.slice(4,8)} ${temp_val.slice(8,12)} ${temp_val.slice(12,16)}`
        }

        setCard({
            ...card,
            [name]: temp_val
        });

        target.value = temp_val;

        props.onChange(card);

    };

    const ccYearsOptions = (start) => {
        const range = [...Array(12).keys()];
        const options = [];

        options.push(<option key={0} value={0}>Year</option>); // first/default option

        range.map((number, i) => {
                options.push(<option key={start + i} value={start + i}>{start + i}</option>);
            }
        )

        return options;
    };

    const ccMonthsOptions = () => {
        const range = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const options = [];

        options.push(<option key={0} value={0}>Month</option>); // first/default option

        range.map((month, idx) => {
                let i = idx < 9 ? '0' + (idx + 1) : idx + 1;
                options.push(<option key={i} value={i}>{i + '-' + month}</option>);
            }
        )

        return options;

    };

    return (
        <div>

        <Card className="mt-3">
            <Card.Header style={{backgroundColor:"#ebf1fc"}}>Payment Card Information</Card.Header>
            <Card.Body className='d-grid gap-2'>
                <Row>
                    <Col>
                        <InputGroup>

                            <span className="input-group-text" style={{backgroundColor:"#ebf1fc"}}>
                                    <svg className="bi bi-person-fill" width="20" height="20"
                                         viewBox="0 0 20 20"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M5 16s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H5zm5-6a3 3 0 100-6 3 3 0 000 6z"
                                          clipRule="evenodd"/>
                                    </svg>
                            </span>

                            <Form.Control name="name"
                                          required type="input"
                                          isInvalid={formErrors.cardholderName}
                                // isValid={valid}
                                          onChange={handleInputChange}
                                          placeholder="Name as it appears on the card"/>
                            <Form.Control.Feedback type="invalid">{formErrors.cardholderName}</Form.Control.Feedback>

                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup>
                            <span className="input-group-text">
                                <svg className="bi bi-credit-card" width="20" height="20"
                                     viewBox="0 0 20 20"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M16 5H4a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1zM4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H4z"
                                          clipRule="evenodd"/>
                                    <rect width="3" height="3" x="4" y="11" rx="1"/>
                                    <path d="M3 7h14v2H3z"/>
                                </svg>
                            </span>
                            <Form.Control name="number" required type="input"
                                          isInvalid={formErrors.cardNumber}
                                          // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                // isValid={valid}
                                          onChange={normalizePhoneInput1}
                                          placeholder="Your card number"/>
                            <Form.Control.Feedback type="invalid">{formErrors.cardNumber}</Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5} xs={5}>
                        <InputGroup>
                            <Form.Control name="expMonth" as="select" size="sm"
                                          isInvalid={formErrors.expMonth}
                                        // isValid={valid}
                                          onChange={handleInputChange}
                                          placeholder="MM"
                                          required>
                                {ccMonthsOptions()}
                            </Form.Control>
                            <Form.Control name="expYear" className="" as="select" size="sm"
                                          isInvalid={formErrors.expYear}
                                // isValid={valid}
                                          onChange={handleInputChange}
                                          placeholder="YY"
                                          required>
                                {ccYearsOptions(new Date().getFullYear())}
                            </Form.Control>
                        </InputGroup>
                    </Col>
                    <Col/>
                    <Col md={3} xs={3}>
                        <Form.Control name="CVV"
                                      isInvalid={formErrors.CCV}
                            // isValid={valid}
                                      onChange={handleInputChange}
                                      required
                                      type="input"
                                      size="sm"
                                      maxLength="3"
                                      placeholder="CVV"/>

                    </Col>
                </Row>

            </Card.Body>
            <Card.Footer>
                <Button variant="primary"
                    // onClick={test}
                        size="sm"
                    // disabled={formData.organization != null? false:true}
                        className="">
                    Add Card
                </Button>
            </Card.Footer>
        </Card>
        </div>
    )

}

CreditCardView.defaultProps = {
    card: {
        name: "John Smith",
        number: "1234234534564567",
        expYear: "2027",
        expMonth: "05",
        CVV: "123"
    },
    mask: true
}

export default CreditCardView;