import API_V2 from "./API_V2";
import {Contact} from "../interfaces";

class IdentityService {


    static checkPermission(resourceUri: string, permission:string) {

        const payload = {
            "resourceUri": resourceUri,
            "permission": permission
        }

        return API_V2.post(`/v2/identity/permission/check`, payload)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

}

export default IdentityService;