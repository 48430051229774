import React from "react";
import ContactCard from "./ContactCard";
import {Contact} from '../../interfaces';

interface ContactCardsProps {
    contacts: Contact[];
    onDelete?: (member_id: string) => void;
}

const ContactCards = ({contacts, onDelete}: ContactCardsProps) => {

    const renderContactCard = (contact: Contact, idx: number) => (
        <div key={idx} className="my-1">
            <ContactCard contact={contact} onDelete={onDelete}/>
        </div>
    );

    return <>{contacts.map(renderContactCard)}</>;
};

export default ContactCards;