


const MemberSearch = () => {

    return (
        <>
            member search placeholder
        </>
    );
}


export default MemberSearch;