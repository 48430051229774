import React, {useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {Option} from "react-bootstrap-typeahead/types/types";
import API_V2 from "../services/API_V2";
import AxiosInstance from "../services/AxiosInstance";
import Avatar from "react-avatar";
import {FloatingLabel} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import OrganizationService from "../services/OrganizationService";
import {formatDateSimple} from "../utils/Utils";

interface Item {
    avatar_url: string;
    id: string;
    login: string;
}

interface props {
    data: Item[];
    controlMetadata: any;
    organizationNumber?: string;
    onChange: any
}


interface Response {
    items: Item[];
}

const MemeberSearchSelect = ({controlMetadata, onChange}: props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<any>();
    const {props} = controlMetadata;

    console.log(controlMetadata);

    const handleSearch = (query: string) => {

        /* example-start */
        const SEARCH_URI = 'https://api.github.com/search/users';
        setIsLoading(true);

        fetch(`${SEARCH_URI}?q=${query}+in:login&page=1&per_page=50`)
            .then((resp) => resp.json())
            .then(({items}: Response) => {
                setOptions(items);
                setIsLoading(false);
            });
    };


    const handleSearchNew = (query: string) => {


        // todo: replace this with the V2 api
        // todo: cross organization search should be based on user's permissions
        // AxiosInstance.get(`/api/v1/organization/1I56JGGD8M/members/page/1/size/20/?searchTerm=${query}`).then((res => {
        //     // console.log(res.data.members);
        //     setOptions(res.data.members);
        // }));

        OrganizationService.searchMembers(props.organizationNumber, query).then((res) => {
            setOptions(res);
        })

    }

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    return (
        <AsyncTypeahead
            filterBy={filterBy}

            id={controlMetadata.name}
            isLoading={isLoading}
            labelKey={(option: any) => `${option.firstNm} ${option.lastNm}`}
            minLength={3}
            onSearch={handleSearchNew}
            options={options}
            placeholder={controlMetadata.placeholder}
            onChange={(e: any) => {

                const c_event = {
                    target: {
                        name: controlMetadata.name,
                        value: Object.keys(e).length > 0 ? e[0].id : ''
                    }
                }
                console.log(e);
                console.log(c_event);
                onChange(c_event)

            }}
            renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
                <FloatingLabel
                    controlId={controlMetadata.name + '-floatingLabel'}
                    label={controlMetadata.label}
                    // className="mb-3"
                >
                    <input
                        {...inputProps}
                        type="input"
                        className="form-control"
                        // className="text-danger py-5"
                        ref={(input:any) => {
                            // Be sure to correctly handle these refs. In many cases, both can simply receive
                            // the underlying input node, but `referenceElementRef can receive a wrapper node if
                            // your custom input is more complex (See TypeaheadInputMulti for an example).
                            inputRef(input);
                            referenceElementRef(input);
                        }}
                    />
                </FloatingLabel>
            )}
            renderMenuItemChildren={(option: any, props, index) => {
                return (
                    <div className="d-flex">
                        <div className="align-self-center">
                            <Avatar name={`${option.firstNm} ${option.lastNm}`} color="#D1D1D1" round={true} size={"25"}/>
                        </div>
                        <div className="mb-1">
                            <div className="ms-2 small lh-1">{option.firstNm} {option.lastNm}</div>
                            <div className="ms-2 text-secondary small lh-1">
                                <span className="small">Birth Date:</span>
                                {formatDateSimple(option.birthDt)}
                            </div>
                        </div>
                    </div>
                )

            }}
        />
    );
};
/* example-end */

export default MemeberSearchSelect;