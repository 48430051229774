import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AxiosInstance from "../../services/AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {AgeCalculator, CustomToggle} from "../../utils/Utils";
import HouseholdAddExisting from "../Household/HouseholdAddExisting";
import HouseholdLink from "../Household/HouseholdLink";


const MemberHousehold = (props) => {


    const [household, setHousehold] = useState({});
    const [showAddExisting, setShowAddExisting] = useState(false);
    const [showLinkHousehold, setShowLinkHousehold] = useState(false);
    // const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
            if (props.id != null) {
                getHouseholdSorted().then(household => {
                    setHousehold(household);
                });
            }
        }, [props.id]
    );

    const getHouseholdSorted = async () => {

        let householdRes = await AxiosInstance.get(`/api/v1/member/${props.id}/household`);

        if (householdRes.data.members !== undefined && householdRes.members !== null) {
            householdRes.data.members.sort((a, b) => {

                if (a.memberId == householdRes.data.primaryMemberId) {
                    return -1;
                }

                if (a.isActive && !b.isActive) {
                    return -1;
                }

                if (a.age > b.age && a.isActive == true) {
                    return -1;
                } else {
                    return 0;
                }

            });
        }

        return householdRes.data;
    }


    const handleMemberRemove = (remove_member_id) => {

        AxiosInstance.get(`/api/v1/member/${remove_member_id}/household/${household._id}/remove`).then((res => {
            getHouseholdSorted().then(household => {
                setHousehold(household);
            });
        }));
    }

    const handleHouseholdExistingMemberAdded = () => {
        setShowAddExisting(false);

        if (props.id != null) {
            getHouseholdSorted().then(household => {
                setHousehold(household);
            });
        }
    }

    const handleMakePrimary = (primary_member_id) => {

        const memberHouseholdPayload = {
            member_id: primary_member_id,
            household_id: household._id,
            isPrimary: true
        }

        AxiosInstance.post(`/api/v1/member/${primary_member_id}/household/add`, memberHouseholdPayload).then((res => {
            if (props.id != null) {
                getHouseholdSorted().then(household => {
                    setHousehold(household);
                });
            }
        }));
    }


    const handleMakeActive = (active_member_id, isActive) => {

        const memberHouseholdPayload = {
            member_id: active_member_id,
            household_id: household._id,
            isActive: isActive
        }

        AxiosInstance.post(`/api/v1/member/${active_member_id}/household/add`, memberHouseholdPayload).then((res => {
            if (props.id != null) {
                getHouseholdSorted().then(household => {
                    setHousehold(household);
                });
            }
        }));
    }


    const renderDropdownMenu = (member) => {
        return (
            <Dropdown className="d-inline" style={{fontStyle: "normal"}}>
                <Dropdown.Toggle variant="link" id="dropdown-basic" as={CustomToggle}>
                    {member.memberId !== household.primaryMemberId &&
                        <i className="bi bi-three-dots-vertical"/>
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {member.memberId !== household.primaryMemberId &&
                        <Dropdown.Item key={1}
                                       onClick={() => handleMakePrimary(member.member_id)}
                        >
                            Make Primary
                        </Dropdown.Item>
                    }
                    <Dropdown.Item key={2}
                                   onClick={() => handleMakeActive(member.member_id, !member.isActive)}>
                        {member.isActive ? "Deactivate" : "Activate"}
                    </Dropdown.Item>
                    <Dropdown.Divider></Dropdown.Divider>
                    <Dropdown.Item key={3}
                                   className="text-danger"
                                   onClick={() => handleMemberRemove(member.member_id)}
                    >
                        Remove from Household
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )

    }


    const renderHouseholdMembers = (householdMembers) => {

        if (householdMembers !== undefined) {
            return householdMembers.map((member, idx) =>
                <div className={`${idx % 2 === 0 && "grid-row-alternative"} p-1`}
                     style={{
                         fontStyle: !member.isActive ? "italic" : null,
                         textDecoration: !member.isActive ? "line-through" : "none",
                         color: !member.isActive ? "#6c6c6c" : null,
                     }}
                >

                    <div className="d-flex">

                        <div className="px-2">
                            <i className="bi bi-person-circle fs-3 text-secondary"></i>
                        </div>

                        <div className="w-100">
                            <div className="d-flex">
                                <div>
                                    <Link to={`/member/${member.member_id}`}>
                                        <span>{`${member.firstNm} ${member.lastNm}`}</span>
                                    </Link>
                                </div>
                                <div className="text-secondary px-1">
                                    {household.primaryMemberId === member.memberId && ` (primary)`}
                                </div>
                                <div className="ms-auto">
                                    {renderDropdownMenu(member)}
                                </div>
                            </div>

                            <div>
                                <div className="d-flex text-secondary small">
                                    <div className="small">
                                        relationship: {member.relationship && member.relationship.toLocaleLowerCase()}
                                    </div>
                                    <div className="px-2 small">
                                        age: {member.age}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    {/*<div className="d-flex">*/}
                    {/*    <div className="">XXX</div>*/}

                    {/*    <div className="">*/}
                    {/*        <div className="d-flex">*/}
                    {/*            <div>*/}
                    {/*                <Link to={`/member/${member.member_id}`}>*/}
                    {/*                    <span>{`${member.firstNm} ${member.lastNm}`}</span>*/}
                    {/*                </Link>*/}
                    {/*            </div>*/}
                    {/*            <div className="text-secondary px-1">*/}
                    {/*                {household.primaryMemberId === member.memberId && ` (primary)`}*/}
                    {/*            </div>*/}
                    {/*            <div className="ms-auto">{renderDropdownMenu(member)}</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="d-block">*/}
                    {/*        ****/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="d-flex text-secondary small">*/}
                    {/*    <div>*/}
                    {/*        relationship: {member.relationship && member.relationship.toLocaleLowerCase()}*/}
                    {/*    </div>*/}
                    {/*    <div className="px-2">*/}
                    {/*        age: {member.age}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            )
        } else {
            return <div>no members</div>
        }
    }


    return (
        <>
            <HouseholdAddExisting household_id={household._id}
                                  onAdded={handleHouseholdExistingMemberAdded}
                                  onClosed={() => setShowAddExisting(!showAddExisting)}
                                  show={showAddExisting}
            />

            <div className="mt-3">
                <Button variant="link" className="dark-blue-link-button"
                        onClick={() => navigate('/member/create', {
                            state: {
                                organizationNumber: props.organizationNumber,
                                household_id: household._id,
                                relationship: "-"
                            }
                        })}>
                    <span>ADD NEW MEMBER</span>
                </Button>
                <Button variant="link" className="dark-blue-link-button"
                        onClick={() => setShowAddExisting(!showAddExisting)}>
                    <span>ADD EXISTING MEMBER</span>
                </Button>
            </div>
            <div className="small text-secondary">

                <span className="me-1">Household Id:</span>{household._id}

            </div>
            <div className="mt-3 pb-5 col-lg-4 col-md-10 col-sm-10 ">
                {renderHouseholdMembers(household.members)}
            </div>
        </>
    )
}

export default MemberHousehold;