import React from "react";
import {useLocation} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";


const ThankYouForPayment = (props) => {

    const {state} = useLocation();
    const navigate = useNavigate();


    return (
        <div className="container">

            <div className="justify-content-center text-center mt-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="green"
                     className="bi bi-check2-circle" viewBox="0 0 16 16">
                    <path
                        d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                    <path
                        d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                </svg>
                <div className="h5 mt-3" style={{color: 'green', fontSize:"24px", marginBottom: '0px'}}>
                    Thank you for your donation!
                </div>
                <div className="small">
                    A receipt for this transaction has been sent via email for your records.
                </div>
            </div>
            <div className="font-weight-bolder text-center mt-5">

                <div className=""
                     style={{fontSize:"24px", marginBottom: '0px'}}
                >
                    Total Donation Amount
                </div>
                <div className="m-0"
                     style={{fontSize:"52px",
                         fontWeight:"bold",
                         lineHeight: 1,
                         color: "#0a1a86"}}>
                    $ {state.totalAmount.toFixed(2)}
                </div>
            </div>

            <div className="mt-5 text-center">
                <Button size="lg"
                        onClick={() => navigate('/my/overview')}
                        variant="outline-primary"
                        className="px-5">
                    Continue
                </Button>
            </div>

        </div>
    );

}


ThankYouForPayment.defaultProps = {
    paymentAmount: 0
}

export default ThankYouForPayment;