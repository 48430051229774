import * as React from "react";
import {Link} from "react-router-dom";
import {Fragment} from "react";


const TreeView = (props) => {


    const getRootNodes = () => {
        let rootNodes = [];

        props.data.map( (treeNode) => {

            // standardize id and parent column names
            if (!treeNode.id) {
                treeNode.id = treeNode[props.idColumn];
                delete treeNode[props.idColumnName];
            }
            if (!treeNode.parent) {
                treeNode.parent = treeNode[props.parentColumn];
                delete treeNode[props.parentColumnName];
            }
            if (!treeNode.name) {
                treeNode.name = treeNode[props.nameColumnn];
                delete treeNode[props.parentColumnName];
            }

            if (treeNode.parent == null ||
                treeNode.parent == treeNode.id ||
                treeNode.parent === undefined
                ) {

                treeNode.children = getChildrenNodes(treeNode);
                rootNodes.push(treeNode);
            }
        })

        return rootNodes;
    }

    const getChildrenNodes = (node) => {

        let childrenNodes = [];

        props.data.map( (treeNode) => {

            // standardize id and parent column names
            if (!treeNode.id) {
                treeNode.id = treeNode[props.idColumn];
                delete treeNode[props.idColumnName];
            }
            if (!treeNode.parent) {
                treeNode.parent = treeNode[props.parentColumn];
                delete treeNode[props.parentColumnName];
            }
            if (!treeNode.name) {
                treeNode.name = treeNode[props.nameColumnn];
                delete treeNode[props.parentColumnName];
            }

            if (treeNode.id != treeNode.parent &&
                node.id == treeNode.parent) {

                treeNode.children = getChildrenNodes(treeNode);
                childrenNodes.push(treeNode);

            }
        })

        return childrenNodes;
    }

    const handleOnClick = (e) => {
        e.preventDefault();

        props.onClick(e.target.name);
    }

    const renderHierarchy = (treeNodes, level) => {

        let treeView = [];

        treeNodes.map( (treeNode) => {

            let item = (<div key={treeNode.id} style={{marginLeft:25 * level}}>

                            {treeNode.children.length > 0 ?
                                <div className="d-inline" >
                                <svg  width="16" height="16" fill="currentColor"
                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                                </svg>{` `}</div>: null}

                            <div className="d-inline">
                                {/*<Link to={`${treeNode.id}`}>{treeNode.name}</Link>*/}
                                <Link name={treeNode.id}
                                      className="text-decoration-none"
                                      onClick={handleOnClick}>
                                    {treeNode.name}
                                </Link>
                            </div>
                            <div>
                                {renderHierarchy(treeNode.children, level+1)}
                            </div>
                       </div>);

            treeView.push(item);

            }
        )

        return treeView;

    }


    return (

          <Fragment>
              {renderHierarchy(getRootNodes(),0)}
          </Fragment>

    );

}

TreeView.defaultProps = {
    idColumn: "id",
    parentColumn: "parentId",
    nameColumn: "name",
    data: [
        {id: 1, name: "Node 1", parentId: 1},
        {id: 2, name: "Node 2", parentId: 1},
        {id: 3, name: "Node 3", parentId: 1},
        {id: 4, name: "Node 4", parentId: 2},
        {id: 5, name: "Node 5", parentId: 5},
        {id: 6, name: "Node 6", parentId: 5},
        {id: 7, name: "Node 7", parentId: 4},
        {id: 8, name: "Node 8", parentId: 4},
    ]
}


export default TreeView;