import React, {useState, Fragment} from 'react';
import AxiosInstance from "../../services/AxiosInstance";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import UpdatedAddressConfirmation from "./UpdatedAddressConfirmation";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router";


const Person = (props) => {

    const navigate = useNavigate();
    const [valid, setValid] = useState(false);
    const [success,setSuccess] = useState(false);
    const [alert, setAlert] = useState({"variant": "primary", "message": ""});
    const [showAlert, setShowAlert] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const memberDataJson = JSON.parse(localStorage.getItem('memberData'));
    const [formData, setFormData] = useState({
        "streetAddress": memberDataJson.address.streetAddress,
        "cityNm": memberDataJson.address.cityNm,
        "stateCd": memberDataJson.address.stateCd,
        "postalCd": memberDataJson.address.postalCd,
        "phone": memberDataJson.phones[0].phoneNbr,

    });

    AxiosInstance.defaults.headers['Authorization-Token'] = localStorage.getItem('userToken');

    const states = {
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District Of Columbia",
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
    }

    const normalizePhoneInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    const normalizePhoneInput1 = (e) => {

        const target = e.target;
        const value = target.value.replace(/[^\d]/g, '');
        const name = target.name;
        const cvLength = value.length;

        let temp_val = value;

        if (value.length < 4) {
            temp_val = temp_val;
        } else if (value.length < 7) {
            temp_val = `(${temp_val.slice(0, 3)}) ${temp_val.slice(3)}`
        } else {
            temp_val = `(${temp_val.slice(0, 3)}) ${temp_val.slice(3, 6)}-${temp_val.slice(6, 10)}`;
        }


        setFormData({
            ...formData,
            [name]: temp_val
        });
        target.value = temp_val;

    };

    const handleSubmit = (e) => {

        e.preventDefault();
        e.stopPropagation();

        let errors = {};

        // !/([A-Z0-9]){4,}$/i.test(formData.orgNbr) ? errors.orgNbr = "Invalid Org number" :
        //     delete errors.orgNbr;
        //
        // !/([A-Z0-9]){4,}$/i.test(formData.lastNm) ? errors.lastNm = "Invalid last name" :
        //     delete errors.lastNm;
        //
        // !/(\d+){4,}$/i.test(formData.phone) ? errors.phone = "Invalid phone number" :
        //     delete errors.phone;
        //
        // !/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(formData.birthDt) ? errors.birthDt = "Invalid birthDate" :
        //     delete errors.birthDt;

        setFormErrors({...errors})

        if (Object.keys(errors).length === 0) {

            const addressData = {
                "memberId": memberDataJson.memberId,
                "address" : {
                    "streetAddress" : formData.streetAddress,
                    "cityNm": formData.cityNm,
                    "stateCd": formData.stateCd,
                    "postalCd": formData.postalCd,
                    "modifiedDt": Date.now()
                },
                "phones": [
                    {"phoneNbr": formData.phone}
                ]
            }

            AxiosInstance.post('/api/v1/member', addressData)
                .then(res => {
                    let {data} = res;
                    console.log(data);
                    setValid(true);

                    AxiosInstance.get('/api/v1/member/' + localStorage.getItem("memberId")).then(
                        res => {


                            localStorage.setItem('memberData',  JSON.stringify(res.data));

                            return;

                        }
                    ).catch( error => console.log(error)

                    );

                    setAlert({variant: "primary", message: ""})
                    setShowAlert(true);
                    setSuccess(true);

                }).catch((err) => {
                    setAlert({variant: "danger", message: "Cannot update your address. Please verify your information and try again."});
                    setShowAlert(true);

                })
        }



    };


    const handlePhoneInputChange = (event) => {
        const target = event.target;
        const value = target.value.replace(/[^0-9]/g, '');
        const name = target.name;

        let temp_val = value;

        if (value.length > 0) {
            temp_val = "(###) ###-" + temp_val.substring(0, 4);
        }
        setFormData({
            ...formData,
            [name]: temp_val
        });

        target.value = temp_val;

    }


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    }


    // const [validated, setValidated] = useState(false);

    // const handleSubmit = event => {
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //     }
    //
    //     setValidated(true);
    // };

    const updateAddressForm =
        <Container>
            <Row>
                <Col>
                    <div className="page-header-text">Update Contact Information</div>
                </Col>
            </Row>


            <section className="col-12 col-lg-6">
                <Form noValidate onSubmit={handleSubmit}>
                    <Alert variant={alert.variant} show={showAlert}>
                        {alert.message}
                    </Alert>
                    <Form.Group className="d-grid gap-2">
                        <Row>
                            <Col>
                                <Form.Control name="streetAddress" required type="input"
                                              maxLength={100}
                                              value={formData.streetAddress}
                                              onChange={handleInputChange}
                                              placeholder="House number and street name"
                                />
                            </Col>
                        </Row>
                        <Row style={{'--bs-gutter-x': '0.4rem'}}>
                            <Col md={8}  xs={7}>
                                <Form.Control name="cityNm" required type="input"
                                              onChange={handleInputChange}
                                              maxLength={30}
                                              value={formData.cityNm}
                                              placeholder="City"
                                />
                            </Col>
                            <Col md={2} xs={2} >
                                <Form.Control name="stateCd" required type="input"
                                              value={formData.stateCd}
                                              maxLength={2}
                                              onChange={handleInputChange}
                                              onKeyPress={(e) => !/[a-zA-Z]/.test(e.key) && e.preventDefault()}
                                              placeholder="State"
                                />
                            </Col>
                            <Col>
                                <Form.Control name="postalCd" required type="input"
                                              value={formData.postalCd}
                                              maxLength={5}
                                              onChange={handleInputChange}
                                              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                              placeholder="Postal code"
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Control name="phone" required type="input"
                                              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                              value={formData.phone}
                                              onChange={normalizePhoneInput1}
                                    // isInvalid={formErrors.phone}
                                    // isValid={valid}
                                              placeholder="Last 4 of phone number"
                                              autoComplete="disabled"/>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col className="d-grid">
                                <Button variant="default" className="d-block" type="submit">
                                    Update
                                </Button>
                            </Col>
                            <Col className="d-grid">
                                <Button variant="outline-dark" className="d-inline" onClick={()=>{navigate('/address-confirm')}} >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>


                    </Form.Group>
                </Form>
            </section>
        </Container>


    const updatedAddressConfirmation = <UpdatedAddressConfirmation address={formData} />;

    return (
        success ? updatedAddressConfirmation : updateAddressForm
    )

};

export default Person;







