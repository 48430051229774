import API_V2 from "./API_V2";
import {run} from "node:test";

class CampaignService {


    static getCampaignRuns(campaignId: string) {
        return API_V2.get(`/v2/campaigns/${campaignId}/runs?page_number=1&page_size=10`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static getCampaignRun(campaignId: string, runId: string) {
        return API_V2.get(`/v2/campaigns/${campaignId}/runs/${runId}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static getCampaignMemberships(campaignId: string, pageNumber: number, pageSize: number = 10) {
        return API_V2.get(`/v2/campaigns/${campaignId}/memberships?page_number=${pageNumber}&page_size=${pageSize}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static getCampaignRunDonations(campaignId: string, runId: string, pageNumber: number, pageSize: number = 10) {
        return API_V2.get(`/v2/campaigns/${campaignId}/runs/${runId}/donations?page_number=${pageNumber}&page_size=${pageSize}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static getCampaignRunDonationsByOrganization(organizationNumber: string,
                                                 campaignId: string,
                                                 runId: string,
                                                 openBalance: boolean = false,
                                                 pageNumber: number,
                                                 pageSize: number = 10) {
        return API_V2.get(`/v2/campaigns/${campaignId}/runs/${runId}/donations?organization_number=${organizationNumber}&open_balance=${openBalance}&page_number=${pageNumber}&page_size=${pageSize}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }


}

export default CampaignService;