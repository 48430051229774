import React, {Fragment, useState} from 'react';
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import AxiosInstance from '../../services/AxiosInstance.js'
import {useNavigate} from "react-router";


const SignUp = (props) => {

    const [valid, setValid] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        "email": "",
        "password": "",
        "confirmPassword": "",
        "terms": false
    });
    const navigate = useNavigate();

    const handleSubmit = event => {

        event.preventDefault();
        event.stopPropagation();

        let errors = {};

        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email) ? errors.email = "Invalid email address" :
            delete errors.email;

        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{6,}$/.test(formData.password) ?
            errors.password = "Minimum 6 characters, at least one uppercase letter, " +
                "one lowercase letter and one number." :
            delete errors.password;

        formData.password !== formData.confirmPassword ? errors.confirmPassword = "Password does not match" :
            delete errors.confirmPassword;


        if (Object.keys(errors).length === 0) {
            AxiosInstance.defaults.headers['callbackUrl'] = window.location.origin;
            AxiosInstance.post('/register', formData)
                .then((res, err) => {
                    setValid(true);
                    setTimeout(() => navigate('/confirm-email?email=' + formData.email), 2000);

                }).catch((err) => {
                console.log(JSON.stringify(err.response.data));
                if (err.response.data === "Email Exists") {
                    errors.email = "This email address is already registered"
                }

                setFormErrors({...errors})
            });
        }

        setFormErrors({...errors})


    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    }


    return (
        <div className="container">
            <div className="row justify-content-md-center mt-5">
                <div className="col-md-5 text-center">
                    <div className="h4 mb-1">Create your account</div>
                    <div className="text-muted small">(all fields are required)</div>
                </div>

            </div>

            <div className="row justify-content-md-center mt-2 pb-5">

                <Form className="col-md-4 col-sm-8 pb-5 d-grid" autoComplete="disabled" noValidate
                      onSubmit={handleSubmit}>

                    <Form.Group>

                        <InputGroup className="mt-2">

                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>

                            <Form.Control name="email" type="input" placeholder="Email"
                                          isInvalid={formErrors.email}
                                          isValid={valid}
                                          onChange={handleInputChange}/>
                            <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mt-2">
                            <Form.Control name="password" required type="password"
                                          isInvalid={formErrors.password}
                                          isValid={valid}
                                          placeholder="Password" autoComplete="disabled" onChange={handleInputChange}/>
                            <Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mt-2">
                            <Form.Control name="confirmPassword" required type="password"
                                          isInvalid={formErrors.confirmPassword || formErrors.password}
                                          isValid={valid}
                                          placeholder="Confirm Password" autoComplete="disabled"
                                          onChange={handleInputChange}/>
                            <Form.Control.Feedback type="invalid">{formErrors.confirmPassword}</Form.Control.Feedback>
                        </InputGroup>

                    </Form.Group>

                    <div className="justify-content-center d-flex pt-3">
                        <Button variant='default' type="submit" className="w-75" disabled={!formData.terms}>
                            Sign Up
                        </Button>
                    </div>

                    <div className="small text-muted mt-3">


                        <div className="small text-muted">
                            By creating an account, you are agreeing to
                            our <a
                            href="/terms" className="text-decoration-underline">Terms of Use</a> and opting
                            in to receive occasional updates about your account and subscriptions through
                            emails, text messages, and/or phone calls.

                        </div>
                        <div className="mt-2">
                            <Form.Check type="checkbox">
                                <Form.Check.Input name="terms" className="d-inline" type="checkbox"
                                                  onChange={handleInputChange} checked={formData.terms}/>
                                <Form.Check.Label className="d-inline small">
                                    I agree with the terms and opt-in to receive account-related notifications
                                </Form.Check.Label>
                            </Form.Check>
                        </div>
                        <div className="mt-4 text-muted small">
                            For a comprehensive understanding of how we handle your personal information, please
                            refer to our <a href="/privacy-notice" className="text-decoration-underline">Privacy
                            Notice</a>.
                        </div>


                    </div>


                    <div className="mt-4 text-muted text-center">
                        <span className="mt-2">Already have an account?</span>
                        {` `}<Button variant="link" className="p-0 pb-2"
                                     onClick={() => navigate('/sign-in')}>
                        Sign in here.
                    </Button>
                    </div>


                </Form>

            </div>
        </div>
    );
};

export default (SignUp);