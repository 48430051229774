import React, {useEffect, useLayoutEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import ContactAdd from "../../components/contact/ContactAdd";
import ContactCards from "../../components/contact/ContactCards";
import Tab, {TabProps} from "react-bootstrap/Tab";
import OrganizationService from "../../services/OrganizationService";
import {useAuth} from "../../hooks/useAuth";
import {TabContainer, TabContent, TabPane} from "react-bootstrap";
import Organization from "./Organization";
import IdentityService from "../../services/IdentityService";


interface IOrganizationContactsTabProps {
    organizationNumber?: string;
}


const OrganizationContactsTabContent = ({organizationNumber}: IOrganizationContactsTabProps) => {


    const auth = useAuth();
    const [canAddContact, setCanAddContact] = useState(false);
    const [contacts, setContacts] = React.useState([]);
    const [contactAddShow, setContactAddShow] = useState(false);


    useEffect(() => {
        handleContactsRefresh();
        if (organizationNumber !== undefined) {
            IdentityService.checkPermission(organizationNumber, "organization.contacts.create").then(res => {
                if (res === true) {
                    setCanAddContact(true);
                } else { setCanAddContact(false); }
            })
        }


    }, [organizationNumber]);


    const handleContactsRefresh = () => {

        if (organizationNumber !== undefined) {
            OrganizationService.getContacts(organizationNumber).then((res) => {
                setContacts(res)
            }).catch(error => {
                auth.setError && auth.setError(error.message);
            })
        }
    }

    useEffect(() => {
        // console.log(`children: ${children}`);
    }, []);

    return (
        <>
            {canAddContact &&
                <div className="d-flex justify-self-end">
                    <Button className="bnt btn-sm btn-link dark-blue-link-button"
                            onClick={() => setContactAddShow(true)}>
                        <i className="bi bi-plus-lg me-1 fw-bolder"></i>
                        <span>Add contact</span>
                    </Button>
                </div>
            }
            <ContactAdd organizationNumber={organizationNumber}
                        show={contactAddShow}
                        onComplete={handleContactsRefresh}
                        onHide={() => setContactAddShow(false)}/>
            <ContactCards contacts={contacts} />
        </>
        //
        // </TabPane>
        // {children}
        // <Tab eventKey={eventKey} title={title}>
        //     <TabContent>test test</TabContent>
        //     <span>test test test test</span>

        //     {/*}*/}

        // </Tab>
    )

}


export default OrganizationContactsTabContent;
