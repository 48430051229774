import React from 'react';
import CampaignRunDonationsByOrganization from "../../components/Campaign/CampaignRunDonationsByOrganization";
import {useParams} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";


const CampaignRunDonations = () => {

    const {campaignId, campaignRunId} = useParams();
    const auth = useAuth()

    return (
        <>
            <CampaignRunDonationsByOrganization campaignId={campaignId} runId={campaignRunId} organizationNumber={auth.accessTokenData.oid} />
        </>
    )

}




export default CampaignRunDonations;