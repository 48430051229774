import {useLocation, useParams, useSearchParams} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import AxiosInstance from "../../services/AxiosInstance";
import Container from "react-bootstrap/Container";
import AddressView from "../../components/address/AddressView";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import {Badge, Breadcrumb, Tab, Tabs} from "react-bootstrap";
import MemberDonationsView from "../../components/Member/MemberDonationsView";
import PhoneView from "../../components/PhoneView";
import OrganizationCampaigns from "../../components/organization/OrganizationCampaigns";
import MemberCampaigns from "../../components/Member/MemberCampaigns";
import MemberHousehold from "../../components/Member/MemberHouseholdNew";
import Alert from "react-bootstrap/Alert";
import MemberLinkedAccounts from "../../components/Member/MemberLinkedAccounts";
import MemberService from "../../services/MemberService"

import {Button, OverlayTrigger, Popover} from 'react-bootstrap';

const Member = (props) => {


    const [member, setMember] = useState({});
    const [memberships, setMemberships] = useState([]);
    const [membershipsTotal, setMembershipsTotal] = useState(0);
    const [pageErrors, setPageErrors] = useState({});
    const {memberId} = useParams();
    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const {state} = useLocation();



    useEffect(() => {
        MemberService.getMemberById(memberId).then(res => {
            setMember(res);
        }).catch(err => {
            if (err.status in [400, 404]) {
                navigate('/bad-request', {state: {code: err.status, message: err.message}})
            } else {
                // todo: create a page for "oops other" error..
                navigate('/bad-request', {state: {code: err.status, message: err.message}})
            }
            console.error(err);
        })
        return () => {
            setMember({})
        }
    }, [memberId])


    const handleMemberDelete = () => {
        // todo: change to use api_v2
        AxiosInstance.delete('/api/v1/member/' + memberId)
            .then(res => navigate(`/organizations/${member.organizationNumber}`))
            .catch(err => {

                setPageErrors({...pageErrors, removeMember: err.response.data})

                setTimeout(() => {
                    delete pageErrors.removeMember;
                    setPageErrors({...pageErrors});
                }, 5000)

            });
    }


    const renderPhones = (phones) => {
        if (!phones || phones.length === 0) {
            return null;
        }

        return phones.map((p, idx) => <PhoneView key={idx} phoneNumber={p.phoneNbr}/>);
    }


    return (
        <Container>

            {/* todo: fix breadcrumbs */}
            <Breadcrumb className="my-2">
                <Breadcrumb.Item href="/organizations">Organizations</Breadcrumb.Item>
                <Breadcrumb.Item
                    href={`/organizations/${member.organizationNumber}`}>{member.organizationName}</Breadcrumb.Item>
                {
                    state !== null && state.breadcrumb != null ?
                        state.breadcrumb.map((bc, idx) => (
                            <Breadcrumb.Item href={bc.link}>{bc.name}</Breadcrumb.Item>
                        )) : null
                }
                <Breadcrumb.Item active>{member.lastNm}{` `}{member.firstNm}</Breadcrumb.Item>
            </Breadcrumb>


            <Row className="mt-3">
                <Col>
                    <strong>{`${member.lastNm} ${member.firstNm}`}</strong>
                    <Button variant="link"
                            onClick={() => navigate(`/member/edit/${member._id}`)}
                            className={"p-0 pb-2 mt-0 "}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"text-secondary align-text-top"} height="24"
                             width="24" fill="currentColor" viewBox="0 0 48 48">
                            <path
                                d="M9 47.4q-1.2 0-2.1-.9-.9-.9-.9-2.1v-30q0-1.2.9-2.1.9-.9 2.1-.9h20.25l-3 3H9v30h30V27l3-3v20.4q0 1.2-.9 2.1-.9.9-2.1.9Zm15-18Zm9.1-17.6 2.15 2.1L21 28.1v4.3h4.25l14.3-14.3 2.1 2.1L26.5 35.4H18v-8.5Zm8.55 8.4-8.55-8.4 5-5q.85-.85 2.125-.85t2.125.9l4.2 4.25q.85.9.85 2.125t-.9 2.075Z"/>
                        </svg>
                    </Button>
                </Col>
            </Row>

            {
                member && member.address &&
                <AddressView streetAddress={member.address.streetAddress}
                             cityNm={member.address.cityNm}
                             stateCd={member.address.stateCd}
                             postalCd={member.address.postalCd}
                />
            }


            {renderPhones(member.phones)}

            <div className="">
                <div className="fw-bolder pt-1">{member.emails && member.emails.length > 0 ? "email(s):" : null}</div>
                {
                    member.emails ?
                        member.emails.map((e, idx) => {
                            return <div><span>{e.email}</span><span
                                className="px-1 text-secondary">({e.email_type})</span></div>
                        }) : null
                }
            </div>

            <div className="small text-secondary">
                {
                    member.externalRef &&
                    <>
                        <span className="text-secondary-emphasis me-1">Ext Ref:</span>
                        <a target="_blank"
                           href={`https://funeral.myfundcenter.com/Customer/View.aspx?CustomerNumber=${member.externalRef}`}>
                            {member.externalRef}
                            <i className="bi bi-box-arrow-up-right ms-1"></i>
                        </a>
                    </>

                }
            </div>


            <Tabs
                defaultActiveKey={searchParams.get("activeTab") ? searchParams.get("activeTab") : "campaigns"}
                id="uncontrolled-tab-example"
                className="mb-3 mt-5"
                onSelect={(k) => navigate(`?activeTab=${k}`)}
            >
                <Tab eventKey="campaigns" title="Campaigns Membership">
                    <MemberCampaigns id={member._id} memberName={member.firstNm}/>
                </Tab>
                <Tab eventKey="donations" title="Donations">
                    <MemberDonationsView member_id={member._id}/>
                </Tab>
                <Tab eventKey="household" title="Household">
                    <MemberHousehold id={member ? member._id : null} organizationNumber={member.organizationNumber}/>
                </Tab>
                <Tab eventKey="linkedAccounts" title="Linked Accounts">
                    <MemberLinkedAccounts id={member ? member._id : null}
                                          organizationNumber={member.organizationNumber}/>
                </Tab>
                <Tab title={<span className="text-danger">Danger Zone</span>}
                     eventKey="danger-zone">
                    <Alert variant={"danger"} show={Object.keys(pageErrors).length > 0}>
                        {`${pageErrors.removeMember}`}
                    </Alert>
                    <div className="mt-3">
                        <div className="text-danger mb-3">
                            The following actions CANNOT be undone.
                        </div>
                        <div className="d-flex">
                            <div className="px-3">
                                <Button variant="danger"
                                        onClick={() => handleMemberDelete()}
                                >
                                    Delete Member
                                </Button>
                            </div>

                            <div>
                                This will permanently remove this member from the database
                            </div>
                        </div>

                    </div>
                </Tab>
            </Tabs>


        </Container>
    )

}

export default Member;