import OrganizationUpdate from "./OrganizationUpdate";
import {Fragment} from "react";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router";


const OrganizationCreate = (props) => {

    const navigate = useNavigate();

    return (
        <Container>
            <div className="h5 mt-3 mb-3">New Organization</div>
            <OrganizationUpdate  organization={{}} onUpdated={() => navigate(-1)} onCancel={() => navigate(-1)} />
        </Container>

    );


}


export default OrganizationCreate;