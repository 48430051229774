import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AxiosInstance from "../../services/AxiosInstance";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import API_V2 from "../../services/API_V2";


const MemberCampaigns = (props) => {


    const [memberCampaigns, setMemberCampaigns] = useState([]);
    const [availableCampaigns, setAvailableCampaigns] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        if (props.id != null) {
            AxiosInstance.get(`/api/v1/member/${props.id}/campaigns`).then((res => {
                setMemberCampaigns(res.data);
            }));
            // AxiosInstance.get(`/api/v1/member/${props.id}/campaigns/other`).then((res => {
            //     setAvailableCampaigns(res.data);
            // }));
            // get other available campaigns for the user
            API_V2.get(`/v2/members/${props.id}/campaigns/other`).then((res => {
                setAvailableCampaigns(res.data);
            }));

        }
        }, [props.id]
    );


    const handleJoin = (campaign_id) => {

        API_V2.get(`/v2/members/${props.id}/campaigns/${campaign_id}/join`).then((res => {

            AxiosInstance.get(`/api/v1/member/${props.id}/campaigns`).then((res => {
                setMemberCampaigns(res.data);
            }));
            API_V2.get(`/v2/members/${props.id}/campaigns/other`).then((res => {
                setAvailableCampaigns(res.data);
            }));

        }));


    }

    return (
        <div>
            <div className="h5 mt-3 ">{props.memberName}'s Campaigns</div>
            {memberCampaigns && memberCampaigns.length == 0?
                <div className="text-primary"><i className="bi bi-info-circle-fill" />{` `}No Campaign Memberships Found</div>:
                null}
            {memberCampaigns ? memberCampaigns.map((campaign,idx) => (
                <Row key={idx}>
                    <Col md={3}>
                        <Link to={`/member/${props.id}/campaign/${campaign.campaign_membership_id}`}>{campaign.campaignName}</Link>
                        <div className="small text-secondary">
                            {campaign.campaign_membership_id}
                        </div>
                    </Col>
                    <Col>

                    </Col>
                </Row>
            )) : null
            }

            <div className="h5 mt-3 text-secondary">Other Campaigns</div>
            {availableCampaigns ? availableCampaigns.map((campaign,idx) => (
                <Row key={idx}>
                    <Col md={3}>
                        {campaign.campaignName}
                    </Col>
                    <Col>
                        <Button size="sm"
                                onClick={() => handleJoin(campaign._id)}
                                style={{paddingTop:0,paddingBottom:0, marginLeft:10}}
                                variant="outline-primary"
                        >Join</Button>
                    </Col>
                </Row>
            )) : null
            }

        </div>

    );
}

export default MemberCampaigns;