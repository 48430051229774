import React from "react";


interface DonationAmountViewProps {
    amount: number;
    processingFee?: number;
    description?: string;
}

const DonationAmountView = ({amount, processingFee, description}: DonationAmountViewProps) => {


    return (

        <div className="text-center">
            <div className=""
                 style={{fontSize: "24px", marginBottom: '0px'}}
            >
                Donation Amount
            </div>
            <div className="m-0"
                 style={{
                     fontSize: "52px",
                     fontWeight: "bold",
                     lineHeight: 1,
                     color: "#0a1a86"
                 }}>

                {processingFee ? `$${(amount + processingFee).toFixed(2)}` : `$${amount.toFixed(2)}`}

            </div>
            {(processingFee && processingFee > 0) ?
                <div className="small text-secondary lh-sm">
                    {`Includes $${processingFee.toFixed(2)} processing fee`}
                </div> : null
            }
            <div>
                {description &&
                    <div className="lh-sm">for <span className="h5">{description}</span></div>
                }
            </div>
        </div>

    );

}


export default DonationAmountView;