import axios from 'axios'
import AppConfig from "./AppConfig";


const instance = axios.create({
    baseURL: AppConfig().api_v2.baseURL,
    timeout: 60000,
    // Access-Control-Allow-Origin
    headers: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')}
});

// API_V2.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

instance.interceptors.response.use((req) => {
    return req;
}, (err) => {


    if (err.response !== undefined && err.response.status === 401) { // redirect to login page if unauthorized
        window.location.replace("/sign-in")
    }

    return Promise.reject(err);
})

export default instance;