import {Organization} from "../../interfaces";
import AddressView from "../address/AddressView";
import PhoneView from "../PhoneView";
import React from "react";


interface OrganizationContactDetailsProps {
    organization: Organization;
}

const OrganizationContactDetails = ({organization}: OrganizationContactDetailsProps) => {

    return (
        <>
            <div className="">
                {organization.organizationName && <i className="bi bi-buildings h5 me-1"></i>}
                <span className="h5">{organization && organization.organizationName}</span>
            </div>
            <div className="">
                {organization.address &&
                    <AddressView streetAddress={organization.address.streetAddress}
                                 cityNm={organization.address.cityNm}
                                 stateCd={organization.address.stateCd}
                                 postalCd={organization.address.postalCd}/>
                }

                {organization.phone && <PhoneView phone={organization.phone}/>}
            </div>
        </>
    );
}

export default OrganizationContactDetails;