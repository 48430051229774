import {useEffect, useState} from "react";
import CampaignMembershipService from "../../services/CampaignMembershipService";
import {formatDate} from "../../utils/Utils";
import {useAuth} from "../../hooks/useAuth";


const CampaignMembershipView = ({c_membership_id}) => {

    const {accessTokenData} = useAuth()
    const [members, setMembers] = useState([]);
    const [campaignMembership, setCampaignMembership] = useState(null);


    useEffect(() => {

        CampaignMembershipService.getCampaignMembership(c_membership_id).then((res) => {
            setMembers(res['members'].sort(customSort));
            setCampaignMembership(res);

        })


    }, [c_membership_id]);


    const customSort = (a, b) => {
        // Priority for primary member
        if (a.isPrimary && !b.isPrimary) return -1;
        if (!a.isPrimary && b.isPrimary) return 1;

        // Check for null relationships and handle accordingly
        const aRelationship = a.relationship ? a.relationship.toLowerCase() : '';
        const bRelationship = b.relationship ? b.relationship.toLowerCase() : '';

        // Priority for spouse relationship
        if (aRelationship === 'spouse' && bRelationship !== 'spouse') return -1;
        if (aRelationship !== 'spouse' && bRelationship === 'spouse') return 1;

        // Sort by age in descending order
        return b.age - a.age;
    }


    const renderRow = (member) => {
        return (
            <div key={member.member_id} className="row">
                <div className="col-1 m-0 p-0">
                    <i className="bi bi-person-circle fs-3 text-secondary mt-2 d-flex justify-content-center"></i>
                </div>
                <div className="col px-1 me-1 small pt-1">
                    <div className="row"> {/* second column first row */}
                        <div className="col text-primary-emphasis">
                            {member.isPrimary && <i className="bi me-1 bi-star-fill text-danger-emphasis small"></i>}
                            {`${member.firstNm}${member.lastNm && ', ' + member.lastNm}`}
                            {member.member_id === accessTokenData['mid'] &&
                                <span className="small ms-1 text-secondary">(you)</span>}

                        </div>
                        <div className="col-3 px-0 text-primary-emphasis d-flex justify-content-end">
                            {`$` + member.price.toFixed(2)}
                        </div>
                    </div>
                    {member.relationship &&
                        <div className="row small">  {/* second column second row */}
                            <div className="col text-secondary">
                            <span className="text-secondary-emphasis">
                            {`Relationship: `}
                            </span>
                                <span>{member.relationship}</span>
                            </div>
                        </div>
                    }

                    <div className="row small">  {/* second column third row */}
                        <div className="col text-secondary">
                            <span className="text-secondary-emphasis">
                            {`Birth Date: `}
                            </span>
                            {
                                new Date(member.birthDt).toLocaleDateString("en-US", {
                                    month: "short",
                                    day: "2-digit",
                                    year: "numeric"
                                })
                            }

                        </div>
                    </div>

                </div>
            </div>

        )

    }


    return (
        <>
            <div className="row fw-bolder">Members</div>
            {members.map((m) => renderRow(m))}
            <div className="row">
                <div className="col mx-0 px-0">
                    {
                        campaignMembership &&
                        <div className="d-flex justify-content-end small">
                            <span className="text-secondary-emphasis mx-2">Total Price</span>
                            <span className="fw-bolder">{` $` + campaignMembership.totalPrice.toFixed(2)}</span>
                        </div>
                    }
                </div>
            </div>
        </>

    )
}


export default CampaignMembershipView;