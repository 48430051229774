import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import AxiosInstance from "../../services/AxiosInstance";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import { useContext } from 'react';
import API_V2 from "../../services/API_V2";


import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionButton} from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import CampaignMembershipView from "../campaignMembership/CampaignMembershipView";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useAuth} from "../../hooks/useAuth";
import IdentityService from "../../services/IdentityService";


const MyCampaigns = (props) => {

    const auth = useAuth()
    const [memberCampaigns, setMemberCampaigns] = useState([]);
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [showSubscription, setShowSubscription] = useState(false);
    const [showOnlyActive, setShowOnlyActive] = useState(true);
    const navigate = useNavigate();
    const [canViewDonations, setCanViewDonations] = useState(false);


    useEffect(() => {

        if (props.member_id !== "undefined") {
            API_V2.get(`/v2/members/${props.member_id}/campaigns?active=${showOnlyActive}`).then(res => {
                setMemberCampaigns(res.data);
            });

            IdentityService.checkPermission(auth.accessTokenData["oid"], "organization.donations.get").then(res => {
                setCanViewDonations(res);
            })

        }

    }, [props.member_id, showOnlyActive]);


    const handleDonate = (campaign_id, campaignName, membership_id) => {
        navigate(`/member/${props.member_id}/membership/${membership_id}/donate`, {
            state: {
                member_id: props.member_id,
                payee_member_id: props.payee_member_id,
                campaign_id: campaign_id,
                membership_id: membership_id,
                campaignName: campaignName
            }
        });
    }


    function CustomToggleAccordion({children, eventKey, callback}) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey)
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <Link
                onClick={decoratedOnClick}
             to="#">
                {children}{isCurrentEventKey ?
                <i className="bi bi-caret-up-fill mx-1"></i> :
                <i className="bi bi-caret-down-fill mx-1"></i>}
            </Link>
        );
    }

    return (
        <Card md={5}>
            <Card.Header style={{backgroundColor: "#073e7e", color: "white"}}>Fundraisers</Card.Header>
            <Card.Body className="pb-0">
                {memberCampaigns.length > 0 ?
                    memberCampaigns.map((c, idx) => (
                        <Fragment key={idx}>
                            <Card.Title>
                                <i className="bi bi-play-fill" style={{color: "#073e7e"}}></i>

                                {
                                    canViewDonations === true ?
                                        <span>
                                            <Link to={`/campaigns/${c.campaignId}/runs/67156618b5c822ef04a3198c`}>{c.campaignName}</Link>
                                        </span> :
                                        <span>{c.campaignName}</span>

                                }


                                {c.currentCampaignRunsBalance > 0 ? null
                                    : <div className="d-inline float-end fw-bold" style={{color: "#ab0b0b", fontSize: "18px"}}>
                                        <i className="bi bi-check2-circle"/>
                                        <span className="fst-italic small ms-1">PAID</span>
                                    </div>
                                }

                            </Card.Title>
                            <Card.Body className="mt-0 pt-0">
                                <div>
                                    <div className="d-inline col-2">{c.shortDescription}</div>
                                </div>
                                <div className="small mt-1">


                                    <Accordion defaultActiveKey="0">
                                        <i className="bi bi-check-circle-fill text-success"></i>
                                        <span className="mx-1">You are a member,{` `}<CustomToggleAccordion
                                            eventKey="1">see details</CustomToggleAccordion></span>
                                        <Accordion.Collapse eventKey="1">
                                            <div className="ms-3 mt-2">
                                                <CampaignMembershipView c_membership_id={c.campaignMembershipId} />
                                            </div>


                                        </Accordion.Collapse>
                                    </Accordion>


                                    <div className="justify-content-center d-flex m-auto pt-3">
                                        {c.currentCampaignRunsBalance > 0 ?
                                            <Button
                                                className="btn-yellow px-4"
                                                disabled={c.currentCampaignRunsBalance > 0 ? false : true}
                                                size=""
                                                onClick={() => handleDonate(c.campaignId, c.campaignName, c.campaignMembershipId)}>
                                                Donate Now
                                            </Button> : null}
                                    </div>

                                </div>
                                {/*<hr/>*/}
                            </Card.Body>
                        </Fragment>
                    ))
                    :
                    <div className="text-primary mb-2">
                        <i className="bi bi-info-circle-fill me-1 text-primary"/>
                        <span>No active fundraisers found</span>
                        {
                            // todo: remove hardcoding, replace with scopes once implemented
                            (auth.isLoggedIn && auth.accessTokenData.email.toLowerCase() === "vitaliy@gogolevs.com") &&
                            <div className="d-flex justify-content-center my-1">
                                <Button className="bnt btn-sm btn-link dark-blue-link-button"
                                        onClick={() => setShowOnlyActive(!showOnlyActive)}>
                                    show inactive
                                </Button>
                            </div>
                        }

                    </div>
                }
            </Card.Body>
        </Card>
    );
}

export default MyCampaigns;