import {AuthContext} from '../hooks/useAuth'
import {useEffect, useState} from "react";
import {getTokenData} from "../utils/Utils";
import API_V2 from "../services/API_V2";


const AuthProvider = ({children}) => {


    const [error, setError] = useState(null);

    // try to load context values from storage
    const [accessToken, setAccessToken] = useState(() => localStorage.getItem("accessToken"));
    const [accessTokenData, setAccessTokenData] = useState(() => getTokenData(localStorage.getItem("accessToken")));
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("accessToken"));


    useEffect(() => {
        // try to load token from local storage if not set
        if (accessToken === null) {
            setAccessToken(localStorage.getItem("accessToken"));
            setAccessTokenData(getTokenData(localStorage.getItem("accessToken")))
        }
    }, [])


    const loginWithUsernamePassword = async (username, password) => {

        const cred_params = new URLSearchParams();
        cred_params.append('username', username);
        cred_params.append('password', password);

        try {
            // authenticate w/username:password to get access token
            const res = await API_V2.post('/v2/token', cred_params);

            addAccessToken(res.data.access_token)

            return getTokenData(res.data.access_token);

        } catch (e) {
            return Promise.reject(e);
        }

    }

    const addAccessToken = (token) => {

        const access_token_decoded = getTokenData(token);
        setAccessToken(token);
        setAccessTokenData(access_token_decoded);
        setIsLoggedIn(true);

        localStorage.setItem("accessToken", token);
        localStorage.setItem("accessTokenData", JSON.stringify(access_token_decoded)); // TODO: remove

        // TODO: experiment to clean

    }


    const value = {
        accessToken,
        accessTokenData,
        isLoggedIn,
        error,
        setError,
        addAccessToken,
        loginWithUsernamePassword,

    };


    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )


}

export default AuthProvider;


