import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {json, useLocation, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import CreditCard from "../components/Payment/CreditCard";
import Button from "react-bootstrap/Button";
import AxiosInstance from "../services/AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import CardsOnFile from "../components/Payment/CardsOnFile";
import {useCloverSDK} from "../hooks/useCloverSDK";
import CloverCreditCard from "../components/Payment/CloverCreditCard";
import DonationAmountView from "../components/Donation/DonationAmountView";
import DonationLinkedAccountsToInclude from "../components/Donation/DonationLinkedAccountsToInclude";
import CampaignMembershipService from "../services/CampaignMembershipService";
import DonationLinkedMembers from "../components/Donation/DonationLinkedMembers";
import MemberService from "../services/MemberService";


const Donate = (props) => {


    // ==== original configs =======================================
    const CHARGE_PROCESSING_FEE = true
    const CARD_PERCENT_FEE = 0.0125
    const PROCESSING_FEE = 10
    const BANK_FEE = 38

    const [formData, setFormData] = useState({totalDonation: 0, memberships: []});
    const [useCardOnFile, setUseCardOnFile] = useState(true);
    const [hasCardsOnFile, setHasCardsOnFile] = useState(false);
    const [saveCard, setSaveCard] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const {state} = useLocation();
    const cloverRef = useRef();
    // ==== end original configs ====================================


    const {membershipId, member_id} = useParams()
    const [campaignName, setCampaignName] = useState("");
    const [donationTotalAmount, setDonationTotalAmount] = useState(0);
    const [totalProcessingFeeAmount, setTotalProcessingFeeAmount] = useState(0);
    const [donations, setDonations] = useState([]);
    const [linkedDonations, setLinkedDonations] = useState([]);


    useEffect(() => {

        getMyDonations()

        MemberService.getCampaigns(member_id).then((campaigns) => {
            if (campaigns.length > 0) {
                setCampaignName(campaigns[0].name);
            }
        })

    }, [membershipId])


    useEffect(() => {

        calculateTotal()

    }, [donations, linkedDonations])


    const calculateProcessingFee = (amount) => {
        if (CHARGE_PROCESSING_FEE) {
            const fee = Number(Math.round(((amount) * CARD_PERCENT_FEE + ((PROCESSING_FEE + BANK_FEE) / 100)) + 'e2') + 'e-2')
            return fee;
        } else {
            return 0;
        }
    }


    const calculateTotal = () => {

        let total = 0
        donations.forEach((d) => {
            total += (d.minAmountDue - d.paymentsTotal)
        })

        linkedDonations.forEach((d) => {
            total += d.amount
        })

        setDonationTotalAmount(total)
        const processingFee = calculateProcessingFee(total)
        setTotalProcessingFeeAmount(processingFee)

    }


    const getMyDonations = () => {

        MemberService.getCampaigns(member_id).then((res) => {

            res.forEach((c) => {
                console.log(`res.donations: ${JSON.stringify(c.donations)}`)
                setDonations(c.donations);
            })
        })
    }


    const prepDonationsForPayment = (cardDate) => {

        let donationsPayload = []

        donations.forEach((d) => {
            donationsPayload.push(
                {
                    donation_id: d._id,
                    amount: (d.minAmountDue - d.paymentsTotal),
                    extRef: d.externalRef,
                    isPrimary: true
                }
            )
        })

        donationsPayload = [...donationsPayload, ...linkedDonations]

        const paymentPayload = {
            campaign_id: state.campaign_id,
            membershipId: membershipId,
            campaignName: campaignName,
            totalAmount: donationTotalAmount,
            processingFee: totalProcessingFeeAmount,
            cardData: cardDate,
            donation_id: -1,  // not sure if this is needed
            donations: donationsPayload
        }

        localStorage.setItem("paymentPayload", JSON.stringify(paymentPayload));

    }

    const handleContinueNew = () => {
        // I need to write this to local storage temporarly for review

        if (useCardOnFile) {

            prepDonationsForPayment(formData.validatedCard)

            navigate("/donate/review");

        } else {
            cloverRef.current.getToken();
        }



    }


    const handleCardValidatedNew = (cardData) => {

        // augment card data
        cardData = {...cardData, saveCard: saveCard, existingCard: useCardOnFile}
        console.log("handleCardValidated cardData: " + JSON.stringify(cardData));

        setFormData({...formData, validatedCard: cardData});

        prepDonationsForPayment(cardData)

        navigate("/donate/review");

    }


    useEffect(() => {

        // Promise.all([
        //     getAmountDue(state.member_id, state.campaign_id),
        //     getLinkedAccounts()
        // ]).then(result => {
        //
        //     setLinkedAccounts(result[1]);
        //
        //     setFormData({
        //         ...formData,
        //         memberships: [result[0]],
        //         totalDonation:[result[0]].balance
        //     });
        //
        // })

        // const elements = clover.elements();

        // console.log("clover elements: " + JSON.stringify(elements));


        getAmountDue(state.member_id, state.campaign_id).then(balanceRes => {

            setFormData({
                ...formData,
                donation_id: balanceRes.donation_id,
                memberships: [{...balanceRes, isPrimary: true}],
                totalDonation: balanceRes.balance,
                processingFee: calculateProcessingFee(balanceRes.balance)
            });

        })

    }, [state]);

    /* TODO:
        * [x] get balance for the props member_id
        * check if props.member has linked accounts
        * if yes list linked accounts that also members of the same campaign
        * promt if payee wants also pay for the selected linked accounts
        * Lear How to perform multiple requests concurrently
        Promise.all([getUserAccount(), getUserPermissions()])
          .then(function (results) {
            const acct = results[0];
            const perm = results[1];
          });

     */


    const getAmountDue = async (member_id, campaign_id) => {

        console.log(`getAmountDue: member_id: ${member_id} campaign_id: ${campaign_id}`)
        const balanceRes = await AxiosInstance.get(`/api/v1/member/${member_id}/${campaign_id}/balance`);
        console.log(`getAmountDue: member_id:${member_id} campaign_id:${campaign_id}`)
        console.log(`balance res: ${JSON.stringify(balanceRes.data)}`)
        return balanceRes.data;

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(`name: ${name} | value: ${value} `)

        let newFormData = {
            ...formData,
            [name]: value
        }

        delete formErrors[name];
        setFormErrors({...formErrors});

        setFormData(newFormData);

    }

    const handleSelectedExistingCard = (cardData) => {
        // augment card data
        cardData = {...cardData, saveCard: saveCard, existingCard: useCardOnFile}
        setFormData({...formData, validatedCard: cardData});
    }

    const handleHasCardsOnFile = (e) => {
        setUseCardOnFile(e);
        setHasCardsOnFile(e);
    }


    return (
        <Container>

            <div className="m-auto col-sm-10 col-md-8 col-lg-6 col-xl-5 mt-3 mb-5">

                <div className="mt-3 mb-4">
                    <DonationAmountView
                        amount={donationTotalAmount}
                        processingFee={totalProcessingFeeAmount}
                        description={campaignName}
                    />
                </div>


                <div>
                    <DonationLinkedMembers member_id={member_id} onSelected={setLinkedDonations}/>
                </div>


                <div className="mt-3 mb-5">
                    <div className="">
                        <div className="grid-header px-2">
                            Payment Method
                        </div>

                        <Form>
                            <Form.Check
                                label="Use New Credit Card"
                                checked={!useCardOnFile}
                                onChange={handleInputChange}
                                onClick={() => setUseCardOnFile(false)}
                                name="payment-method"
                                type="radio"
                                id="one-time-payment"
                            />
                            <Form.Check
                                label="Existing Credit Card"
                                checked={useCardOnFile}
                                onChange={handleInputChange}
                                disabled={hasCardsOnFile ? false : true}
                                onClick={() => setUseCardOnFile(true)}
                                name="payment-method"
                                type="radio"
                                id="existing-payment"
                            />
                        </Form>
                    </div>

                    {!useCardOnFile ?
                        <div className="m-auto mt-2">
                            <CloverCreditCard width="100%" ref={cloverRef} onCardValidated={handleCardValidatedNew}/>

                            <div className="mt-2">
                                <Form.Check
                                    label="Save Card for Future Payments"
                                    disabled={true}
                                    onClick={() => setSaveCard(!saveCard)}
                                    name="save-card"
                                    type="checkbox"
                                    id="save-card"
                                />
                            </div>

                        </div>


                        : <div className="col-md-6 mt-3">
                            <CardsOnFile member_id={state.payee_member_id}
                                         hasCards={handleHasCardsOnFile}
                                         onSelected={handleSelectedExistingCard}/>
                        </div>

                    }


                    <div className="mt-5 text-center mb-5">
                        <Button size="lg"
                                onClick={handleContinueNew}
                                disabled={(useCardOnFile && hasCardsOnFile && formData.validatedCard && formData.validatedCard.token) ||
                                (!useCardOnFile) ? false : true}
                                variant="default"
                                className="px-5">
                            Continue
                        </Button>

                    </div>
                </div>
            </div>

            {/*<div>*/}
            {/*    <div>State:</div>*/}
            {/*    <div>{JSON.stringify(formData)}</div>*/}
            {/*</div>
*/}

        </Container>
    );
}

export default Donate;