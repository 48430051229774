import {Fragment} from "react";
import Container from "react-bootstrap/Container";


const PrivacyNotice = () => {


    return (
        <Container>

            <div className="col-12 col-md-8 col-lg-7 m-auto my-5 text-secondary">
                <div className="h3">Terms and Conditions of Use</div>

                <div className="pb-5">
                    <div className="h5">Last Updated: April 1, 2023</div>


                    <div className="pt-5">
                        <div className="h5">1. Introduction</div>
                        Welcome to MyFundCenter.com. This website provides a platform for churches, small organizations,
                        and communities to manage private fundraising initiatives. By accessing or using
                        MyFundCenter.com, you agree to be bound by these Terms of Use (the "Terms"). If you disagree
                        with any part of the terms, you do not have permission to access the website.
                    </div>

                    <div className="pt-5">
                        <div className="h5">2. Use of the Site</div>
                        MyFundCenter.com is intended for users who are at least 18 years old. Persons under the age of
                        18 are not permitted to use or register for the site.
                    </div>

                    <div className="pt-5">
                        <div className="h5">3. User Accounts</div>
                        When you create an account with us, you must provide information that is accurate, complete, and
                        current at all times. Failure to do so constitutes a breach of the Terms, which may result in
                        immediate termination of your account on our service.
                    </div>

                    <div className="pt-5">
                        <div className="h5">4. Privacy</div>
                        Your use of MyFundCenter.com is subject to our Privacy Policy. Please review our Privacy Policy,
                        which also governs the site and informs users of our data collection practices.
                    </div>

                    <div className="pt-5">
                        <div className="h5">5. Prohibited Activities</div>
                        You may not access or use the site for any purpose other than that for which we make the site
                        available. The site may not be used in connection with any commercial endeavors except those
                        that are specifically endorsed or approved by us.
                    </div>

                    <div className="pt-5">
                        <div className="h5">6. Intellectual Property Rights</div>
                        The content on MyFundCenter.com, including text, graphics, images, and logos, are owned by or
                        licensed to MyFundCenter.com and are protected by copyright and trademark laws. You agree not to
                        reproduce, distribute, modify, or create derivative works based on the content found on the site
                        without prior written consent from us.
                    </div>

                    <div className="pt-5">
                        <div className="h5">7. Termination</div>
                        We may terminate or suspend your account and bar access to the service immediately, without
                        prior notice or liability, for any reason whatsoever, including, without limitation, if you
                        breach the Terms.
                    </div>

                    <div className="pt-5">
                        <div className="h5">8. Limitation of Liability</div>
                        MyFundCenter.com shall not be liable for any indirect, incidental, special, consequential or
                        punitive damages, including without limitation, loss of profits, data, use, goodwill, or other
                        intangible losses, resulting from your access to or use of or inability to access or use the
                        service.
                    </div>

                    <div className="pt-5">
                        <div className="h5">9. Governing Law</div>
                        These Terms shall be governed and construed in accordance with the laws of the State of Oregon,
                        United States, without regard to its conflict of law provisions. Our failure to enforce any
                        right or provision of these Terms will not be considered a waiver of those rights. If any
                        provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                        provisions of these Terms will remain in effect.
                    </div>

                    <div className="pt-5">
                        <div className="h5">10. Changes to Terms</div>
                        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. What
                        constitutes a material change will be determined at our sole discretion. By continuing to access
                        or use our service after those revisions become effective, you agree to be bound by the revised
                        terms.
                    </div>

                    <div className="pt-5">
                        <div className="h5">11. Contact Us</div>
                        If you have any questions about these Terms, please contact us at support@myfundcenter.com

                        <p/>By using MyFundCenter.com, you acknowledge that you have read and understand these Terms of Use
                        and agree to be bound by them.
                    </div>


                </div>
            </div>

        </Container>
    )

}

export default PrivacyNotice;