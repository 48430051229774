import { Fragment } from "react";
import { XCircle } from 'react-bootstrap-icons';
import {useLocation} from "react-router-dom"; //Assuming you want a cross circle as your SVG icon

const HttpErrorPage = ({ errorCode, message }) => {

    const location = useLocation();

    return (
        <Fragment>
            <div className="pt-5 text-center align-content-center">

                <div className="align-bottom d-flex justify-content-center">
                    <div className=""><XCircle size={20} className="text-danger"/></div>
                    <div className="mx-2 h5 mt-1">Error {location.state.code}</div>
                </div>
                <div>{location.state.message}</div>

            </div>
        </Fragment>
    )

};

export default HttpErrorPage;