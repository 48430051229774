import React from 'react'
import AppConfig from "../../services/AppConfig";
import {Toast, ToastContainer} from "react-bootstrap";
import {useAuth} from "../../hooks/useAuth";

const Footer = () => {


    const auth = useAuth();

    const errorMessageToast =
        <ToastContainer className="bottom-100 mb-3" position="bottom-center">
            <Toast onClose={() => auth.setError(false)}
                   show={auth.error && true}
                   delay={5000}
                   autohide
            >
                <Toast.Header className="">
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto text-danger">Error</strong>
                    {/*<small>11 mins ago</small>*/}
                </Toast.Header>
                <Toast.Body>{auth.error}</Toast.Body>
            </Toast>
        </ToastContainer>


    return (

        <>

            <footer className="font-small bg-dark-blue fixed-bottom">
                {errorMessageToast}
                <div className="mx-lg-5 mx-md-5 mx-2">
                    <div className="mt-2">
                        <a href="/terms"><span className="light-link">Terms</span></a>
                        <span className="mx-2 light-link">|</span>
                        <a href="/privacy-notice"><span className="light-link">Privacy Notice</span></a>
                    </div>
                </div>
                <div className="footer-copyright text-secondary text-center py-1">© 2022 Copyright:&nbsp;
                    <a className="text-secondary mr-2" href="/public">MFC</a>{` `}v{AppConfig().global.version}
                </div>
            </footer>
        </>

    );

};

export default Footer