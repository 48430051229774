import AxiosInstance from './AxiosInstance.js'
import {Modal} from "react-bootstrap";
import {Component, React} from "react";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";


class ActivityWatcher extends Component {

    constructor(props) {
        super(props);

        this.handleActivity = this.handleActivity.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

        this.state = {

            // graceTimerSecondsDefault: 10, // for testing
            // maxInactivityTimerSecondsDefault: 15, // for testing
            maxInactivityTimerSecondsDefault: 60*15,
            graceTimerSecondsDefault: 60,
            refreshTokenAfterSeconds: (60*10),
            secondSinceRefreshTokenCounter: 0,
            secondsSinceLastActivityCounter: 0,
            graceSecondsCounter: 0,
            inactivityThresholdReached: false,
            activityEvents: ['mousedown', 'mousemove', 'keydown','scroll', 'touchstart']
        };
    };

    componentDidMount() {
        // register client event listeners to reset inactivity counter
        this.state.activityEvents.forEach( (e) => {
            document.addEventListener(e, this.handleActivity);
        });

        this.setState({graceSecondsCounter: this.state.graceTimerSecondsDefault})

        // start inactivity counter
        this.inactivityTimerId = setInterval(
            () => this.inactivityTicks(),
            1000
        );
    }

    componentWillUnmount() {
        // clean up event listeners
        this.state.activityEvents.forEach( (e) => {
            document.removeEventListener(e, this.handleActivity);
        });

        clearInterval(this.inactivityTimerId);
        clearInterval(this.timerID);
    }

    handleLogout = () => {
        localStorage.clear();
        this.setState({inactivityThresholdReached: false});
        this.props.onLogout();

    };

    handleContinue () {
        clearInterval(this.graceTimerId); // stop grace timer
        this.setState({inactivityThresholdReached: false}) // reset inactivity indicator
        this.setState({graceSecondsCounter: this.state.graceTimerSecondsDefault}); // reset grace period
        this.timerID = setInterval(
            () => this.inactivityTicks(),
            1000
        );
    }

    handleActivity () {
        if (!this.state.inactivityThresholdReached) {
            this.setState({secondsSinceLastActivityCounter: 0}); // reset inactivity counter
        }
    }

    inactivityTicks() {
        this.setState({
            secondsSinceLastActivityCounter: this.state.secondsSinceLastActivityCounter + 1,
            secondSinceRefreshTokenCounter: this.state.secondSinceRefreshTokenCounter + 1
        });

        // refresh token
        if (this.state.secondSinceRefreshTokenCounter > this.state.refreshTokenAfterSeconds) {
            let token = localStorage.getItem('userToken')

            AxiosInstance.post('/token/refresh', {userToken: token}).then(newToken => {
                let {data} = newToken;
                if (data !=null) {
                    localStorage.setItem("userToken", data.token);
                    AxiosInstance.defaults.headers['Authorization-Token'] = data.token
                    console.log(`refreshed user token, new token: ${data.token}`)
                }
            });

            this.setState({secondSinceRefreshTokenCounter: 0});
        }

        // start grace count down when inactivity reaches threshold
        if(this.state.secondsSinceLastActivityCounter > this.state.maxInactivityTimerSecondsDefault){
            clearInterval(this.inactivityTimerId); // stop timer

            this.setState({inactivityThresholdReached: true});
            this.setState({secondsSinceLastActivityCounter: 0});  //reset seconds since last activity

            // set and start grace timer
            this.setState({graceSecondsCounter: this.state.graceTimerSecondsDefault})
            this.graceTimerId = setInterval(() => this.graceTicks(), 1000);
        }
    }

    graceTicks() {
        this.setState({
            graceSecondsCounter: this.state.graceSecondsCounter - 1
        });

        if(this.state.graceSecondsCounter <= 0 || !this.state.inactivityThresholdReached){
            clearInterval(this.graceTimerId);
            this.handleLogout();
        }
    }

    render () {
        return (
            <Modal
                show={this.state.inactivityThresholdReached}
                onHide={this.handleContinue}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you still here?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You will be logged out in {this.state.graceSecondsCounter} seconds due to inactivity
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleContinue} >Keep me Signed In</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ActivityWatcher;