import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useEffect, useState} from "react";
import {FormControl, FormLabel} from "react-bootstrap";


const CreditCardView = (props) => {

    const [card, setCard] = useState(props.card);


    useEffect( () => {

        if (props.mask) {

            setCard(maskCard());

        } else {
            setCard(props.card);
        }

        }, [props.mask]
    )

    const maskCard = () => {

        let maskedCard = {...card};
        maskedCard.name = card.name;
        maskedCard.number = "XXXXXXXXXXXX" + card.number.substring(12,16);
        maskedCard.CVV = "XXX";

        return maskedCard;

    }

    const formatCard = (cardNumber) => {

        let formatedCard = "";

        formatedCard = cardNumber.substring(0,4);
        formatedCard += ' ' + cardNumber.substring(4,8);
        formatedCard += ' ' + cardNumber.substring(8,12);
        formatedCard += ' ' + cardNumber.substring(12,16);

        return formatedCard;

    }

    return (
        <Container>
            <Row>
                <Col>
                    {formatCard(card.number)}
                </Col>
            </Row>
            <Row>
                <Col md={2} xs={5} className="pr-0 text-secondary">
                    Exparation
                </Col>
                <Col className="text-secondary" style={{'--bs-gutter-x': '0.1rem'}}>
                    CVV
                </Col>
            </Row>
            <Row >
                <Col md={2} xs={5} className="pr-0 mr-0">
                    {`${card.expMonth} / ${card.expYear}`}
                </Col>
                <Col style={{'--bs-gutter-x': '0.1rem'}}>
                    {card.CVV}
                </Col>
            </Row>
            <Row>
                <Col className="fw-bold">
                    {card.name}
                </Col>
            </Row>

        </Container>
    )

}

CreditCardView.defaultProps = {
    card: {
        name: "John Smith",
        number: "1234234534564567",
        expYear: "2027",
        expMonth: "05",
        CVV: "123"
    },
    mask: true
}



export default CreditCardView;