import React, {useEffect, useState} from "react";
import MyService from "../../services/MyService";
import Container from "react-bootstrap/Container";
import ComingSoon from "../../components/ComingSoon";
import AddressView from "../../components/address/AddressView";
import PhoneView from "../../components/PhoneView";
import Avatar from "react-avatar";
import {Link} from "react-router-dom";
import ContactCard from "../../components/contact/ContactCard";
import ContactCards from "../../components/contact/ContactCards";
import Button from "react-bootstrap/Button";
import ContactAdd from "../../components/contact/ContactAdd";
import {Organization} from "../../interfaces";
import {useAuth} from "../../hooks/useAuth";
import {Auth} from "../../interfaces"
import myService from "../../services/MyService";
import OrganizationContactDetails from "../../components/organization/OrganizationContactDetails";


const MyOrganization = () => {


    const auth = useAuth()
    const [organization, setOrganization] = useState<Organization>({
        organizationName: '',
        organizationNumber: ''
    });
    const [contacts, setContacts] = useState([]);
    const [contactAddShow, setContactAddShow] = useState(false);
    const [canAddContact, setCanAddContact] = useState(false);

    useEffect(() => {

        handleRefresh();

    }, [])

    useEffect(() => {

        myService.checkPermission(organization.organizationNumber, "organization.contacts.create")
            .then(res => setCanAddContact(res))

    }, [organization]);

    const handleRefresh = () => {
        MyService.getMyOrganization().then(org => {
            setOrganization(org)
            setContacts(org.contacts)
        })

    }

    const handleContactOnDelete = (member_id: string) => {
        MyService.deleteContact(organization.organizationNumber, member_id).then(() => {
            handleRefresh()
        }).catch(err => {
            auth.setError && auth.setError(`${err.status} ${err.message}`);
        })
    }






    const renderContent = () => (
        <Container>
            <div className="mt-3">
                <OrganizationContactDetails organization={organization}/>
            </div>



            <div className="mt-4 d-flex justify-content-between" style={{maxWidth: '25rem'}}>
                <div className="fw-bolder mb-2 justify-content-end">Contacts</div>
                {canAddContact &&
                    <div className="d-flex justify-self-end">
                        <Button className="bnt btn-sm btn-link dark-blue-link-button"
                                onClick={() => setContactAddShow(true)}>
                            <i className="bi bi-plus-lg me-1 fw-bolder"></i>
                            <span>Add contact</span>
                        </Button>
                    </div>
                }

            </div>
            <ContactAdd organizationNumber={organization.organizationNumber}
                        show={contactAddShow}
                        onComplete={handleRefresh}
                        onHide={() => setContactAddShow(false)}/>

            <ContactCards contacts={contacts !== undefined ? contacts : []} onDelete={handleContactOnDelete}/>

        </Container>
    )

    return (
        renderContent()

    )

}

export default MyOrganization;

