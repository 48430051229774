import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import AxiosInstance from "../../services/AxiosInstance";
import API_V2 from "../../services/API_V2";
import {formatDate} from "../../utils/Utils";
import {useAuth} from "../../hooks/useAuth";


const CampaignAddRun = (props) => {


    const auth = useAuth();
    const [formData, setFormData] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const [valid, setValid] = useState(false);
    const [showDialog, setShowDialog] = useState(props.show);


    useEffect(() => {
        if (props.campaign_run_id !== null) {
            API_V2.get(`/v2/campaign-run/${props.campaign_run_id}`).then((res => {
                res.data.startDt = dateFormat(res.data.startDt)
                res.data.endDt = dateFormat(res.data.endDt)
                setFormData(res.data);
            }))
        } else {
            // reset forms data
            setFormData({name:"", description:""})
        }
        // reset errors
        setFormErrors({})
        setShowDialog(props.show);
    }, [props.show]);


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

    }

    const handleDateInputChange = (event) => {
        const target = event.target;
        const value = target.value.replace(/[^0-9]/g, '');
        const name = target.name;

        let temp_val = value;

        if (value.length > 4) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2, 2) + '/' +
                temp_val.substr(4)
        } else if (value.length > 2) {
            temp_val = temp_val.substr(0, 2) + '/' +
                temp_val.substr(2)
        }


        setFormData({
            ...formData,
            [name]: temp_val
        });
        target.value = temp_val;
    }

    const dateFormat = (my_date) => {
        let dateOut = my_date;
        return `${dateOut.substring(5, 7)}/${dateOut.substring(8, 10)}/${dateOut.substring(0, 4)}`
    }

    const validateRequiredField = (required_field) => {
        const regex = /^(?!\s*$).{3,}$/
        return regex.test(required_field);
    }

    const validateDate = (date_field) => {
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(19|20)\d\d$/
        return regex.test(date_field);
    }

    const handleRunAdd = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // clear errors
        setFormErrors({})

        // validate input
        let validationErrors = {};
        !validateRequiredField(formData.name) ? validationErrors.name = "The name is required" : delete validationErrors.name;
        !validateRequiredField(formData.description) ? validationErrors.description = "The description is required" : delete validationErrors.description;
        !validateDate(formData.startDt) ? validationErrors.startDt = "Incorrect date" : delete validationErrors.startDt;
        !validateDate(formData.endDt) ? validationErrors.endDt = "Incorrect date" : delete validationErrors.endDt;

        // if any errors, set validation messages and do not continue
        if (Object.keys(validationErrors).length > 0) {

            setFormErrors({...validationErrors})
            return;
        }

        (async () => {

            const payload = {
                campaignId: props.campaign_id,
                ...formData,
                startDt: new Date(formData.startDt),
                endDt: new Date(formData.endDt),
                modifiedBy: auth.accessTokenData.email,
                createdBy: auth.accessTokenData.email
            }

            if (props.campaign_run_id !== null) {
                payload._id = props.campaign_run_id
            }

            console.log(JSON.stringify(payload))

            // submit the run data to the API
            const res = await API_V2.post('/v2/campaign-run/', payload).catch(e =>
                console.log(`error ${JSON.stringify(e.response.data.detail)}`)
            );

            // show that input is valid then redirect
            setValid(true);
            setTimeout(() => {
                setShowDialog(false);
                props.onAdded();
            }, 500)

        })();

    }


    return (
        <Modal show={showDialog} onHide={() => setShowDialog(false)}>
            <Modal.Header closeButton>
                <Modal.Title>New Campaign Run</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form className="d-grid gap-2">
                    <Row>
                        <Col>
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="name" type="text"
                                          isInvalid={formErrors.name}
                                          isValid={valid}
                                          maxLength={100}
                                          value={formData.name}
                                          onChange={handleInputChange}
                                          placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Description</Form.Label>
                            <Form.Control name="description" type="text"
                                          isInvalid={formErrors.description}
                                          isValid={valid}
                                          maxLength={100}
                                          value={formData.description}
                                          onChange={handleInputChange}
                                          placeholder="Description"
                            />
                            <Form.Control.Feedback type="invalid">{formErrors.description}</Form.Control.Feedback>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Start</Form.Label>
                            <Form.Control name="startDt" required type="input"
                                          isInvalid={formErrors.startDt}
                                          isValid={valid}
                                          maxLength="10"
                                          value={formData.startDt}
                                          onChange={handleDateInputChange}
                                          placeholder="MM/DD/YYYY"
                                          autoComplete="disabled"/>
                        </Col>
                        <Col>
                            <Form.Label>End</Form.Label>
                            <Form.Control name="endDt" required type="input"
                                          isInvalid={formErrors.endDt}
                                          isValid={valid}
                                          maxLength="10"
                                          value={formData.endDt}
                                          onChange={handleDateInputChange}
                                          placeholder="MM/DD/YYYY"
                                          autoComplete="disabled"/>

                        </Col>
                        <Form.Control.Feedback type="invalid">{formErrors.startDt}</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{formErrors.endDt}</Form.Control.Feedback>
                    </Row>
                    <Row>

                    </Row>
                    {/*<Row>*/}
                    {/*    <Col md={4}></Col>*/}
                    {/*    <Col md={5} className="fw-bold">*/}
                    {/*        {formData.lastNm != null?*/}
                    {/*            <span>*/}
                    {/*                    <i className="bi bi-check text-success"></i>*/}
                    {/*                {formData.firstNm + ' ' + formData.lastNm}*/}
                    {/*                </span> : null*/}
                    {/*        }*/}

                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                    {/*    <Col md={4}>Organizations</Col>*/}
                    {/*    <Col md={7}>*/}
                    {/*        <Form.Select name="organization"*/}
                    {/*                     size="sm"*/}
                    {/*                     onChange={handleInputChange}*/}
                    {/*        >*/}
                    {/*            <option>---</option>*/}
                    {/*            {*/}
                    {/*                organizations.map((org,idx) => (*/}
                    {/*                    <option key={idx} value={org.organizationNumber}>{org.organizationName}</option>*/}
                    {/*                ))*/}
                    {/*            }*/}
                    {/*        </Form.Select>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Row className="w-100">
                    <Col className="center col-6">
                        <Button variant="primary"
                                onClick={handleRunAdd}
                                size="sm"
                                disabled={formData === {}}
                                className="d-block w-100">
                            Save
                        </Button>
                    </Col>
                </Row>

            </Modal.Footer>
        </Modal>
    );
}

export default CampaignAddRun;