import {Modal, ModalFooter} from "react-bootstrap";
import AddressEdit from "../address/AddressEdit";
import Button from "react-bootstrap/Button";
import {useEffect, useState} from "react";
import {lettersOnlyHandler, numericOnlyHandler} from "../../utils/inputValidators";
import FormProvider from "../../providers/FormProvider";
import MyService from "../../services/MyService";
import {useAuth} from "../../hooks/useAuth";




const ContactAdd = ({organizationNumber, show, onHide, onComplete}) => {

    const {setError} = useAuth()
    const [showModal, setShowModal] = useState(false);
    const [contact, setContact] = useState({});
    const [validateModal, setValidateModal] = useState(false);


    const formDefinition = {
        "fields": [
            {
                "name": "member_id",
                "type": "memberSelect",
                "props": {"organizationNumber": organizationNumber},
                "rowNumber": 1,
                "rowClass": "col-2",
                "colClass": "col-12",
                "controlClass": "text-small",
                "placeholder": "Name",
                "label": "Name",
                "maxLength": 100,
                "validators": [{
                    "name": "requiredField",
                    "minLength": 4,
                    "message": "Member Id is required"
                }]
            }, {
                "name": "title",
                "type": "select",
                "rowNumber": 2,
                "maxLength": 100,
                "colClass": "col-12 m-0",
                "controlClass": "m-0 pr-0",
                "placeholder": "Select Title",
                "label": "Title",
                "keypressHandler": lettersOnlyHandler,
                "options": [
                    {value: 'Church Pastor', label: 'Church Pastor'},
                    {value: 'Deacon', label: 'Deacon'},
                    {value: 'Funeral Fund Deacon', label: 'Funeral Fund Deacon'},
                    {value: 'Funeral Fund Lead', label: 'Funeral Fund Lead'},
                    {value: 'Funeral Fund Associate', label: 'Funeral Fund Associate'}
                ],
                "validators": [{
                    "name": "requiredField",
                    "minLength": 3,
                    "message": "Title is required"
                }]
            }
        ]
    };

    useEffect(() => {

        setShowModal(show)

    }, [show])

    const handleOnHide = () => {
        setShowModal(false);
        setValidateModal(false)
        onHide();
    }

    const handleOnChange = (e) => {
        setContact(e);
        setValidateModal(false)

    }

    const handleOnValidated = (e) => {

        MyService.addContact(organizationNumber, contact).then(response => {
            onComplete();
        }).catch(err => {
            setError(err.response.data.detail);
        })

        if (e) {
            handleOnHide();
        }

    }

    return (
        <Modal show={showModal} onHide={handleOnHide} fullscreen="sm-down">
            <Modal.Header className="grid-header py-2" closeButton>
                <Modal.Title>Add Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body className="gap-2">
                <FormProvider
                    formDefinition={formDefinition}
                    validate={validateModal}
                    onChange={handleOnChange}
                    onValidated={handleOnValidated}
                />
            </Modal.Body>
            <ModalFooter>
                <Button className="bnt btn-sm btn-link dark-blue-link-button"
                        style={{minWidth: '8rem'}}
                        onClick={handleOnHide}>Cancel</Button>
                <Button className="btn-default btn-sm" style={{minWidth: '8rem'}}
                        onClick={() => setValidateModal(true)}>Save</Button>
            </ModalFooter>
        </Modal>
    )
}


export default ContactAdd;