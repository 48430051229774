import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Card from "react-bootstrap/Card";
import AxiosInstance from "../../services/AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";


const MyLinkedAccounts = (props) => {


    const [linkedHouseholds, setLinkedHouseholds] = useState([]);
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        if (props.member_id !== "undefined") {
            AxiosInstance.get(`/api/v1/member/${props.member_id}/household/linked`).then((res => {
                setLinkedHouseholds(res.data);
            }));
        }

        }, [props.member_id]);


    const getHouseholdSorted = async () => {

        let householdRes  = await AxiosInstance.get(`/api/v1/member/${props.member_id}/household`);

        if (householdRes.data.members !== undefined && householdRes.members !== null) {
            householdRes.data.members.sort((a,b) => {

                if (a.memberId == householdRes.data.primaryMemberId) {
                    return -1;
                } else if (a.isActive && !b.isActive) {
                    return -1;
                } else {
                    return 0;
                }

            } );
        }

        return householdRes.data;
    }

    return (
        <Card>
            <Card.Header style={{backgroundColor: "#073e7e", color:"white"}}>Linked Accounts</Card.Header>
            <Card.Body className="pt-1">
                {linkedHouseholds !== undefined && linkedHouseholds.length > 0?
                    <Row key={-1} className="fw-bold">
                        <Col>
                            Linked Account Holder(s)
                        </Col>
                    </Row>:
                    <div className="text-primary mt-2">
                        <i className="bi bi-info-circle-fill"/>{` `}
                        You don't have any linked accounts
                    </div>
                }
                {linkedHouseholds !== undefined ?
                    linkedHouseholds.map( (m,idx) => (
                    <Row key={idx}>
                        <Col>

                            <Link onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.onSelected(m.primary_member_id);
                            }
                            }>

                                <i className="bi bi-play-fill" style={{color: "#073e7e"}}></i>
                                {m.primaryMemberName}
                            </Link>
                        </Col>
                    </Row>
                )): null}
            </Card.Body>
        </Card>
    );
}

export default MyLinkedAccounts;