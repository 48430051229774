import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import AxiosInstance from "../../services/AxiosInstance";
import {Modal} from "react-bootstrap";


const HouseholdAddExisting = (props) => {


    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const [show, setShow] = useState(props.show);


    useEffect(() => {
            setShow(props.show)
        },[props.show]);

    const handleSearchByGUID = (guid) => {

        AxiosInstance.get(`/api/v1/member/${guid}`).then((res => {
            res.data.member_id = res.data._id;
            setFormData(res.data);
        }));

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });

    }

    const handleMemberAdd = (add_member_id) => {

        const memberHouseholdPayload = {
            member_id: add_member_id,
            household_id: props.household_id,
            relationship: formData.relationship
        }

        AxiosInstance.post(`/api/v1/member/${add_member_id}/household/add`, memberHouseholdPayload).then((res => {
            handleClose();
            props.onAdded();
        }));
    }


    const handleClose = () => {
        setShow(false);
        props.onClosed();
    }
    const handleShow = () => setShow(true);

    return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Existing Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form className="d-grid gap-2">
                        <Row>
                            <Col md={4}>
                                Member GUID:
                            </Col>
                            <Col md={7}>
                                <InputGroup>
                                    <Form.Control name="member_id" required type="input" size="sm"
                                                  maxLength={100}
                                                  value={formData.member_id}
                                                  onChange={handleInputChange}
                                        // isInvalid={!!formErrors.streetAddress}
                                                  placeholder="Member's GUID"
                                    />
                                    <Button variant="secondary"
                                            onClick={() => handleSearchByGUID(formData.member_id)}
                                            size="sm"
                                            className="">
                                        <i className="bi bi-search" />
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}></Col>
                            <Col md={5} className="fw-bold">
                                {formData.lastNm != null?
                                    <span>
                                        <i className="bi bi-check text-success"></i>
                                        {formData.firstNm + ' ' + formData.lastNm}
                                    </span> : null
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>Relationship</Col>
                            <Col md={7}>
                                <Form.Select name="relationship"
                                             size="sm"
                                             disabled={formData.member_id != null? false:true}
                                             onChange={handleInputChange}
                                >
                                    <option>---</option>
                                    <option value="Head of Household">Head of Household</option>
                                    <option value="Spouse">Spouse</option>
                                    <option value="Son">Son</option>
                                    <option value="Daughter">Daughter</option>
                                    <option value="Child">Child</option>
                                    <option value="Parent">Parent</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                            onClick={() => handleMemberAdd(formData.member_id)}
                            size="sm"
                            disabled={formData.member_id != null? false:true}
                            className="">
                        Add To Household
                    </Button>
                </Modal.Footer>
            </Modal>
    );
}

export default HouseholdAddExisting;