import {Modal, ModalFooter} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AddressEdit from "../address/AddressEdit";
import PhoneEdit from "../PhoneEdit";
import {useEffect, useState} from "react";
import AxiosInstance from "../../services/AxiosInstance";
import {useAuth} from "../../hooks/useAuth";


const MyAddressUpdate = (props) => {

    const {accessTokenData} = useAuth()
    const [show, setShow] = useState(false);
    const [address, setAddress] = useState({});
    const [addressValidate, setAddressValidate] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const memberEmail = localStorage.getItem('email');


    useEffect(()=>{
        setShow(props.show)
        setAddress(props.address)
    }, [props.show, props.address, props.phone])

    const handleAddressUpdate = (valid) => {

        if (valid) {
            const addressData = {
                "memberId": props.memberId,
                "address" : {
                    "streetAddress" : address.streetAddress,
                    "cityNm": address.cityNm,
                    "stateCd": address.stateCd,
                    "postalCd": address.postalCd,
                    "modifiedDt": Date.now()
                },
                "modifiedBy": accessTokenData['email'],
                "modifiedDt": Date.now()
            }

            AxiosInstance.post('/api/v1/member', addressData).then(res=>{
                setAddressValidate(false);
                handleClose();
            })
        } else {
            // props.onClose();
        }

    }

    const handleAddressChange = (address) => {

        setAddress(address);

        setAddressValidate(false);
    }

    const handleClose =() => {
        setShow(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    return (
        <Modal show={show} onHide={handleClose} fullscreen="sm-down">
            <Modal.Header className="grid-header" closeButton>
                <Modal.Title>Update Address</Modal.Title>
            </Modal.Header>
            <Modal.Body className="gap-2">
                <AddressEdit address={address}
                             validate={addressValidate}
                             onChange={handleAddressChange}
                             onValidated={handleAddressUpdate} />
            </Modal.Body>
            <ModalFooter>
                <Button className="btn-default" style={{minWidth: '10rem'}} onClick={()=> setAddressValidate(true)}>Save</Button>
            </ModalFooter>
        </Modal>
    );
}

export default MyAddressUpdate;