import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from "react";
import {FloatingLabel, FormControl, FormLabel, Spinner} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useCloverSDK} from "../../hooks/useCloverSDK";
import AppConfig from "../../services/AppConfig";


const CloverCreditCard = forwardRef((props, ref) => {


    const [card, setCard] = useState({});
    const [cardToken, setCardToken] = useState({});
    const [cardErrors, setCardErrors] = useState({});
    const formRef= useRef();

    const {Clover} = useCloverSDK(AppConfig().clover.sdk_uri, 'Clover', AppConfig().clover.public_api_key);

    const styles = {
        body: {
            fontFamily: 'Roboto, Open Sans, sans-serif',
            fontSize: '12px',
            height: '30px'
        },
        'card-number': {
            height: '30px'
        },
        'card-number img': {
            maxHeight: '20px !important',
            margin: "5px 5px 0 0",
            paddingRight: "5px"
        },
        'card-cvv input': {
            textAlign: "center"
        },
        'card-date': {
            height: '30px'
        },
        'card-date input': {},
        'card-postal-code input': {
            width: '115px',
            marginRight: '5px',
            float: "right"
        },
        'input::placeholder': {
            color: 'rgba(108,117,125,0.8)',

        },
        input: {
            width: '99%',
            fontSize: '1rem',
            // display: 'block',
            fontWeight: '400',
            lineHeight: '1.5',
            color: '##212529',
            backgroundClip: 'padding-box',
            padding: '16px 10px 13px 10px',
            border: '1px solid #ced4da',
            borderRadius: '.375rem',
            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'

        }
    };

    useEffect(() => {

        if (Clover != null) {

            const elements = Clover.elements();
            const cardNumber = elements.create('CARD_NUMBER', styles);
            const cardDate = elements.create('CARD_DATE', styles);
            const cardCvv = elements.create('CARD_CVV', styles);
            const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);
            //
            cardNumber.mount('#card-number');
            cardDate.mount('#card-date');
            cardCvv.mount('#card-cvv');
            cardPostalCode.mount('#card-postal-code');

            cardNumber.addEventListener('change', function(event) {
                setCardErrors({});
            });
            cardDate.addEventListener('change', function(event) {
                setCardErrors({});
            });
            cardCvv.addEventListener('change', function(event) {
                setCardErrors({});
            });
            cardPostalCode.addEventListener('change', function(event) {
                setCardErrors({});
            });

        }

    },[Clover])

    useImperativeHandle(
        ref,
        () => ({
            getToken() {
                formRef.current.click()
            }
        }),
    )

    const test = (e) => {
        e.preventDefault();

        Clover.createToken().then(res => {

            if (res.token) {
                setCardToken(res.token);
                props.onCardValidated(res);
            }

            if (res.card) {
                setCard(res.card);
            }

            if (res.errors) {
                setCardErrors(res.errors);
            } else {
                setCardErrors({});
            }

            console.log("res: " + JSON.stringify(res));
        }).catch(err => {
            console.log("err from CCreditCard: " + JSON.stringify(err.response));
        });

    }

    return (
        <Card className="mt-3 col-12 col-sm-6 col-md-6 col-lg-4"
              style={{width: props.width? props.width : null}}
        >
            <Card.Header style={{backgroundColor:"#ebf1fc"}}>Payment Card Information</Card.Header>
            <Card.Body>
            <Row className="">
                <Col>
                    <Form id="payment-form"  onSubmit={test} className="d-grid gap-0">
                        <Row>
                            <Col className="pr-0">
                                <Form.Label className="mb-0 small">Name on the Card<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="input"
                                              style={{padding: '4px 10px 4px'}}
                                              placeholder="Cardholder Name"
                                ></Form.Control>
                            </Col>
                        </Row>


                        <Row className="" style={{height:"55px"}}>
                            <Col className="col-12" >
                                <Form.Label className="mb-0 small">Card Number<span className="text-danger">*</span></Form.Label>
                                <div id="card-number"
                                     style={{height:'35px'}}
                                     className="field text-primary"></div>
                            </Col>

                        </Row>

                        <Row>
                            <Col><div className="text-danger small">{cardErrors.CARD_NUMBER}</div></Col>
                        </Row>

                        <Row style={{height:'55px', overflow:'hidden'}}>
                            <Col className="col-3">
                                <Form.Label className="mb-0 small">
                                    Expiration<span className="text-danger">*</span>
                                </Form.Label>
                                <div id="card-date"
                                     style={{height:'35px'}}
                                     className="input field"></div>
                            </Col>
                            <Col className="col-3" >
                                <Form.Label className="mb-0 small" >
                                    CVV<span className="text-danger">*</span>
                                </Form.Label>
                                <div id="card-cvv"
                                     className="field"></div>
                            </Col>

                            <Col className="">
                                <Form.Label className="mb-0 float-end small" >
                                    Billing Postal Code<span className="text-danger">*</span>
                                </Form.Label>
                                <div id="card-postal-code"
                                     className="field float-right"></div>
                            </Col>
                        </Row>

                        <Row>
                            <Col><div className="text-danger small">{cardErrors.CARD_DATE}</div>
                                <div className="text-danger small">{cardErrors.CARD_CVV}</div>
                            </Col>

                            <Col className="col-4" style={{padding: "0px 2px 0px 0px"}}>
                                <div className="text-danger float-end small">{cardErrors.CARD_POSTAL_CODE}</div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={12} className="d-none">
                                <Button variant="primary" ref={formRef} type="submit">Process</Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
            </Card.Body>
        </Card>
    )

})

CloverCreditCard.defaultProps = {
    card: {
        name: "John Smith",
        number: "1234234534564567",
        expYear: "2027",
        expMonth: "05",
        CVV: "123"
    },
    mask: true
}

export default CloverCreditCard;