import React, {useEffect, useState} from 'react';
import AxiosInstance from "../../services/AxiosInstance";
import {useLocation, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import OrganizationUpdate from "./OrganizationUpdate";
import OrganizationView from "./OrganizationView";
import {useNavigate} from "react-router";
import {Breadcrumb} from "react-bootstrap";

const Organization = (props) => {

    const [organization, setOrganization] = useState({});
    const [editMode, setEditMode] = useState(false);
    const {organizationNumber} = useParams()
    const navigate = useNavigate();
    const {state} = useLocation();

    useEffect(() => {
        AxiosInstance.get('/api/v1/organization/' + organizationNumber).then((res => {
            setOrganization(res.data);
        }))
    }, [organizationNumber, editMode])

    const handleEditMode = () => {
        setEditMode(!editMode)
    }

    const handleGroupAdded = (group) => {
        AxiosInstance.get('/api/v1/organization/' + organizationNumber).then((res => {
            setOrganization(res.data);
        }))
    }

    const content = (
        !editMode ?
            <OrganizationView organization={organization}
                              onEdit={handleEditMode}
                              onGroupAdded={handleGroupAdded}
                              onCancel={() => navigate(-1)}/> :
            <OrganizationUpdate organization={organization}
                                onUpdated={handleEditMode}
                                onCancel={handleEditMode}/>
    )

    return (
        <Container>
            <Breadcrumb className="my-2">
            {
                state!= null && state.breadcrumb ?
                    state.breadcrumb.map((bc, idx) => (
                        <Breadcrumb.Item key={idx} href={bc.link}>{bc.name}</Breadcrumb.Item>
                    )):null
            }
                <Breadcrumb.Item key={'parent'} href={`/organizations`}>Organizations</Breadcrumb.Item>
                <Breadcrumb.Item key={'active'} active>{organization.organizationName}</Breadcrumb.Item>
            </Breadcrumb>
            {content}

        </Container>
    );

};

export default Organization;