import API_V2 from "./API_V2";


class CampaignMembershipService {

    static upsertMembership(membership) {
        return API_V2.post(`/v2/membership  `, membership)
            .then(res => {
                    return res.data;
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });

    }

    static updateDonation(donation) {
        return API_V2.post(`/v2/membership/donations/`, donation)
            .then(res => {
                    return res.data;
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });

    }

    static getCampaignMembership(c_membership_id) {
        return API_V2.get(`/v2/membership/${c_membership_id}?exclude_inactive=True`)
            .then(res => {
                    return res.data;
                }
            ).catch(err => {
                if (err.response) {
                    throw {status: err.response.status, message: err.response.data.detail};
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });

    }

    static refreshPrices(membershipId) {
        return API_V2.get(`/v2/membership/${membershipId}/refresh-price`)
            .then(res => {
                    return res.data;
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });

    }

}

export default CampaignMembershipService;