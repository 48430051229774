import React from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router';

const Landing = (props) => {

    const navigate = useNavigate();

    return (

        <div className="" style={{
            minHeight:"800px",
            backgroundImage: `url(${process.env.PUBLIC_URL + "/images/mf_landing_bg.png"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: 'left'}}>

              <div className="container pt-5" style={{color:"#FFFFF"}}>
                  <h1 className="mb-0">Welcome to</h1>
                  <div className="brand-pre-title-welcome mt-0 p-0 mb-2">
                      my <span className="brand-title-welcome">Fund Center</span>
                  </div>
                  <p className="mt-3 mb-5" style={{color:"#03045e", fontSize: "18px"}}>
                      We help churches, small organizations, and communities manage private fundraising initiatives since 2001.
                  </p>
                  <Button variant="default" size="lg" onClick={() => navigate('/sign-in')}>Sign In »</Button>
                  <Button variant="success" size="lg" className="btn-space" onClick={() => navigate('/sign-up')}>
                      or Register »
                  </Button>
              </div>
        </div>
    )

};

export default Landing;