import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {Fragment, useEffect, useState} from "react";
import {requiredValidator, lettersOnlyHandler, numericOnlyHandler} from "../../utils/inputValidators";
import {map} from "react-bootstrap/ElementChildren";


const AddressEdit = ({address, onChange, validate, onValidated}) => {


    const formFields = [
        {
            "name": "streetAddress",
            "rowNumber": 1,
            "rowClass": "col-2",
            "colClass": "col-12",
            "controlClass": "text-small",
            "placeholder": "Street Address",
            "maxLength": 100,
            "validators": [{
                "name": "requiredField",
                "minLength": 4,
                "message": "Street Name and Number is required"
            }]
        }, {
            "name": "cityNm",
            "rowNumber": 2,
            "maxLength": 30,
            "colClass": "col-6 m-0",
            "controlClass": "m-0 pr-0",
            "keypressHandler": lettersOnlyHandler,
            "validators": [{
                "name": "requiredField",
                "minLength": 3,
                "message": "City name is required"
            }]
        }, {
            "name": "stateCd",
            "rowNumber": 2,
            "maxLength": 2,
            "colClass": "col-3 m-0",
            "keypressHandler": lettersOnlyHandler,
            "validators": [{
                "name": "requiredField",
                "minLength": 2,
                "message": "State is required"
            }]
        }, {
            "name": "postalCd",
            "rowNumber": 2,
            "maxLength": 5,
            "colClass": "col m-0",
            "keypressHandler": numericOnlyHandler,
            "validators": [{
                "name": "requiredField",
                "minLength": 4,
                "message": "Zip Code is required"
            }]
        }
    ];

    const [form, setForm] = useState(() => {
        let newFormState = {}
        formFields.forEach(field => {
            newFormState = {...newFormState, [field.name]: ""}
        })
        return newFormState
    });
    const [formErrors, setFormErrors] = useState({});



    const handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let newFormState = {
            ...form, [name]: value
        }

        delete formErrors[name];
        setFormErrors({...formErrors});

        setForm(newFormState);

        if (onChange) {
            onChange(newFormState);
        }
    }

    useEffect(() => {

        if (address !== undefined && address !== null) {
            setForm(address);
        }

        return (() => {
            setForm({});
        })
    }, [address])



    useEffect(() => {
        if (validate) {
            handleValidate();
        }
    }, [validate])


    const handleValidate = () => {

        let errors = {};

        formFields.forEach((formField, i) => {
            formField.validators.forEach(validator => {
                if (validator.name === "requiredField") {
                    !requiredValidator(form[formField.name], validator.minLength) ?
                        errors[formField.name] = validator.message :
                        delete errors[formField.name];
                }
            })
        })

        if (Object.keys(errors).length === 0) {
            if (onValidated) {
                onValidated(true);
            }
        }

        setFormErrors({...errors})

    }

    const createFormControl = ({
                                   type = "input",
                                   name,
                                   className,
                                   placeholder,
                                   maxLength = 100,
                                   keypressHandler = null
                               }) => {

        return (
            <>
                <Form.Control name={name} required type={type}
                              maxLength={maxLength}
                              value={form[name]}
                              className={className}
                              onChange={handleInputChange}
                              isInvalid={!!formErrors[name]}
                              onKeyPress={keypressHandler}
                              placeholder={placeholder}
                />
                <Form.Control.Feedback type="invalid"
                                       className="small">{formErrors[name]}
                </Form.Control.Feedback>
            </>

        )
    }

    const renderControls = () => {
        // Find the highest rowNumber
        const maxRowNumber = Math.max(...formFields.map(field => field.rowNumber));

        // Create rows based on the maximum row number, populating controls that match the current row number
        const myArray = Array.from({length: maxRowNumber}, (_, rowIndex) => rowIndex + 1)

        return myArray.map(rowNumber => (
            <div key={rowNumber} className="row mb-3 gx-2">
                {formFields
                    .filter(formField => formField.rowNumber === rowNumber)
                    .map((formField, i) => (
                        <div key={i} className={formField.colClass}>
                            {
                                createFormControl({
                                    name: formField.name,
                                    className: formField.controlClass,
                                    placeholder: formField.placeholder,
                                    maxLength: formField.maxLength,
                                    keypressHandler: formField.keypressHandler
                                })
                            }
                        </div>
                    ))}
            </div>
        ))
    }


    return (
        <>
            {renderControls()}
        </>)
}


export default AddressEdit;