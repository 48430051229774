import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import AxiosInstance from "../../services/AxiosInstance";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddressEdit from "../../components/address/AddressEdit";
import PhoneEdit from "../../components/PhoneEdit";
import {useNavigate} from "react-router";
import IdentityService from "../../services/IdentityService";
import CustomerErrorComponent from "../../components/CustomErrorComponent";

const OrganizationUpdate = (props) => {

    const [hasPermission, setHasPermission] = useState(true);
    const [organization, setOrganization] = useState(props.organization);
    const [formErrors, setFormErrors] = useState({});
    const [parentOrganizations, setParentOrganizations] = useState([]);
    const [authorizationCode, setAuthorizationCode] = useState();
    const navigate = useNavigate();


    useEffect(() => {

        AxiosInstance.get('/api/v1/organization').then((res => {
            setParentOrganizations(res.data);
        }))


        IdentityService.checkPermission(organization.organizationNumber, "organization.details.update")
            .then(res => {
                setHasPermission(res)
            })


    }, [organization])

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setOrganization({
            ...organization,
            [name]: value
        });

    }

    const handleAddressChange = (address) => {

        setOrganization({
            ...organization,
            "address": address
        });

    }

    const handlePhoneChange = (phone) => {

        setOrganization({
            ...organization,
            "phones": [{
                phoneNbr: phone,
            }]
        });

    }

    const getParentOrganizationsList = () => {

        let orgs = [];
        let options = [];
        options.push(<option key={0} value={0}>Parent Organization</option>); // first/default option

        parentOrganizations.map((org, idx) => {
                options.push(<option key={idx}
                                     selected={org.organizationNumber == organization.parentOrganization ? true : false}
                                     value={org.organizationNumber}
                >{org.organizationName}
                </option>);
            }
        )

        return options;


    }

    const handleCancel = () => {
        props.onCancel();
    }

    const handleSubmit = () => {

        let errors = {};

        const defaultAddress = {
            streetAddress: "",
            cityNm: "",
            stateCd: "",
            postalCd: ""
        }

        let address = organization.address ? organization.address : defaultAddress;


        !/[a-z0-9\w]{2,}$/i.test(address.streetAddress) ? errors.streetAddress = "Street name and number is required" :
            delete errors.streetAddress;

        !/[a-z0-9\w]{2,}$/i.test(address.cityNm) ? errors.cityNm = "City is required" :
            delete errors.cityNm;

        !/[a-z0-9\w]{2,2}$/i.test(address.stateCd) ? errors.stateCd = "State is required" :
            delete errors.stateCd;

        !/[a-z0-9\w]{5,}$/i.test(address.postalCd) ? errors.postalCd = "ZipCode is required" :
            delete errors.postalCd;


        setFormErrors({address: {...errors}});

        if (Object.keys(errors).length === 0) {
            AxiosInstance.post('/api/v1/organization/', organization).then((res => {
                props.onUpdated();
            }))
        }
    }

    const handleMerchConnect = () => {

        const redirectURL = "http://localhost:3000/organizations/BU15D7B1NN"
        window.location.replace(`https://sandbox.dev.clover.com/oauth/authorize?client_id=VFVGEJTCSS88G&redirect_uri=${redirectURL}`);
        // AxiosInstance.get("https://sandbox.dev.clover.com/oauth/authorize?client_id=VFVGEJTCSS88G").then(res => {
        //     console.log(res);
        // })

    }


    return (

        <>
            {!hasPermission ? <CustomerErrorComponent code={"401"}
                                                      message={`You are not authorized to view this page`}/> :
                <Form className="mt-5 col-lg-5 col-md-8">

                    <Row className="mb-3">
                        <Col>
                            <Form.Label>Organization Name</Form.Label>
                            <Form.Control name="organizationName" type="text"
                                          maxLength={100}
                                          value={organization.organizationName}
                                          onChange={handleInputChange}
                                          placeholder="Organization Name"
                            />
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col>
                            <Form.Label>Parent Organization</Form.Label>
                            <Form.Select name="parentOrganization" className=""
                                // isInvalid={formErrors.expYear}
                                // isValid={valid}
                                         onChange={handleInputChange}
                                         placeholder="Parent Organization"
                                         required>
                                {getParentOrganizationsList()}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Address</Form.Label>
                            <AddressEdit address={organization.address}
                                         errors={formErrors.address}
                                         onChange={handleAddressChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Phone</Form.Label>
                            <PhoneEdit phoneNumber={
                                organization.phones !== null &&
                                organization.phones !== undefined &&
                                organization.phones[0] !== undefined &&
                                organization.phones[0] !== null ?
                                    organization.phones[0].phoneNbr : null
                            }
                                       onChange={handlePhoneChange}
                            />
                        </Col>
                    </Row>


                    <Row className="mt-3">
                        <Col className="d-grid">
                            <Button variant="outline-dark" className="d-inline" onClick={handleCancel}>
                                Back
                            </Button>
                        </Col>
                        <Col className="d-grid">
                            <Button variant="default" className="d-block" onClick={handleSubmit}>
                                {organization.organizationNumber ? "Update" : "Create"}
                            </Button>
                        </Col>
                    </Row>


                    <Row className="mb-3 mt-5">
                        <Col>
                            <Form.Label>Merchant Account</Form.Label>
                            <Form.Control name="merchentAccount" type="text"
                                          maxLength={100}
                                // value={organization.organizationName}
                                          onChange={handleInputChange}
                                          placeholder="Merchant ID(MID)"
                            />
                            <div>Auth Code: {authorizationCode}</div>
                        </Col>
                    </Row>
                    <Row className="d-grid mb-5">
                        <Col>
                            <Button variant="outline-dark"
                                    className="d-block float-end"
                                    onClick={handleMerchConnect}
                            >
                                Connect
                            </Button>
                        </Col>
                    </Row>

                </Form>
            }

        </>

    );

}


export default OrganizationUpdate;