import API_V2 from "./API_V2";
import {Contact} from "../interfaces";

class OrganizationService {

    static listOrganizations() {
        return API_V2.get(`/v2/organizations/list`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }


    static getOrganization(organizationNumber: string) {
        return API_V2.get(`/v2/organizations/${organizationNumber}`)
            .then(res => {
                return res.data
            }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static searchMembers(organizationNumber: string, searchTerm: string) {
        return API_V2.get(`/v2/organizations/${organizationNumber}/members/search?search_term=${searchTerm}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static getContacts(organizationNumber: string) {
        return API_V2.get(`/v2/organizations/${organizationNumber}/contacts`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static addContact(organizationNumber: string, contact: Contact) {
        return API_V2.post(`/v2/organizations/${organizationNumber}/contacts`, contact)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static deleteContact(organizationNumber: string, member_id: string) {
        return API_V2.delete(`/v2/organizations/${organizationNumber}/contacts/${member_id}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});

                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }


}

export default OrganizationService;