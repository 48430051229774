import {Pagination} from "react-bootstrap";
import styles from "./PaginationStrip.module.css";
import React, {useEffect, useState} from "react";

interface IPaginationStripProps {
    currentPage: number;
    pageSize: number;
    resultsCount: number;
    pagesLength?: number;
    onPageClick?: (pageNumber: number) => void;
    startingIndex?: number;
    totalPages?: number;
}

const PaginationStrip = ({
                             currentPage = 1,
                             pageSize,
                             resultsCount,
                             pagesLength = 10,
                             onPageClick
                         }: IPaginationStripProps) => {


    const [state, setState] = useState<IPaginationStripProps>({
        currentPage: currentPage,
        pageSize: pageSize,
        resultsCount: resultsCount,
        pagesLength: pagesLength,
        totalPages: 0,
        startingIndex: 1
    })

    useEffect(() => {

        setState({
            ...state,
            currentPage: currentPage,
            pageSize: pageSize,
            pagesLength: pagesLength === 0 ? state.pagesLength : pagesLength,
            totalPages: Math.ceil(resultsCount / pageSize)
        })
    }, [currentPage, pageSize, resultsCount, pagesLength]);


    const handleGoFirstClick = () => handlePageClick(1, 1)

    const handleGoPreviousClick = () => {

        const newPageIndex = state.currentPage > 1 ? state.currentPage - 1 : state.currentPage

        // @ts-ignore
        const newStartingIndex = state.currentPage > 1 && state.startingIndex === state.currentPage ?
            state.pagesLength && (state.startingIndex - state.pagesLength) : state.startingIndex

        handlePageClick(newPageIndex, newStartingIndex? newStartingIndex : 0)
    }

    const handleGoNextClick = () => {

        const pages: number = state.totalPages ? state.totalPages : 0
        const len: number = state.pagesLength ? state.pagesLength : 0
        const idx: number = state.startingIndex ? state.startingIndex : 0


        const newPageIndex :number = state.currentPage < pages ? state.currentPage + 1 : state.currentPage
        const newStartingIndex: number = (len + idx) <= newPageIndex ? (idx + len) : idx

        handlePageClick(newPageIndex, newStartingIndex)
    }

    const handleGoLastClick = () => {

        const pages: number = state.totalPages ? state.totalPages : 0
        const len: number = state.pagesLength ? state.pagesLength : 0
        const idx: number = state.startingIndex ? state.startingIndex : 0

        const newPageIndex = state.totalPages ? state.totalPages : 0
        const newStartingIndex = (pages + 1) - Math.min(len, pages)

        handlePageClick(newPageIndex, newStartingIndex)
    }

    const handlePageClick = (pageNumber:number, startingIndex: number) => {

        // startingIndex = (startingIndex === 0) ? state.startingIndex : startingIndex

        setState({
            ...state,
            // currentPage: pageNumber,
            startingIndex: startingIndex
        })

        if (onPageClick !== undefined) {
            onPageClick(pageNumber)
        }
    }

    const renderPages = (idx:number = 1, pages: number = 1, len: number=1) => {

        const items: any[] = [];


        for (let i = idx; (i < (idx + len)) && (i <= pages); i++) {

            items.push(
                <Pagination.Item
                    className={`${styles.paginationItem} ${styles.paginationItemSelected}`}
                    key={i}
                    active={state.currentPage === i}
                    onClick={() => {
                        handlePageClick(i, idx)
                    }}
                >{i}</Pagination.Item>
            )
        }

        return items;

    }

    return (
        <>
            {resultsCount > pageSize &&
                <Pagination size="sm" className="text-secondary">
                    <Pagination.First className={styles.paginationNav} onClick={handleGoFirstClick}/>
                    <Pagination.Prev className={styles.paginationNav} onClick={handleGoPreviousClick}/>
                    {renderPages(state.startingIndex, state.totalPages, pagesLength)}
                    <Pagination.Next className={styles.paginationNav} onClick={handleGoNextClick}/>
                    <Pagination.Last className={styles.paginationNav} onClick={handleGoLastClick}/>
                </Pagination>
            }
        </>

    )
}

export default PaginationStrip;