import React, {Fragment, useState} from 'react';
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import AxiosInstance from '../../services/AxiosInstance.js'


// Please check  vitaliy@gogolevs.com for a link to create a new password for your Arlo account. If you don't see the email, check your spam, or tap "Resend email", below.
// You requested to reset your Arlo account password. To complete the process, please click the “Reset Password” button below.
// copy and paste the following link into your browser’s address bar:

const ForgotPassword = (props) => {

    AxiosInstance.defaults.headers['callbackUrl'] = window.location.origin;

    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [valid, setValid] = useState(false);
    const [resent, setResent] = useState(false);


    const handleResend = (e) => {
        setResent(!resent)
    }

    const handleForgotPassword = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let errors = {};

        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email) ? errors.email = "Invalid email address" :
            delete errors.email;

        if (Object.keys(errors).length === 0) {
            AxiosInstance.post('/forgot-password', {"email": formData.email})
                .then((res) => {
                    console.log(res)
                    setResent(true);
                    setValid(true);
                    setFormData({}); //clear form
                    // setTimeout(() => props.history.push('sign-in'), 1000 );

                }).catch((err) => {
                console.log(JSON.stringify(err.response.data));
                if (err.response.data === "Not Found") {
                    errors.email = "This email is not registered or not confirmed"
                }

                setFormErrors({...errors})
            });
        }


        setFormErrors({...errors})

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    let resubmitLayout = <div className="container">
        <div className="row justify-content-md-center mt-5">
            <div className="col-md-5 text-center">
                <div className="h4 mb-1">Forgot Password</div>
                <div className="text-muted">
                    Enter your email address below to reset your password.
                </div>

            </div>
        </div>
        <div className="row justify-content-md-center mt-3">
            <Form className="col-md-5 text-center">
                <Form.Group>
                    <InputGroup className="mt-2">

                        <InputGroup.Text id="basic-addon1">@</InputGroup.Text>

                        <Form.Control name="email" className="text-center" required type="input"
                                      isInvalid={formErrors.email}
                                      isValid={valid}
                                      placeholder="Email Address" onChange={handleInputChange}/>
                        <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mt-3 d-grid ">
                        <Button variant="default" className="d-block" type="submit" disabled={resent} onClick={handleForgotPassword}>
                            Submit
                        </Button>
                    </InputGroup>
                </Form.Group>
            </Form>
        </div>
    </div>

    let emailSentLayout = <div className="container">
        <div className="row justify-content-md-center mt-5">
            <div className="col-md-5 text-center">
                <div className="h4 mb-1">Forgot Password</div>
                <div className="text-muted">
                    Please check your email {formData.email} for a link to create a new password for your MyFundCenter account.
                    If you don't see the email, check your spam, or tap "Resend Email" below.
                </div>
                <InputGroup className="mt-2">
                    <Button variant="default" type="submit" block onClick={handleResend} >
                        Resend Email
                    </Button>
                </InputGroup>

            </div>
        </div>
    </div>

    return (
        resent? emailSentLayout: resubmitLayout
    );
}

export default (ForgotPassword);