import React from "react";
import Avatar from "react-avatar";
import PhoneView from "../PhoneView";
import {Link} from "react-router-dom";
import {Contact} from "../../interfaces";
import Button from "react-bootstrap/Button";
import {OverlayTrigger, Popover} from "react-bootstrap";

interface ContactCardProps {
    contact: Contact;
    onDelete?: (member_id: string) => void;
}

const ContactCard = ({contact, onDelete}: ContactCardProps) => {

    const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

    const handleOnDelete = () => {

        setShowDeleteConfirm(false);
        onDelete && onDelete(contact.member_id)

    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Are you sure?</Popover.Header>
            <Popover.Body>

                <div>
                    <Button className="bnt btn-sm btn-link dark-blue-link-button text-danger"
                            onClick={handleOnDelete}>Yes</Button>
                    <Button className="bnt btn-sm btn-link dark-blue-link-button"
                            onClick={() => setShowDeleteConfirm(!showDeleteConfirm)}
                    >Cancel</Button>
                </div>

            </Popover.Body>
        </Popover>
    );

    return (
        <div className="card" style={{maxWidth: '25rem'}}>
            <div className="card-body d-flex p-2">
                <div className="me-2 ms-1 mt-1">
                    <Avatar name={contact.name} color="#D1D1D1" round={true} size="80"/>
                </div>

                <div className="">
                    <div className="fw-bolder">{contact.name}</div>
                    <div className="text-secondary small lh-1">{contact.title}</div>
                    {
                        contact.phone &&
                        <div className="small mt-2 lh-small">
                            <i className="bi bi-phone text-secondary"></i>
                            <PhoneView phoneNumber={contact.phone}/>
                        </div>
                    }
                    {
                        contact.email &&
                        <div className="text-secondary small lh-small">
                            <i className="bi bi-envelope me-1"></i>
                            <Link className="text-decoration-underline"
                                  to="#"
                                  onClick={() => window.location.href = `mailto:${contact.email}`}>
                                {contact.email}
                            </Link>
                        </div>
                    }

                </div>

                <div className="ms-auto">
                    <OverlayTrigger trigger="click" show={showDeleteConfirm} onToggle={(e)=>setShowDeleteConfirm(e)}  rootClose={true} placement="left-start" overlay={popover}>
                        <Button className="btn-close btn-sm" onClick={(e) => setShowDeleteConfirm(true)}> </Button>
                    </OverlayTrigger>

                </div>

            </div>

        </div>
    )
}

export default ContactCard;