
const AddressView = ({streetAddress = "", cityNm = "", stateCd = "", postalCd = ""}) => {

    return (
        <>
            <div className="lh-sm">{streetAddress !== "" && streetAddress}</div>
            <div className="lh-sm">{`${cityNm !== "" ? cityNm + ', ' : ''}${stateCd} ${postalCd}`}</div>
        </>
    )
}

export default AddressView;