import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {useEffect, useState} from "react";
import MemberService from "../../services/MemberService";
import CampaignMembershipService from "../../services/CampaignMembershipService";
import * as linked_accounts_res from "react-bootstrap/ElementChildren";

const DonationLinkedAccountsToInclude = ({member_id, onSelected}) => {

    const [accounts, setAccounts] = useState([]);
    const [form, setForm] = useState({})
    const [donations, setDonations] = useState([]);
    const [selectedDonations, setSelectedDonations] = useState([]);


    useEffect(() => {

        refresh2()


    }, [member_id])

    useEffect(() => {
        handleSelected()
    }, [form])


    const handleSelected = () => {

        let selectedDonationsPayload = []
        if (form.selected !== undefined) {
            form.selected.forEach((m) => {

                let donation = donations.find((d_header) => d_header.member_id === m)
                donation.donations.forEach((d) => {
                    selectedDonationsPayload.push(
                        {
                            donation_id: d._id,
                            amount: d.totalBalance,
                            extRef: d.externalRef,
                            isPrimary: false
                        }
                    )
                })
            })
        }

        setSelectedDonations(selectedDonationsPayload)

        onSelected && onSelected(selectedDonationsPayload)

        // donations.forEach((d) => {
        //     donationsPayload.push(
        //         {
        //             donation_id: d._id,
        //             amount: d.minAmountDue,
        //             extRef: d.externalRef
        //             // isPrimary: m.isPrimary
        //         }
        //     )
        // })
    }

    const getMyDonations = () => {

        MemberService.getCampaigns(member_id).then((res) => {

            res.forEach((c) => {
                console.log(`res.donations: ${JSON.stringify(c.donations)}`)
                setDonations(c.donations);
            })
        })
    }


    const refresh2 = () => {


        (async () => {

            const l_accounts = await MemberService.getLinkedMembers(member_id)
            let donationsPayload = []

            for (let i = 0; i < l_accounts.length; i++) {

                let payload = {
                    "member_id": l_accounts[i]._id,
                    "lastNm": l_accounts[i].lastNm,
                    "firstNm": l_accounts[i].firstNm,
                    "totalBalance": 0,
                    "donations": []
                }

                const campaigns = await MemberService.getCampaigns(l_accounts[i]._id)

                let c_donations = []
                for (let j = 0; j < campaigns.length; j++) {
                    c_donations.push(...campaigns[j].donations)
                }

                // calculate donations total
                let totalBalance = 0
                c_donations.forEach((d) => {
                    d.totalBalance = (d.minAmountDue - d.paymentsTotal)
                    totalBalance += (d.minAmountDue - d.paymentsTotal)
                })

                payload.totalBalance = totalBalance
                payload.donations = c_donations;

                donationsPayload.push(payload);

            }


            setDonations(donationsPayload);

        })()

        // MemberService.getLinkedMembers(member_id).then((l_accounts) => {
        //
        //     l_accounts.forEach((l_account) => {
        //
        //         MemberService.getCampaigns(l_account._id).then((campaigns) => {
        //
        //             let c_donations = []
        //             campaigns.forEach((c) => {
        //
        //                 c_donations = [...c_donations, ...c.donations]
        //             })
        //
        //             setDonations([l_account]);
        //             console.log("I AM HERE", c_donations)
        //
        //
        //         })
        //
        //     })
        //
        // })

    }


    const handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let newFormState = {
            ...form, [name]: value
        }

        // delete formErrors[name];
        // setFormErrors({...formErrors});


        let selectedValues = []
        Object.entries(newFormState).forEach(([key, value]) => {
            if (value === true) {
                selectedValues.push(key)

            }
        })
        newFormState["selected"] = selectedValues;
        setForm(newFormState);


    }


    const renderLinkedMembers = () => {
        return (
            <div>
                <div className="grid-header px-2">
                    Linked Accounts to Include
                </div>

                <div>
                    {donations.map((li, idx) => (
                        <div>
                            <Row key={idx} className="m-auto">
                                <Col md={8} xs={8}>
                                    <Form.Check name={li.member_id}
                                                required type="checkbox"
                                                onChange={handleInputChange}
                                                label={`${li.lastNm} ${li.firstNm}`}

                                    />

                                </Col>
                                <Col>
                                    {`$ ` + li.totalBalance.toFixed(2)}
                                </Col>
                            </Row>
                        </div>
                    ))
                    }
                </div>
                {/*<div>*/}
                {/*    <div>state</div>*/}
                {/*    <div>{JSON.stringify(selectedDonations)}</div>*/}
                {/*</div>*/}
            </div>
        )
    }

    return (
        donations.length > 0 && renderLinkedMembers()
        )


}

export default DonationLinkedAccountsToInclude;