import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


const AccountSummaryGrid = (props) => {


    return(
        <Container className="mb-2 d-grid gap-2">

            {
                props.accountSummaries.map((accountSummary) => (
                    <div key={accountSummary.accountName}>
                        <Row className="bg-dark text-light py-2">
                            <Col>
                                {accountSummary.accountName}
                            </Col>
                        </Row>
                        <Row className="">
                            <Col>
                                Last Payment Received
                            </Col>
                            <Col md={2} xs={3}>
                                {`$ `}{accountSummary.previousPayment.toFixed(2)}
                            </Col>
                        </Row>
                        <Row className="">
                            <Col>
                                Balance
                            </Col>
                            <Col md={2} xs={3}>
                                {`$ `}{accountSummary.balance.toFixed(2)}
                            </Col>
                        </Row>
                        <Row >
                            <Col >
                                <Button variant={accountSummary.balance > 0? "default" : "secondary" }
                                        disabled={accountSummary.balance > 0? false : true}
                                        className="float-end"
                                        >
                                    Make a Payment
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ))
            }

        </Container>

    );

}

export default AccountSummaryGrid;