import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import AxiosInstance from "../../services/AxiosInstance";
import Container from "react-bootstrap/Container";
import NavigateBackMenu from "../../components/NavigateBackMenu";
import CampaignRunDonations from "../../components/Campaign/CampaignRunDonations";


const CampaignRunView = () => {

    const {campaignId, campaignRunId} = useParams();
    const [campaignRun, setCampaignRun] = useState({});


    useEffect(() => {

        AxiosInstance.get(`/api/v1/campaign-run/${campaignRunId}`).then((res => {
            setCampaignRun(res.data);

        }));

    }, [campaignRunId])

    return (
        <Container>
            <NavigateBackMenu />
            <div className="h5 mt-3">
                {campaignRun.description}
            </div>
            <div>
                { campaignRun.donationsAmount ? <span>{`$ ${parseFloat(campaignRun.donationsAmount.toFixed(2)).toLocaleString()}`}</span> : null}
            </div>

            <div>
                <CampaignRunDonations campaignId={campaignId} runId={campaignRunId} />
            </div>

        </Container>

    );
}

export default CampaignRunView;