import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import React, {useEffect, useState} from 'react'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Alert from "react-bootstrap/Alert"
import Col from "react-bootstrap/Col"
import AxiosInstance from '../../services/AxiosInstance.js'
import API_V2 from "../../services/API_V2";
import InputGroup from 'react-bootstrap/InputGroup'
import {useNavigate} from "react-router";
import {getTokenData, Logger} from "../../utils/Utils";
import MemberService from "../../services/MemberService";
import {useAuth} from "../../hooks/useAuth";
import Member from "../Member/Member";
import instance from "../../services/AxiosInstance.js";


const SignIn = (props) => {

    const auth = useAuth();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [valid, setValid] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        "email": "",
        "password": ""
    });

    useEffect(() => {
        // Logger({page: 'sign-in', action: 'open'})
    }, [])


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
            ...formData,
            [name]: value
        });
    }

    const validateEmail = (emailAddress) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(emailAddress);
    }

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{6,}$/;
        return regex.test(password);
    }

    const handleLoginNew = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // validate input
        let formValidationErrors = {};
        !validateEmail(formData.email) ? formValidationErrors.email = "Invalid email address" : delete formValidationErrors.email;
        !validatePassword(formData.password) ? formValidationErrors.password = "Minimum 6 characters, at least one uppercase letter, " +
                "one lowercase letter and one number." :
            delete formValidationErrors.password;


        // if any errors, set validation messages and do not continue
        if (Object.keys(formValidationErrors).length > 0) {
            setFormErrors({...formValidationErrors})
            return;
        }

        auth.loginWithUsernamePassword(formData.email, formData.password).then(res => {
            setValid(true);
            handlePostLoginFlow(res);
        }).catch(e => {
            setShowAlert(true);
        })

    }

    const handlePostLoginFlow = (accessTokenData) => {

        console.log(`localStorage.getItem('accessToken'): ${localStorage.getItem('accessToken')}`);
        API_V2.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        AxiosInstance.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');

        // check if email is verified, if not redirect to the confirm email page
        if (!accessTokenData.email_verified) {
            console.log(`email is not confirmed redirecting to confirm email page`);
            navigate(`/confirm-email?email=${auth.accessTokenData.email}`);
            return;
        }

        // if the user is NOT a member, redirect to the link account page
        if (!accessTokenData['mid']) {
            console.log(`no mid in accessTokenData, redirecting to link accounts page`);
            navigate('/link-account');
            return;
        }


        MemberService.getMemberById(accessTokenData["mid"]).then((member) => {
            console.log("I am not supposed to be here either");
            // check if address needs to be updated
            const today = new Date();

            let lastUpdated = member.address.modifiedDt;
            if (lastUpdated === undefined || lastUpdated === null) {
                lastUpdated = new Date("2020-01-01")
            } else {
                lastUpdated = new Date(lastUpdated)
            }


            let diffDt = (today.getTime() - lastUpdated.getTime());

            diffDt = Math.floor(diffDt / 86400000) //days
            // diffDt = Math.floor((diffDt % 86400000) / 3600000); // hours

            if (diffDt >= 60) {
                navigate('/address-confirm');
            }

        }).then(() => navigate('/my/overview'))
            .catch(e => {
            console.log("error");
        })


    }

    return (

        <Container>
            <div className="row justify-content-md-center mt-5 mb-3">
                <div className="col-md-7 text-center">
                    <div className="h4 mb-1">Sign In To Your Account</div>
                </div>
            </div>
            <Row className="justify-content-md-center pb-5">
                <Col className="col-md-5 col-lg-4 align-self-center pb-5">
                    <Alert variant="danger" show={showAlert}>
                        <span className="small">Invalid username or password.</span>
                    </Alert>
                    <Form noValidate onSubmit={handleLoginNew} className="d-grid gap-2">
                        <Form.Group controlId="formBasicEmail">
                            <InputGroup>
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control name="email" required type="email"
                                              isInvalid={formErrors.email}
                                              isValid={valid}
                                              placeholder="Email Address"
                                              onChange={handleInputChange}/>
                                <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
                            </InputGroup>

                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Control name="password" required type="password"
                                          isInvalid={formErrors.password}
                                          isValid={valid}
                                          placeholder="Password"
                                          onChange={handleInputChange}/>
                            <Form.Control.Feedback type="invalid">{formErrors.password}</Form.Control.Feedback>
                        </Form.Group>


                        <Button variant="default" type="submit" className="mt-3">
                            Sign In
                        </Button>
                    </Form>


                    <div className="mt-3 text-muted text-center">
                        <Button variant="link danger" className="pl-1 pb-2"
                                onClick={() => navigate('/forgot-password')}>
                            Forgot Password?
                        </Button>
                    </div>
                    <div className="text-muted text-center mt-3 d-grid gap-0">
                        <span className="">Don't have an account?</span>
                        <Button variant="link" className="p-0" onClick={() => navigate('/sign-up')}>
                            Sign Up here.
                        </Button>
                    </div>
                </Col>
            </Row>

        </Container>
    );

}

export default SignIn;
