import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Card from "react-bootstrap/Card";
import AxiosInstance from "../../services/AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDate} from "../../utils/Utils";
import MemberService from "../../services/MemberService";
import MyService from "../../services/MyService";


const MyRecentDonations = ({member_id}) => {


    const [donations, setDonations] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {

        if (member_id !== "undefined") {

            // MyService.getMyDonations(5).then((res) => {
            //     setDonations(res);
            // }).catch((err) => {
            //     setErrors(["something went wrong.."])
            // })
            // todo: revert back once impersonation is solved
            MemberService.getDonations(member_id, 5).then((res) => {
                setDonations(res.donations);
            }).catch((err) => {
                setErrors(["something went wrong.."])
            })

        }

        }, [member_id]);

    return (
        <Card>
            <Card.Header style={{backgroundColor: "#073e7e", color:"white"}}>Recent Donations</Card.Header>
            <Card.Body className="pt-1">


                    {errors.length > 0 &&
                        <div className="text-secondary small">
                            <span className="text-secondary-emphasis text-decoration-underline">oops, something went wrong:</span>
                            <span className="mx-1">we cannot load your recent donations at this time.</span>
                            <p>Please check back later.</p>
                        </div>
                    }


                {donations !== undefined && donations.length > 0 ?
                    donations.map( (d,idx) => (
                    <Row key={idx}>
                        <Col className="px-0">
                            {formatDate(d.modifiedDt)}
                        </Col>

                        <Col md={4} xs={5} lg={4} className="d-flex justify-content-end">
                            {`$ ` + d.paymentsTotal.toFixed(2)}
                        </Col>

                    </Row>
                    )):
                    <div className="text-dark small">
                        <span>No donations to display</span>
                    </div>
                }
            </Card.Body>
            <Card.Footer style={{backgroundColor: "#e7f4fb"}}>
                <div className="text-secondary small">
                    * <u>only</u> the last 5 donations are shown.
                </div>
            </Card.Footer>
        </Card>
    );
}

export default MyRecentDonations;