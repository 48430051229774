import React, {Component, Fragment} from 'react';
import './App.css';
import {Routes, Route} from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import AccountSummary from './pages/Account/AccountSummary'
import ProcessPayment from './pages/Payment/CreditCardInfo'
import Landing from './pages/Welcome/Landing'
import SignIn from './pages/Login/SignIn'
import SignUp from "./pages/Login/SignUp";
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Person from './pages/Person/Person'
import ConfirmEmail from './pages/Login/ConfirmEmail'

import LinkAccount from './pages/Account/LinkAccount'
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import UpdateAddressPrompt from "./pages/Person/UpdateAddressPrompt";
import Organization from "./pages/organization/Organization";
import Organizations from "./pages/organization/Organizations";
import Member from "./pages/Member/Member";
import MemberEdit from "./pages/Member/MemberEdit";
import OrganizationCreate from "./pages/organization/OrganizationCreate";
import CampaignCreate from "./pages/Campaign/CampaignCreate";
import CampaignView from "./pages/Campaign/CampaignView";
import CampaignRunView from "./pages/Campaign/CampaignRunView";
import MemberCampaignMembership from "./pages/Member/MemberCampaignMembership";
import MyOverview from "./pages/My/MyOverview";
import Donate from "./pages/Donate";
import WIP from "./pages/WIP";
import DonationReview from "./pages/DonationReview";
import ThankYouForPayment from "./pages/Payment/ThankYouForPayment";
import MyUserProfile from "./pages/My/MyUserProfile";
import IntakeForm from "./pages/Form/IntakeForm";
import Http400 from "./pages/Http400"
import PrivacyNotice from "./pages/PrivacyNotice";
import Terms from "./pages/Terms";
import {Search} from "react-bootstrap-icons";
import MemberSearch from "./pages/MemberSearch";
import AuthProvider from "./providers/AuthProvider";
import MyOrganization from "./pages/organization/MyOrganization";
import CampaignMemberships from "./pages/Campaign/CampaignMemberships";
import CampaignRunDonations from "./pages/Campaign/CampaignRunDonations";


class App extends Component {


    render() {
        return (
            <Fragment>
                <AuthProvider>
                    <Header/>
                    <Routes>

                        {/*Authentication*/}
                        <Route path="/sign-in" element={<SignIn/>}/>
                        <Route path="/sign-up" element={<SignUp/>}/>
                        <Route path="/confirm-email" element={<ConfirmEmail/>}/>
                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        <Route path="/reset-password" element={<ResetPassword/>}/>
                        <Route path="/link-account" element={<LinkAccount/>}/>

                        {/*My*/}
                        <Route path="/my/overview" element={<MyOverview/>}/>
                        <Route path="/my/profile" element={<MyUserProfile/>}/>
                        <Route path="/my/organization" element={<MyOrganization/>}/>
                        <Route path="/account-summary" element={<AccountSummary/>}/>
                        <Route path="/address-update" element={<Person/>}/>
                        <Route path="/address-confirm" element={<UpdateAddressPrompt/>}/>
                        <Route path="/account-process-payment" element={<ProcessPayment/>}/>

                        {/*Organization*/}
                        <Route path="/organizations" element={<Organizations/>}/>
                        <Route path="/organizations/:organizationNumber" element={<Organization/>}/>
                        <Route path="/organizations/create" element={<OrganizationCreate/>}/>

                        {/*Campaign*/}
                        <Route path="/campaign/create" element={<CampaignCreate/>}/>
                        <Route path="/campaign/:campaignId" element={<CampaignView/>}/>
                        <Route path="/campaign/:campaignId/run/:campaignRunId" element={<CampaignRunView/>}/>
                        <Route path="/campaigns/:campaignId/runs/:campaignRunId" element={<CampaignRunDonations/>}/>
                        <Route path="/campaigns/:campaignId/memberships" element={<CampaignMemberships/>}/>

                        {/*Donation*/}
                        <Route path="/donate" element={<Donate/>}/>
                        <Route path="/member/:member_id/membership/:membershipId/donate" element={<Donate/>}/>
                        <Route path="/donate/review" element={<DonationReview/>}/>
                        <Route path="/donate/thank-you" element={<ThankYouForPayment/>}/>

                        {/*Member*/}
                        <Route path="/member/create" element={<MemberEdit/>}/>
                        <Route path="/member/edit/:memberId" element={<MemberEdit/>}/>
                        <Route path="/member/:memberId" element={<Member/>}/>
                        <Route path="/member/:member_id/campaign/:membership_id" element={<MemberCampaignMembership/>}/>
                        <Route path="/search" element={<MemberSearch/>}/>

                        {/* temp to delete */}
                        <Route path="/wip" element={<WIP/>}/>
                        <Route path="/intake-form" element={<IntakeForm/>}/>

                        <Route path="/terms" element={<Terms/>}/>
                        <Route path="/privacy-notice" element={<PrivacyNotice/>}/>

                        <Route path="/bad-request" element={<Http400/>}/>
                        <Route path="/error" element={<Http400/>}/>

                        <Route path="/" exact element={<Landing/>}/>
                        {/*<Redirect to="/"/>*/}

                    </Routes>

                    <Footer/>
                </AuthProvider>
            </Fragment>
        );
    }
}


export default App;


