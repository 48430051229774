import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Container from "react-bootstrap/Container";
import MyCampaigns from "../../components/my/MyCampaigns";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MyHousehold from "../../components/my/MyHousehold";
import {CardGroup} from "react-bootstrap";
import MyRecentDonations from "../../components/my/MyRecentDonations";
import MyLinkedAccounts from "../../components/my/MyLinkedAccounts";
import AxiosInstance from "../../services/AxiosInstance";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import member from "../Member/Member";
import {getTokenData, Logger} from "../../utils/Utils";
import {Link, useLocation} from "react-router-dom";
import MemberService from "../../services/MemberService";
import {useAuth} from "../../hooks/useAuth";
import Avatar from "react-avatar";


const MyOverview = (props) => {

    const auth = useAuth();
    const accessTokenData = getTokenData(localStorage.getItem("accessToken"));
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();


    const [viewingMember_id, setViewingMember_id] = useState(auth.accessTokenData['mid']);
    const [yourMember_id, setYourMember_id] = useState(auth.accessTokenData['mid']);


    useEffect(() => {

        if (viewingMember_id === undefined) navigate("/");

        MemberService.getMemberById(viewingMember_id).then(member => {
            setFormData(member);
        })
    }, [viewingMember_id]);


    const handleMemberSelected = (member_id) => {
        setViewingMember_id(member_id);
        MemberService.getMemberById(member_id).then(member => {
                setFormData(member)
            }
        )
    }

    const renderDefaultWelcome = (name) => {
        // default welcome message
        return (
            <>

                {/*</div>*/}
                <div className="d-flex pt-3">
                    <div className="d-inline mt-1">
                        <Avatar name={`${formData.firstNm}, ${formData.lastNm}`}
                                size="60"
                                round={true} color="#050C9C"
                                textSizeRatio={1.75}/>
                    </div>
                    <div className="d-inline ms-2">
                        <div className="fw-bolder small lh-1">Welcome back,</div>
                        <div className="h4 my-0 lh-1">{name}</div>
                        <div className="small text-secondary lh-1"><span className="small">Member {formData.memberId}</span></div>
                        <div className="small text-secondary lh-small">
                            <Link to={`/my/organization`} className="text-decoration-underline">
                                <i className="bi bi-buildings text-secondary me-1"></i><span>{formData.organizationName}</span>
                            </Link>

                        </div>

                    </div>
                </div>


            </>
        )
    }


    const welcomeMessage = (yourMember_id !== viewingMember_id ?
            <Alert variant="warning" className="mt-3 mb-0">
            <h5>You are now viewing {formData.firstNm + ' ' + formData.lastNm}'s Account</h5>
                Here is {formData.firstNm}'s account summary at a glance.
                <hr/>
                <div className="d-flex justify-content-end">
                    <Button variant="outline-warning"
                            className="fw-bolder"
                            onClick={() => setViewingMember_id(yourMember_id)}

                    >
                        Go Back
                    </Button>
                </div>

            </Alert> : renderDefaultWelcome(formData.firstNm)

    )

    return (
        <Container className="pb-5">
            {formData.memberId && welcomeMessage}
            <Row className="mt-0 g-4 mb-5">
                <Col className="col-12 col-md-6">

                    <Row>
                        <Col>
                            <MyCampaigns member_id={viewingMember_id} payee_member_id={yourMember_id}/>
                        </Col>
                    </Row>
                    {/*<Row className="mt-3">*/}
                    {/*    <Col>*/}
                    {/*        <MyHousehold member_id={viewingMember_id} />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                </Col>
                {/*second column*/}
                <Col className="col col-md-6">
                    <Row>
                        <Col>
                            <MyRecentDonations member_id={viewingMember_id}/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            {yourMember_id && yourMember_id === viewingMember_id ?
                                <MyLinkedAccounts member_id={yourMember_id} onSelected={handleMemberSelected}/> : null
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Container>
    );
}

export default MyOverview;