import API_V2 from "./API_V2";
import {Contact} from "../interfaces";

class MyService {

    static getMyOrganization() {
        return API_V2.get(`/v2/my/organization`)
            .then(res => {
                return res.data
            }
            ).catch(err => {
                if (err.response) {
                    throw {status: err.response.status, message: err.response.data.detail};
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static linkAccount(lastNm:string, birthDt: string, phone:string) {
        const payload = {"lastNm": lastNm, "birthDt": birthDt, "phone": phone }
        return API_V2.post(`/v2/identity/account-link`, payload)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static addContact(organizationNumber: string, contact: Contact) {
        return API_V2.post(`/v2/organizations/${organizationNumber}/contacts`, contact)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    throw {status: err.response.status, message: err.response.data.detail};
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }


    static checkPermission(resourceUri: string, permission:string) {

        const payload = {
            "resourceUri": resourceUri,
            "permission": permission
        }

        return API_V2.post(`/v2/identity/permission/check`, payload)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }


    static deleteContact(organizationNumber: string, member_id: string) {
        return API_V2.delete(`/v2/organizations/${organizationNumber}/contacts/${member_id}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status,
                        message: err.response.data.detail});

                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }

    static getMyDonations(pageSize:number = 5) {
        return API_V2.get(`/v2/my/donations?page_size=${pageSize}`)
            .then(res => {
                    return res.data
                }
            ).catch(err => {
                if (err.response) {
                    return Promise.reject({status: err.response.status, message: err.response.data.detail});
                } else if (err.request) {
                    throw {message: 'No response was received from the server.'};
                } else {
                    throw {message: err.message};
                }
            });
    }


}

export default MyService;