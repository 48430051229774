import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form'
import React, {useState, useEffect, useReducer, Fragment} from 'react';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useNavigate} from "react-router";
import PhoneView from "../../components/PhoneView";
import AddressView from "../../components/address/AddressView";
import AxiosInstance from "../../services/AxiosInstance";

const UpdateAddressPrompt = (props) => {

    const navigate = useNavigate();
    const [confirmedAddress, setConfirmedAddress] = useState(false);
    const memberDataJson = JSON.parse(localStorage.getItem('memberData'));
    const address = {
        "streetAddress": memberDataJson.address.streetAddress,
        "cityNm": memberDataJson.address.cityNm,
        "stateCd": memberDataJson.address.stateCd,
        "postalCd": memberDataJson.address.postalCd,
        "phone": memberDataJson.phones[0].phoneNbr,
    };

    const handleConfirm = () => {

        const payload = {
            memberId: memberDataJson.memberId,
            address: {
                ...address,
                modifiedDt: Date.now()
            },
            modifiedDt: Date.now()
        }


        AxiosInstance.post('/api/v1/member', payload).then((res => {
            navigate('/my/overview');
        }))

    };

    const handleConfirmCheck = () => {
        setConfirmedAddress(!confirmedAddress)
    };

    const handleAddressChange = () => {
        navigate('/address-update');
    };

    return (
        <Container>
            <Row className="mt-4 text-center">
                <Col className="col-6 m-auto">
                    <div className="h4">Please Verify Your Address</div>
                </Col>

            </Row>
            <Row className="text-center">
                <Col className="col-6 m-auto">
                    <AddressView streetAddress={address.streetAddress}
                                 cityNm={address.cityNm}
                                 stateCd={address.stateCd}
                                 postalCd={address.postalCd}
                    />
                    <PhoneView phoneNumber={address.phone}/>

                </Col>
            </Row>
            <Row className="text-center">
                <Col className="col-6 m-auto">
                    <Button variant="default" className="mt-2"
                            disabled={confirmedAddress}
                            onClick={handleAddressChange}>
                        Update My Address
                    </Button>

                    <Button variant="success" className="mt-2 btn-space d-inline"
                            disabled={!confirmedAddress}
                            onClick={handleConfirm}>
                        Continue
                    </Button>
                </Col>
            </Row>
            <Row className="text-center">
                <Col className="col-6 m-auto d-flex justify-content-center">
                    <span className="d-inline">
                        <Form.Check type="checkbox"
                                    className="mt-2 text-start"
                                    label="I confirm my address above is accurate"
                                    onClick={handleConfirmCheck}/>
                    </span>


                </Col>
            </Row>

        </Container>


    );
}

export default UpdateAddressPrompt;