import React from "react";
import AxiosInstance from "../services/AxiosInstance";
import {jwtDecode} from "jwt-decode";


export const getTokenData = (jwtToken) => {

    return jwtToken ? jwtDecode(jwtToken): {}
}

export const AgeCalculator = (birthDate) => {

    if (birthDate ===null || birthDate === undefined) return 0;

    var dob = new Date(birthDate);

    //calculate month difference from current date in time
    let month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    let age_dt = new Date(month_diff);

    //extract year from date
    let year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    let age = Math.abs(year - 1970);

    return age;
}

export const formatDateSimple = (inputDate) => {
    let d = new Date(inputDate);
    let res = d.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
    });
    return res.replaceAll('/', '.');
}

export const formatDate = (inputDate) => {
    let d = new Date(inputDate);
    let res = d.toLocaleDateString("en-US", {
        month: "short",
        day:"2-digit",
        year: "numeric"
    });


    // let placeholder1 = <span>
    //
    //                     <div style={{width:"25px"}} className="text-end d-inline-block">{res.substring(0,2)}/</div>
    //                     <div style={{width:"25px"}} className="text-end d-inline-block">{res.substring(3,5)}/</div>
    //                     <div style={{width:"44px"}} className="text-end d-inline-block">{res.substring(6,10)}</div>
    //
    //                 </span>;

    // let placeholder = `${res.substring(0,2)} ${res.substring(3,5)} ${res.substring(6,10)}`

    return (<span className="mr-2">
            <div className="text-end" style={{width:120, paddingRight:2}}>
                {res.replaceAll('/','.')}
            </div>
        </span>)


    return res.replaceAll('/','.');
}

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

export const Logger = (logEntry) => {

    AxiosInstance.post(`/log`, logEntry).catch(err => console.log("can't log"));

}