import React, {useEffect, useState} from "react";
import {lettersOnlyHandler, requiredValidator} from "../utils/inputValidators";
import Form from "react-bootstrap/Form";
import {FloatingLabel} from "react-bootstrap";
import {AsyncTypeahead, Typeahead} from "react-bootstrap-typeahead";
import MemeberSearchSelect from "../components/MemeberSearchSelect";

const FormProvider = ({formData, formDefinition, validate, onChange, onValidated}) => {


    // const [form, setForm] = useState({});
    const [form, setForm] = useState(() => {
        let newFormState = {}
        formDefinition.fields.forEach(field => {
            newFormState = {...newFormState, [field.name]: ""}
        })
        return newFormState
    });
    const [formErrors, setFormErrors] = useState({});


    useEffect(() => {

        if (formData === undefined || formData === null || Object.keys(formData).length === 0) {
            let newFormState = {}
            formDefinition.fields.forEach(field => {
                newFormState = {...newFormState, [field.name]: ""}
            })
            setForm(newFormState)
        } else {
            setForm(formData);
        }
        return (() => {
            setForm({});
        })
    }, [formData])

    useEffect(() => {
        if (validate) {
            handleValidate();
        }
    }, [validate])


    const handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let newFormState = {
            ...form, [name]: value
        }

        delete formErrors[name];
        setFormErrors({...formErrors});

        setForm(newFormState);

        if (onChange) {
            onChange(newFormState);
        }
    }

    const handleValidate = () => {

        let errors = {};

        formDefinition.fields.forEach((formField, i) => {
            formField.validators.forEach(validator => {
                if (validator.name === "requiredField") {
                    !requiredValidator(form[formField.name], validator.minLength) ?
                        errors[formField.name] = validator.message :
                        delete errors[formField.name];
                }
            })
        })

        if (Object.keys(errors).length === 0) {
            if (onValidated) {
                onValidated(true);
            }
        }

        setFormErrors({...errors})
    }

    const createFormControl = (name) => {

        const controlMetadata = formDefinition.fields.find(field => field.name === name);

        switch (controlMetadata.type) {
            case "input":
                return createFormInputControl(controlMetadata);

            case "select" :

                return createFormTypeaheadControl(controlMetadata);
            case "memberSelect":
                return <MemeberSearchSelect controlMetadata={controlMetadata} onChange={handleInputChange} />
            case "selectAsync" :
                return createFormTypeaheadControl(controlMetadata);
            default:
                return null
        }

    }

    const createFormInputControl = (controlMetadata) => {

        return (
            <>
                <FloatingLabel
                    controlId={controlMetadata.name + '-floatingLabel'}
                    label={controlMetadata.label}

                >
                    <Form.Control name={controlMetadata.name} required type={`input`}
                                  maxLength={controlMetadata.maxLength}
                                  value={form[controlMetadata.name]}
                                  className={controlMetadata.className}
                                  onChange={handleInputChange}
                                  isInvalid={!!formErrors[controlMetadata.name]}
                                  onKeyPress={controlMetadata.keypressHandler}
                                  placeholder={controlMetadata.placeholder}
                    />

                    <Form.Control.Feedback type="invalid"
                                           className="small">{formErrors[controlMetadata.name]}
                    </Form.Control.Feedback>
                </FloatingLabel>


            </>
        )
    }

    const createFormTypeaheadControl = (controlMetadata) => {

        return (
            <>

                <Typeahead
                    name={controlMetadata.name}
                    id={controlMetadata.name}
                    className=""
                    clearButton={true}
                    labelKey="label"
                    isInvalid={!!formErrors[controlMetadata.name]}
                    onChange={(e) => {

                        if (Object.keys(e).length > 0) {
                        }
                        const c_event = {
                            target: {
                                name: controlMetadata.name,
                                value: Object.keys(e).length > 0 ? e[0].value : ''
                            }
                        }
                        handleInputChange(c_event)

                    }}
                    options={controlMetadata.options}
                    placeholder="Choose a state..."
                    renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
                        <FloatingLabel
                            controlId={controlMetadata.name + '-floatingLabel'}
                            label={controlMetadata.label}
                            // className="mb-3"
                        >
                            <Form.Control
                                {...inputProps}
                                // className="text-danger py-5"
                                ref={(input) => {
                                    // Be sure to correctly handle these refs. In many cases, both can simply receive
                                    // the underlying input node, but `referenceElementRef can receive a wrapper node if
                                    // your custom input is more complex (See TypeaheadInputMulti for an example).
                                    inputRef(input);
                                    referenceElementRef(input);
                                }}
                            />
                        </FloatingLabel>
                    )}
                    // selected={form[controlMetadata.name]}
                />

                <Form.Control.Feedback type="invalid"
                                       className="small">{formErrors[controlMetadata.name]}
                </Form.Control.Feedback>


            </>
        )
    }


    const renderControls = () => {

        // Find the highest rowNumber
        const maxRowNumber = Math.max(...formDefinition.fields.map(field => field.rowNumber));

        // Create rows based on the maximum row number, populating controls that match the current row number
        const myArray = Array.from({length: maxRowNumber}, (_, rowIndex) => rowIndex + 1)

        return myArray.map(rowNumber => (
            <div key={rowNumber} className="row mb-4 gx-2">
                {formDefinition.fields
                    .filter(formField => formField.rowNumber === rowNumber)
                    .map((formField, i) => (
                        <div key={i} className={formField.colClass}>
                            {
                                createFormControl(formField.name)
                            }
                        </div>
                    ))}
            </div>
        ))
    }

    return (
        <>
            {renderControls()}

            {/* todo: remove */}
            {/*<div className="mb-5">*/}
            {/*    /!*{form['member_id']}*!/*/}
            {/*    {JSON.stringify(form, null, 2)}*/}

            {/*</div>*/}
        </>
    )
}

export default FormProvider;


