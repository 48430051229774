import React, {Fragment, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Link, useParams} from "react-router-dom";
import CampaignService from "../../services/CampaignService";
import {PageTitle} from "../../components/layout/PageTitle";
import PaginationStrip from "../../components/PaginationStrip";
import MembershipService from "../../services/CampaignMembershipService";
import {Dropdown, Spinner, Toast, ToastContainer} from "react-bootstrap";
import {CustomToggle, formatDate, formatDateSimple} from "../../utils/Utils";
import {useAuth} from "../../hooks/useAuth";


const CampaignMemberships = () => {

    const auth = useAuth();
    const {campaignId} = useParams();
    const [memberships, setMemberships] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        refreshMemberships()

    }, [campaignId, currentPage]);


    const refreshMemberships = () => {
        setIsLoading(true);
        CampaignService.getCampaignMemberships(campaignId, currentPage, 15).then((res) => {
            setMemberships(res.memberships)
            setPages(res.pages)
            console.log(res)
            setIsLoading(false);
        })
    }

    // useEffect(() => {
    //
    // }, [campaignId]);

    const statusIcon = (value) => {

        switch (value) {
            case true:
                return <i className="bi bi-circle-fill small text-success"></i>;
            case false:
                return <i className="bi bi-circle-fill small text-danger"></i>;
            default:
                return <i className="bi bi-circle-fill small text-warning"></i>
        }
    }


    const CampaignMemberMenu = ({membership, onChange}) => {

        const [isMembershipActive, setMembershipActive] = useState(membership.isActive);

        const handleMembershipStatusChange = (isActive) => {
            const new_membership = {
                "_id": membership._id,
                "isActive": isActive,
                "modifiedBy": auth.accessTokenData.email,
                "modifiedDt": new Date()
            }

            MembershipService.upsertMembership(new_membership).then((res) => {
                setMembershipActive(membership.isActive);

                onChange && onChange()
                console.log(res);
            })


        }

        return (
            <Dropdown className="d-inline" style={{fontStyle: "normal"}}>
                <Dropdown.Toggle variant="link" id="dropdown-basic" as={CustomToggle}>
                    <i className="bi bi-three-dots-vertical"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {(!isMembershipActive === true ||
                            isMembershipActive === null ||
                            isMembershipActive === undefined) &&
                        <Dropdown.Item key={1}
                                       className="text-danger"
                                       onClick={() => handleMembershipStatusChange(true)}>

                            Activate Membership
                        </Dropdown.Item>
                    }
                    {(isMembershipActive === true ||
                            isMembershipActive === null ||
                            isMembershipActive === undefined) &&
                        <Dropdown.Item key={1}
                                       className="text-danger"
                                       onClick={() => handleMembershipStatusChange(false)}>

                            Deactivate Membership
                        </Dropdown.Item>
                    }


                    {/*<Dropdown.Item key={1}*/}
                    {/*               className="text-danger"*/}
                    {/*               onClick={handleMembershipStatusChange}>*/}

                    {/*    {isMembershipActive ? "Deactivate Membership" : "Activate Membership"}*/}
                    {/*</Dropdown.Item>*/}

                </Dropdown.Menu>
            </Dropdown>
        )

    }

    const MemberShipHeader = () => (
        <div className="d-flex justify-content-start mb-2">
            <div className="me-auto"></div>
            <div className="col lh-sm small">

            </div>
            <div className="col-2 text-end small fw-bolder me-4">Price</div>
            <div className="col-2 text-end small me-4 fw-bolder text-end">Last Payment</div>


        </div>
    )

    const isPaymentDifferent = (v1, v2) => {
        if ((v1 === undefined || v1 === null) && (v2 === undefined || v2 === null)) {
            return false; // Treat both undefined and null as equal
        } else if (v1 !== v2) {
            return true; // Return true if values are different
        }
        return false; // Return false if they are equal
    }

    const MembershipRow = ({membership}) => {

        return (
            <div className="d-flex justify-content-start mb-1"
                 style={{
                     background: isPaymentDifferent(membership.lastPayment ? membership.lastPayment.paymentsTotal : 0,
                         membership.totalPrice) ? "#fcfcc7" : null
                 }}>
                <div className="me-auto">
                    {statusIcon(membership.isActive)}
                    <CampaignMemberMenu membership={{"_id": membership._id, "isActive": membership.isActive}}
                                        onChange={refreshMemberships}/>
                </div>
                <div className="col lh-sm small">

                    <Link to={`/member/${membership.primaryMember._id}`}>
                        <span style={{textDecoration: !membership.primaryMember.isActive ? "line-through" : "none"}}>
                            {`${membership.primaryMember.lastNm} ${membership.primaryMember.firstNm}`}
                        </span>
                    </Link>
                    <div className="text-secondary">{membership.organizationName}</div>

                    <div className="text-secondary small align-middle">
                        <span className="me-1">Ext Ref:</span>
                        {
                            membership.externalRef &&
                            <a target="_blank"
                               href={`https://funeral.myfundcenter.com/Policy/View.aspx?policyNumber=${membership.externalRef}`}>
                                {membership.externalRef}
                                <i className="bi bi-box-arrow-up-right ms-1"></i>
                            </a>
                        }
                    </div>
                </div>

                <div className="col-2 text-end small me-4">
                    {`$ ${membership.totalPrice.toFixed(2)}`}
                </div>
                <div className="col-2 text-end small me-4 text-end">
                    {membership.lastPayment &&
                        <div className="">

                            {`$ ${membership.lastPayment.paymentsTotal.toFixed(2)}`}
                            <div className="small">{formatDateSimple(membership.lastPayment.modifiedDt)}</div>
                            <div className="small">
                                <a target="_blank"
                                   href={`https://funeral.myfundcenter.com/Invoice/Pay.aspx?InvoiceNumber=${membership.lastPayment.externalRef}`}>
                                    {membership.lastPayment.externalRef}
                                    <i className="bi bi-box-arrow-up-right ms-1"></i>
                                </a>
                            </div>
                        </div>
                    }

                </div>


            </div>
        )
    }

    const renderMemberships = () => {

        return memberships.map((m, idx) => <MembershipRow key={idx} membership={m}/>)

    }


    return (
        <Container>
            <div className="w-80">
                <ToastContainer position="middle-center" style={{width: "150px"}}>
                    <Toast onClose={() => setIsLoading(false)}
                           className="d-flex justify-content-center align-items-center my-2 py-2"
                           show={isLoading} delay={3000} as='div' autohide>
                        <Spinner animation="border" variant="warning"/>
                        <span className="ms-2">loading...</span>
                    </Toast>

                </ToastContainer>
                <div className="mt-3">
                    <PageTitle title={`Memberships`}/>
                </div>

                {MemberShipHeader()}
                {renderMemberships()}

                <div className="pb-5">
                    <PaginationStrip onChangePage={setCurrentPage} pages={pages}/>
                </div>

            </div>

        </Container>
    )

}

export default CampaignMemberships;