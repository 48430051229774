import React, {Fragment, useEffect, useState} from "react";
import AxiosInstance from "../../services/AxiosInstance";
import member from "../../pages/Member/Member";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MemberService from "../../services/MemberService";
import Button from "react-bootstrap/Button";
import CampaignMembershipService from "../../services/CampaignMembershipService";
import {Link} from "react-router-dom";


const MemberDonationsView = ({member_id}) => {


    const [donations, setDonations] = useState([]);


    useEffect(() => {

        refreshDonations()

    }, [member_id]);


    const refreshDonations = () => {
        MemberService.getDonations(member_id).then((res) => {
            setDonations(res.donations);
        })
    }


    const formatDate = (inputDate) => {
        let d = new Date(inputDate);
        let res = d.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric"
        });
        return res.replaceAll('/', '.');
    }


    const handleRefreshDonation = (campaignRunId, campaignMembershipId) => {

        const donationToUpdate = {
            "campaignMembershipId": campaignMembershipId,
            "campaignRunId": campaignRunId
        }

        CampaignMembershipService.updateDonation(donationToUpdate).then(() => {
            refreshDonations()
        })

    }

    return (

        <>
            <div className="h5">Donations</div>
            <Row className="fw-bold small">
                <div className="col text-start">
                    Fundraiser
                </div>
                <div className="col-2 text-end">
                    Date
                </div>
                <div className="col-2 text-end">
                    Price
                </div>
                <div className="col-2 text-end">
                    Paid
                </div>
            </Row>

            {
                donations && donations.map((donation, idx) => (
                    <Row key={idx} className="mb-2 small">
                        <div className="col">
                            <div className="lh-1">
                                <span>{donation.campaignRunName}</span>
                                <span className="text-primary ms-2">
                                    <Button className="btn-sm btn-link m-0 p-0"
                                            onClick={() => handleRefreshDonation(
                                                donation.campaignRunId,
                                                donation.campaignMembershipId)}>
                                        <i className="bi bi-arrow-clockwise fw-bold"></i>
                                    </Button>
                                </span>

                            </div>
                            {
                                donation.externalRef &&
                                <div className="small text-secondary lh-1">
                                    <a target="_blank"
                                       href={`https://funeral.myfundcenter.com/Invoice/Pay.aspx?InvoiceNumber=${donation.externalRef}`}>
                                        <span className="text-secondary-emphasis me-1 small">Ext. Ref:</span>
                                        <span className="text-secondary small">{donation.externalRef}</span>
                                        <i className="bi bi-box-arrow-up-right ms-1"></i>
                                    </a>
                                </div>
                            }

                        </div>
                        <div className="col-2 text-end">
                            {formatDate(donation.modifiedDt)}

                        </div>
                        <div className="col-2 text-end">
                            {`$ `}{donation.minAmountDue.toFixed(2)}
                        </div>
                        <div className="col-2 text-end">
                            {`$`}{donation.paymentsTotal.toFixed(2)}
                        </div>

                    </Row>
                ))
            }


        </>

    );


}


export default MemberDonationsView;