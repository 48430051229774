import React, {Fragment, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddressView from "../../components/address/AddressView";
import PhoneView from "../../components/PhoneView";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import MyAddressUpdate from "../../components/my/MyAddressUpdate";
import AxiosInstance from "../../services/AxiosInstance";
import {useAuth} from "../../hooks/useAuth";

const MyUserProfile = (props) => {


    const {accessTokenData} = useAuth()
    const [member, setMember] = useState({});
    const [address, setAddress] = useState({});
    const [phone, setPhone] = useState();

    const memberEmail = localStorage.getItem('email');
    const [showAddressUpdate, setShowAddressUpdate] = useState(false);
    const [showPhoneUpdate, setShowPhoneUpdate] = useState(false);

    useEffect(()=> {

        AxiosInstance.get(`/api/v1/member/${accessTokenData['mid']}`).then(res => {
                const memberData = res.data;
                setMember(memberData);
                setAddress(memberData.address);
                setPhone(memberData.phones[0].phoneNbr)
            });

    }, [showAddressUpdate])

    return (
        <Container>
            <MyAddressUpdate show={showAddressUpdate}
                             memberId={member.memberId}
                             address={address}
                             onClose={() => setShowAddressUpdate(false)}/>
            <Row className="mt-3">
                <h5>User Profile</h5>
            </Row>
            <Card>
                <Card.Body>
                    <Row>
                        <Col>
                            <Row className="mt-2">
                                <Col>
                                    <div className="fw-bolder text-secondary">Name</div>
                                    <div>{member.firstNm + ` ` + member.lastNm}</div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <div className="fw-bolder text-secondary">Email</div>
                                    <div>{memberEmail}</div>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <div className="fw-bolder text-secondary">Address</div>
                                    <AddressView streetAddress={address.streetAddress}
                                                 cityNm={address.cityNm}
                                                 stateCd={address.stateCd}
                                                 postalCd={address.postalCd}
                                    />
                                </Col>
                                <Col className="col-3 text-end">
                                    <Button variant="link"
                                            onClick={()=>setShowAddressUpdate(true)}
                                            className="text-decoration-none fw-bold"
                                    >Edit</Button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <div className="fw-bolder text-secondary">Phone</div>
                                    <div>
                                        <PhoneView phoneNumber={phone} />
                                    </div>
                                </Col>
                                <Col className="col-3 text-end">
                                    <Button variant="link"
                                            className="text-decoration-none fw-bold"
                                    >Edit</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>

            </Card>

        </Container>


    );
}

export default MyUserProfile;