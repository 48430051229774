import {Fragment, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";


const PhoneEdit = (props) => {

    const [valid, setValid] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({phoneNumber: props.phoneNumber});

    useEffect(() => {
        setFormData({phoneNumber: props.phoneNumber});
    }, [props.phoneNumber, valid])


    const normalizePhoneInput = (e) => {

        const target = e.target;
        const value = target.value.replace(/[^\d]/g, '');
        const name = target.name;
        const cvLength = value.length;

        let temp_val = value;

        if (value.length < 4) {
            temp_val = temp_val;
        } else if (value.length < 7) {
            temp_val = `(${temp_val.slice(0, 3)}) ${temp_val.slice(3)}`
        } else {
            temp_val = `(${temp_val.slice(0, 3)}) ${temp_val.slice(3, 6)}-${temp_val.slice(6, 10)}`;
        }


        setFormData({
            ...formData,
            [name]: temp_val
        });

        target.value = temp_val;

        props.onChange(temp_val);

    };


    const alertIcon = !valid ?
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red"
             className="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg> : null;


    return (
        <Fragment>
            <Form.Control name="phoneNumber" required type="input"
                          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                          value={formData.phoneNumber}
                          onChange={normalizePhoneInput}
                // isInvalid={formErrors.phone}
                // isValid={valid}
                          placeholder="Phone Number"
                          autoComplete="disabled"/>
        </Fragment>
    )
}

PhoneEdit.defaultProps = {
    phoneNumber: "0000000000",
    // onChange: () =>
}

export default PhoneEdit;