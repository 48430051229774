import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import AxiosInstance from "../../services/AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import PaginationStrip from "../PaginationStrip";
import CampaignService from "../../services/CampaignService";
import {Link} from "react-router-dom";
import {Dropdown, Pagination, Spinner, Toast, ToastContainer} from "react-bootstrap";
import MembershipService from "../../services/CampaignMembershipService";
import {CustomToggle} from "../../utils/Utils";
import {useAuth} from "../../hooks/useAuth";
import CustomerErrorComponent from "../CustomErrorComponent";
import PaginationStrip from "../paggination/PaginationStrip";


interface IProps {
    campaignId: string;
    runId: string;
    organizationNumber: string;
}

const CampaignRunDonationsByOrganization = ({organizationNumber, campaignId, runId}: IProps) => {


    const auth = useAuth();
    const [defaultPage, setDefaultPage] = useState(1);
    const [unauthorized, setUnauthorized] = useState(false);
    const [campaignRun, setCampaignRun] = useState<any>({});
    const [donations, setDonations] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showOpenBalanceOnly, setShowOpenBalanceOnly] = useState(false);


    useEffect(() => {

        refreshDonations()

    }, [campaignId, currentPage, showOpenBalanceOnly])

    useEffect(() => {

        refreshCampaign()
    }, [campaignId]);


    const refreshCampaign = () => {
        CampaignService.getCampaignRun(campaignId, runId).then(res => {
            setCampaignRun(res);
        })
    }

    const refreshDonations = () => {
        setIsLoading(true);
        CampaignService.getCampaignRunDonationsByOrganization(organizationNumber, campaignId, runId, showOpenBalanceOnly, currentPage, 15).then(res => {
            setDonations(res);
            setTotalPages(res.pages)
            setIsLoading(false);
        }).catch(err => {
            setUnauthorized(true);
        })
    }

    const handleShowOpenBalanceOnlyToggle = () => {
        setShowOpenBalanceOnly(!showOpenBalanceOnly)
        setTotalPages(0);
        setDefaultPage(2)
    }


    const statusIcon = (value: boolean) => {

        switch (value) {
            case true:
                return <i className="bi bi-circle-fill small text-success"></i>;
            case false:
                return <i className="bi bi-circle-fill small text-danger"></i>;
            default:
                return <i className="bi bi-circle-fill small text-warning"></i>
        }
    }


    interface ICampaignMemberMenuProps {
        membership: any;
        onChange: () => void;
    }

    const CampaignMemberMenu = ({membership, onChange}: ICampaignMemberMenuProps) => {

        const [isMembershipActive, setMembershipActive] = useState(membership.isActive);

        const handleMembershipStatusChange = (isActive: boolean) => {
            const new_membership = {
                "_id": membership._id,
                "isActive": isActive,
                "modifiedBy": auth.accessTokenData !== undefined ? auth.accessTokenData.email : "unknown",
                "modifiedDt": new Date()
            }

            MembershipService.upsertMembership(new_membership).then((res) => {
                setMembershipActive(membership.isActive);

                onChange && onChange()
                console.log(res);
            })

        }

        return (
            <Dropdown className="d-inline" style={{fontStyle: "normal"}}>
                <Dropdown.Toggle variant="link" id="dropdown-basic" as={CustomToggle}>
                    <i className="bi bi-three-dots-vertical"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {(!isMembershipActive === true || isMembershipActive === null) &&
                        <Dropdown.Item key={1}
                                       className="text-danger"
                                       onClick={() => handleMembershipStatusChange(true)}>

                            Activate Membership
                        </Dropdown.Item>
                    }
                    {(isMembershipActive === true || isMembershipActive === null) &&
                        <Dropdown.Item key={1}
                                       className="text-danger"
                                       onClick={() => handleMembershipStatusChange(false)}>

                            Deactivate Membership
                        </Dropdown.Item>
                    }


                    {/*<Dropdown.Item key={1}*/}
                    {/*               className="text-danger"*/}
                    {/*               onClick={handleMembershipStatusChange}>*/}

                    {/*    {isMembershipActive ? "Deactivate Membership" : "Activate Membership"}*/}
                    {/*</Dropdown.Item>*/}

                </Dropdown.Menu>
            </Dropdown>
        )

    }

    const donationRow = (donation: any, rowNum: number) => {

        return (
            <div key={rowNum} className="d-flex flex-row align-items-baseline mb-1">
                <div className="col col-md-4 lh-sm small">

                    <span className="me-1">
                        {statusIcon(donation.isMembershipActive)}
                    </span>


                    {`${donation.primaryMember.lastNm} ${donation.primaryMember.firstNm}`}

                    <div className="text-secondary small align-middle">
                        <span className="">{donation.primaryMember.phoneNbr}
                            <i className="bi bi-dot"></i>
                        </span>
                        <span className="mx-1">Ext Ref:</span>
                        {
                            donation.externalRef && donation.externalRef

                        }

                    </div>
                </div>
                <div className="col-3 col-sm-2 text-end small">
                    {`$${donation.minAmountDue.toFixed(2)}`}
                </div>
                <div
                    className={`col-3 col-sm-2 text-end small ${donation.minAmountDue - donation.paymentsTotal > 0 && 'text-danger'}`}>
                    {`$${donation.paymentsTotal.toFixed(2)}`}
                </div>

            </div>
        )
    }

    const headerRow = <>
        <div>
            <Form.Check // prettier-ignore
                type="switch"
                id="openBalanceSwitch"
                checked={showOpenBalanceOnly}
                onChange={handleShowOpenBalanceOnlyToggle}
                className="mb-1 text-muted small"
                label="Show w/Balance Only"
            />
        </div>
        <div className="d-flex flex-row align-items-baseline mb-1">
            <div className="col col-md-4 lh-sm small"></div>
            <div className="col-3 col-sm-2 text-end small text-secondary fw-bolder">Amount Due</div>
            <div className="col-3 col-sm-2 text-end small text-secondary fw-bolder">Donation</div>
        </div>
    </>


    return (
        <Container>
            {
                !unauthorized ?
                    <>
                        <ToastContainer position="middle-center" style={{width: "150px"}}>
                            <Toast onClose={() => setIsLoading(false)}
                                   className="d-flex justify-content-center align-items-center my-2 py-2"
                                   show={isLoading} delay={3000} as='div' autohide>
                                <Spinner animation="border" variant="warning"/>
                                <span className="ms-2">loading...</span>
                            </Toast>

                        </ToastContainer>

                        <div className="mb-3">
                            {campaignRun.campaignName && <>
                                <div className="h4 mt-3 lh-1">{campaignRun.campaignName} - Donations</div>
                                <div className="lh-1 small">{campaignRun.name}</div>
                            </>
                            }
                        </div>

                        {headerRow}
                        {
                            (donations && donations.donations !== undefined) && donations.donations.map((donation: any, idx: number) => (
                                donationRow(donation, idx)

                            ))
                        }
                        <hr/>
                        <div className="pb-5">
                            {/*currentPage: number;*/}
                            {/*pageSize: number;*/}
                            {/*resultsCount: number;*/}
                            <PaginationStrip currentPage={currentPage}
                                             resultsCount={donations ? donations.results : 1}
                                             pageSize={15}
                                             onPageClick={setCurrentPage} />
                            {/*original*/}
                            {/*<PaginationStrip defaultPage={defaultPage} onChangePage={setCurrentPage}*/}
                            {/*                 pages={donations ? donations.pages : 1}/>*/}
                        </div>
                    </>:
                    <CustomerErrorComponent code={"403"}
                                            message={"You are not authorized to view this page"}/>
            }
        </Container>
    )


}

export default CampaignRunDonationsByOrganization;