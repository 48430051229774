import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {Badge, FormControl, FormGroup, TabContent, TabPane} from "react-bootstrap";
import AxiosInstance from "../../services/AxiosInstance";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import AddressView from "../../components/address/AddressView";
import {Link, useSearchParams} from "react-router-dom";
import Pagination from "../../components/PaginationStrip";
import PaginationStrip from "../../components/PaginationStrip";
import {AgeCalculator} from "../../utils/Utils";
// import organization from "./Organization";
import OrganizationCampaigns from "../../components/organization/OrganizationCampaigns";
import GroupsViewTab from "../../components/Groups/GroupsViewTab";
import {useNavigate} from "react-router";
import PhoneView from "../../components/PhoneView";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ContactCard from "../../components/contact/ContactCard";
import ContactCards from "../../components/contact/ContactCards";
import OrganizationService from "../../services/OrganizationService";
import {useAuth} from "../../hooks/useAuth";
import ContactAdd from "../../components/contact/ContactAdd";
import OrganizationContactsTabContent from "./OrganizationContactsTabContent";
import OrganizationContactDetails from "../../components/organization/OrganizationContactDetails";
import CampaignRunDonationsByOrganization from "../../components/Campaign/CampaignRunDonationsByOrganization";

const OrganizationView = (props) => {


    const [members, setMembers] = useState();
    const [hasMembers, setHasMembers] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams()

    const auth = useAuth();
    const [organization, setOrganization] = useState();
    const [contactAddShow, setContactAddShow] = useState(false);


    useEffect(() => {

        if (props.organization.organizationNumber) {

            AxiosInstance.get(`/api/v1/organization/${props.organization.organizationNumber}/members/page/${currentPage}/size/20/?searchTerm=${searchTerm}`).then((res => {
                setMembers(res.data);
            }));
            AxiosInstance.get(`/api/v1/organization/${props.organization.organizationNumber}/members/page/1/size/1/?searchTerm=`).then((res => {
                setHasMembers(res.data.results > 0 ? true : false);
            }));
        }

    }, [props.organization, currentPage, searchTerm])


    const handleUpdate = () => {
        props.onEdit();
    }

    const handleDelete = () => {

        if (!hasMembers && !props.organization.hasChildren && props.organization.hasCampaigns) {
            AxiosInstance.delete(`/api/v1/organization/${props.organization.organizationNumber}`).then((res => {
                props.onCancel();
            }));
        }
    }

    const handleSearch = (e) => {

        let target = e.target;

        if (target.value) {

            setTimeout(() => {
                setSearchTerm(target.value);
            }, 2000)

        }


    }

    const handleCampaignsOnClick = (key) => {
        navigate(`/campaign/${key}`, {
            state: {
                breadcrumb: [
                    {
                        name: props.organization.organizationName,
                        link: "/organizations/" + props.organization.organizationNumber
                    }
                ]
            }
        });
    }


    return (
        <Container>
            <Row className="mb-0">
                <div className="col d-flex">
                    <div>
                        <OrganizationContactDetails organization={props.organization}/>
                    </div>
                    <div className="col d-flex align-items-baseline">
                        <Button variant="link" onClick={handleUpdate} className="ms-1 p-0">
                            <i className="bi bi-pencil-square text-secondary"></i>
                        </Button>
                    </div>
                </div>
            </Row>
            <Row className="mt-3">
                <Col>

                    <Tabs
                        defaultActiveKey="fundraisers"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="fundraisers" title={
                            <span>Fundraisers
                                <Badge pill bg="light" text="dark">{props.organization.campaignsCnt}</Badge>
                            </span>
                        }>
                            <OrganizationCampaigns
                                organizationNumber={props.organization.organizationNumber}
                                onClick={handleCampaignsOnClick}
                            />
                        </Tab>

                        <Tab eventKey="groups" title={
                            <>
                                <span>Groups</span>
                                <Badge pill bg="light" text="dark">{props.organization.groupsCnt}</Badge>
                            </>
                        }>
                            <GroupsViewTab onGroupAdded={props.onGroupAdded}
                                           organizationNumber={props.organization.organizationNumber}/>
                        </Tab>


                        <Tab eventKey="contacts" title={`Contacts`}>
                            <TabContent>
                                <OrganizationContactsTabContent
                                    organizationNumber={props.organization.organizationNumber}/>
                            </TabContent>
                        </Tab>



                        <Tab eventKey="members"
                             title={members ?
                                 <span>Members{` `}
                                     <Badge pill bg="light" text="dark">
                                                {members.results}
                                         </Badge></span> : "Members"
                             }>

                            {/* TODO: make into a component */}

                            <Row>
                                <Col>
                                    <Button variant="" size=""
                                            onClick={() => navigate('/member/create', {
                                                state: {
                                                    organizationNumber: props.organization.organizationNumber
                                                }
                                            })}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor"
                                             className="bi bi-plus-circle" viewBox="0 0 16 16">
                                            <path
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                        </svg>
                                        {` `}Add New
                                    </Button>
                                </Col>
                                <Col md={2}>
                                    <FormControl type="input" placeholder="Search"
                                                 onKeyDown={handleSearch}></FormControl>
                                </Col>
                            </Row>
                            {hasMembers ?
                                <div className={"mt-3 mb-5 pb-5"}>


                                    <hr className="my-2"/>


                                    {
                                        members ?
                                            members.members.map((m, idx) => (
                                                <div key={idx}><Link
                                                    to={`/member/${m._id}`}>{m.lastNm}{` `}{m.firstNm}</Link>{` `}
                                                    <Badge pill bg="light" text="dark">
                                                        {AgeCalculator(m.birthDt)}
                                                    </Badge>

                                                </div>

                                            )) : null
                                    }
                                    <hr/>
                                    <div>
                                        <PaginationStrip onChangePage={setCurrentPage}
                                                         pages={members ? members.pages : null}/>
                                    </div>
                                </div> : false
                            }
                        </Tab>

                        <Tab eventKey="donations" title={
                            <span className="text-success">Donations By Org</span>
                        }>
                            <div>
                                <div className="small text-danger">experiment</div>
                                <div>
                                    {
                                        props.organization.organizationNumber &&
                                        <CampaignRunDonationsByOrganization
                                            organizationNumber={`${props.organization.organizationNumber}`}
                                            campaignId={`5ff0f1b11078457ba0980add`} runId={`67156618b5c822ef04a3198c`} />
                                    }

                                </div>

                            </div>
                        </Tab>

                    </Tabs>


                </Col>
            </Row>


            <div className={"text-center mt-5"}>
                {!hasMembers &&
                !props.organization.hasChildren &&
                !props.organization.hasCampaigns &&
                props.organization.groupsCnt == 0 ?
                    <Button variant="danger" onClick={handleDelete}>
                        Remove Organization
                    </Button> : null
                }
            </div>

        </Container>

    );
}

export default OrganizationView;