import React, {useEffect, useState} from "react";
import AxiosInstance from "../../services/AxiosInstance";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CampaignRuns from "../Campaign/CampaignRuns";
import {Accordion, useAccordionButton} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";


const OrganizationCampaigns = (props) => {

    const [campaigns, setCampaigns] = useState([{}]);
    const [activeCampaign, setActiveCampaign] = useState();
    const [activeKey, setActiveKey] = useState();
    const navigate = useNavigate();

    const handleSelect = (e) => {
        console.log("selected: " + e);
    }

    useEffect( () => {

        AxiosInstance.get(`/api/v1/organization/${props.organizationNumber}/campaigns`).then((res => {
            setCampaigns(res.data);
        }));


    }, [props.organizationNumber])

    const handleOnClick = (e) => {
        e.preventDefault();

        props.onClick(e.target.name);
    }


    return (
        <div className="mt-3">
            <Button variant="" size=""
                    onClick={()=> navigate('/campaign/create', {state: {
                        organizationNumber: props.organizationNumber
                        }})}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>{` `}Add New
            </Button>


            {campaigns?
                campaigns.map((c, idx) => (
                    <Row key={idx}>
                        <Col>
                            <Link name={c._id} onClick={handleOnClick}>{c.campaignName}</Link>
                            {c.managedBy != null ? <span className="text-secondary small"> (Managed by {c.managedBy})</span> : null}

                        </Col>
                    </Row>
                )):null
            }

        </div>
    );

}

export default OrganizationCampaigns;